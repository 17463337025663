import React from 'react'
import DisagreeSVG from "@/pad/assets/svgicons/donor/DisagreeSVG";
import moment from "moment";
import SuccessIcon from '@/pad/assets/svgicons/donor/SuccessIcon'

const ReportLayout = (props) => {
    const { children } = props

    return (
        <div className="row w-100 h-100">
            <div className="col-12">
                <p className="report_title">Report</p>
            </div>
            <div className="col-12">
                {children}
            </div>
        </div>
    )
}

export default ReportLayout