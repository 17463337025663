export const defaultLanguage = (data) => {
    let defaultLang = ""
    if (data) {
        let getDefault = data.find((item) => item.isDefault === true)
        defaultLang = getDefault.name
    }
    return defaultLang
}

export const fetchLang = (data) => {
    let fetchLang = ""
    let getLang = data.filter((item) => item.url)
    if (getLang) {
        fetchLang = getLang.map((item) => item.language)
    }
    return fetchLang
}

export const defaultLangg = (data) => {
    let defaultLan = ""
    if (data) {
        defaultLan = data.find((item) => item === "English" ? "English" : "German")
    }
    return defaultLan
}

export const fetchLanguage = (data) => {
    let fetchLang = data.map((item) => item.language)
    let defaultLang = fetchLang.find((item) => item === "english" ? "English" : "German")
}