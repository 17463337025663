function HideIcon(props) {
    const { width, height } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={`${width ? width : '18'}`} height={`${height ? height : '17'}`} viewBox="0 0 18 17" fill="none">
            <path d="M17.9249 8.175C16.1075 4.36438 12.6887 2 9 2C5.31129 2 1.89248 4.36438 0.0751101 8.175C0.0255704 8.27751 0 8.38815 0 8.5C0 8.61185 0.0255704 8.72249 0.0751101 8.825C1.89248 12.6356 5.31129 15 9 15C12.6887 15 16.1075 12.6356 17.9249 8.825C17.9744 8.72249 18 8.61185 18 8.5C18 8.38815 17.9744 8.27751 17.9249 8.175ZM9 13.375C6.139 13.375 3.44893 11.5144 1.89248 8.5C3.44893 5.48563 6.139 3.625 9 3.625C11.861 3.625 14.5511 5.48563 16.1075 8.5C14.5511 11.5144 11.861 13.375 9 13.375ZM9 5.25C8.28823 5.25 7.59245 5.44061 7.00064 5.79772C6.40883 6.15484 5.94757 6.66242 5.67519 7.25628C5.40281 7.85014 5.33154 8.50361 5.4704 9.13404C5.60926 9.76448 5.95201 10.3436 6.4553 10.7981C6.9586 11.2526 7.59983 11.5622 8.29792 11.6876C8.99601 11.813 9.7196 11.7486 10.3772 11.5026C11.0348 11.2566 11.5968 10.8401 11.9922 10.3056C12.3877 9.77114 12.5987 9.14279 12.5987 8.5C12.5987 7.63805 12.2196 6.8114 11.5447 6.2019C10.8698 5.59241 9.95445 5.25 9 5.25ZM9 10.125C8.64412 10.125 8.29623 10.0297 8.00032 9.85114C7.70442 9.67258 7.47379 9.41879 7.3376 9.12186C7.20141 8.82493 7.16577 8.4982 7.2352 8.18298C7.30463 7.86776 7.476 7.57821 7.72765 7.35095C7.9793 7.12369 8.29992 6.96892 8.64896 6.90622C8.998 6.84352 9.3598 6.8757 9.68859 6.9987C10.0174 7.12169 10.2984 7.32997 10.4961 7.5972C10.6938 7.86443 10.7994 8.17861 10.7994 8.5C10.7994 8.93098 10.6098 9.3443 10.2723 9.64905C9.9349 9.95379 9.47722 10.125 9 10.125Z" fill="#B6BDCD" />
            <path d="M16.2783 1.22172C16.2086 1.15146 16.1257 1.0957 16.0344 1.05765C15.943 1.01959 15.845 1 15.7461 1C15.6471 1 15.5492 1.01959 15.4578 1.05765C15.3665 1.0957 15.2836 1.15146 15.2139 1.22172L1.72172 14.7139C1.65146 14.7836 1.5957 14.8665 1.55765 14.9578C1.51959 15.0492 1.5 15.1471 1.5 15.2461C1.5 15.345 1.51959 15.443 1.55765 15.5344C1.5957 15.6257 1.65146 15.7086 1.72172 15.7783C1.7914 15.8485 1.8743 15.9043 1.96565 15.9424C2.05699 15.9804 2.15496 16 2.25391 16C2.35286 16 2.45084 15.9804 2.54218 15.9424C2.63352 15.9043 2.71642 15.8485 2.7861 15.7783L16.2783 2.2861C16.3485 2.21642 16.4043 2.13352 16.4424 2.04218C16.4804 1.95084 16.5 1.85286 16.5 1.75391C16.5 1.65496 16.4804 1.55699 16.4424 1.46565C16.4043 1.3743 16.3485 1.2914 16.2783 1.22172Z" fill="#FBFBFD" stroke="#FBFBFD" stroke-width="2" />
            <path d="M16.2783 1.22172C16.2086 1.15146 16.1257 1.0957 16.0344 1.05765C15.943 1.01959 15.845 1 15.7461 1C15.6471 1 15.5492 1.01959 15.4578 1.05765C15.3665 1.0957 15.2836 1.15146 15.2139 1.22172L1.72172 14.7139C1.65146 14.7836 1.5957 14.8665 1.55765 14.9578C1.51959 15.0492 1.5 15.1471 1.5 15.2461C1.5 15.345 1.51959 15.443 1.55765 15.5344C1.5957 15.6257 1.65146 15.7086 1.72172 15.7783C1.7914 15.8485 1.8743 15.9043 1.96565 15.9424C2.05699 15.9804 2.15496 16 2.25391 16C2.35286 16 2.45084 15.9804 2.54218 15.9424C2.63352 15.9043 2.71642 15.8485 2.7861 15.7783L16.2783 2.2861C16.3485 2.21642 16.4043 2.13352 16.4424 2.04218C16.4804 1.95084 16.5 1.85286 16.5 1.75391C16.5 1.65496 16.4804 1.55699 16.4424 1.46565C16.4043 1.3743 16.3485 1.2914 16.2783 1.22172Z" fill="#B6BDCD" stroke="#B6BDCD" stroke-width="0.4" />
        </svg>
    )
}

export default HideIcon;