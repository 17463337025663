import React from "react";

import {
  Col,
  Container,
  Row,
  Modal,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";

import { useNavigate } from "react-router-dom";

const DefaultPage = ({ dispatch, userInfo }) => {
  const navigate = useNavigate();

  const handleOnToHomeClick = () => {
    navigate('/');
  }

  return (
    <Container
      fluid
      className="login_layout"
      style={{
        overflowX: "hidden",
        overflowY: "scroll",
        padding: "1.875rem",
        minWidth: "37.5rem",
      }}
    >
      {/*<div>
        <SelecFile setPhotoList={setPhotoList} />
      </div>*/}
      <div
        className="w-100"
        style={{
          padding: "1.875rem",
          borderRadius: "0.625rem",
          backgroundColor: "white",
        }}
      >
        <div className="w-100 d-flex flex-column align-items-center">
          <div
            style={{
              paddingTop: "12.5rem",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "6.625rem",
              lineHeight: "8.0625rem",
              letterSpacing: "0.2em",
              color: "#152C5B",
              opacity: 0.8,
            }}
          >
            404
          </div>
          <div
            style={{
              paddingTop: "1.25rem",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "1.875rem",
              lineHeight: "2.3125rem",
              color: "#152C5B",
              opacity: 0.36,
            }}
          >
            Page Not Found
          </div>
          <div
            className="w-100 d-flex flex-row align-items-center justify-content-between"
            style={{ paddingTop: "12.5rem" }}
          >
            <button
              onClick={() => navigate(-1)}
              className="label_preview_white_btn"
            >
              Back
            </button>

            <button
              onClick={handleOnToHomeClick}
              className="label_preview_blue_btn"
              style={{ width: "21.25rem" }}
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

// export default DefaultPage;

export default DefaultPage
  
