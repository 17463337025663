import React, { useEffect, useRef, useState } from "react";
import "@/pad/styles/pages/chart/chart.css";
import urlUtils from "@/utils/urlUtils";
import questionAPI from "@/api/app/question/question";
import donorAPI from "@/api/app/donor/donor";
import errorDeal from "@/utils/errorDeal";
import { useLocation, useNavigate } from "react-router-dom";
import CDonorLayout from "@/pad/components/layout/CDonorLayout";
import QuestionBoxProgress from "@/components/questionBox/QuestionBoxProgress";
import QuestionBox from "@/components/questionBox/QuestionBox";
import Vector from "@/pad/assets/svgicons/donor/Vector";
import CancelButton from "@/components/button/cancel/cancelButton";
import "../preview.css";
import answerUtils from "@/utils/answerUtils";
import { Space, Empty, Spin, message, Modal } from "antd";
import { getDonor } from "@/pad/components/service/donor/donor";
import Report from "@/pad/components/donor/process/qualification/Report";

import preRegistrationRecordsApi from "@/api/app/preRegistrationRecords/preRegistrationRecords";
import DonorVerify from "@/components/auth/DonorVerify";
import MessageModal from "@/pad/components/donor/process/createProfile/modal/messageModal";
import { defaultLangg } from "@/utils/defaultLanguage";
import { firstToUpper } from "@/components/util/Util";
import { useDispatch, useSelector } from "react-redux";
import { IS_CHANGE } from "@/employee/components/state/actions/isChange";
import MessageConfirmModal from "@/components/messageModal/MessageConfirmModal";
import customMessage from "@/utils/showMessage";
import {
  Player,
  BigPlayButton,
  LoadingSpinner,
  ControlBar,
  PlayToggle,
  CurrentTimeDisplay,
  TimeDivider,
  DurationDisplay,
} from "video-react";
import { checkDefeTime } from "@/utils/checkDefeTime";
import { setIsLogin,SetUserInfo } from "@/pad/components/state/actions/login";
import { LOGOUT } from "@/components/state/auth/constants";

const ChecklistPage = () => {
  const dispatch = useDispatch()
  const isChange = useSelector((state) => state.isChange.isChange)
  const { isLogin } = useSelector((state) => state.authReducer);
  const isChangeValue = (status) => {
    dispatch({ type: IS_CHANGE, isChange: status })
  }
  const messageModalRef = useRef("rememberMe");
  const [data, setData] = useState(null)
  const location = useLocation();
  const navigate = useNavigate();
  const verifyRef = React.useRef();
  const modalRef = useRef();
  const checkInRef = useRef();
  const [isloading, setisloading] = useState(false);
  const [isCheckin, setIsCheck] = useState("");
  const [questions, setQuestions] = useState(null);
  const [isNext, setIsNext] = useState(false);
  const [currentIndex, setcurrentIndex] = useState(0);
  const [currentProcess, setCurrentProcess] = useState(0);
  const [isautofill, setisautofill] = useState(false);
  const [VersionRecord, setVersionRecord] = React.useState([]);
  let query = urlUtils.url2query(location.search);
  let donorId = query.id ? query.id : "";
  let appCode = query.app_unique_code ? query.app_unique_code : "";
  const [donorType, setDonorType] = useState("")
  const [donor_Id, setDonor_Id] = useState("")
  const [isModalCreate, setIsModalCreate] = useState(false);
  const [donorProgramOptions, setDonorProgramOptions] = useState([])
  const [donorProgramId, setDonorProgramId] = useState("")
  const [error, setError] = useState({})
  const [fetchLangVideo, setFetchLangVideo] = useState([])
  let defaultLang = defaultLangg(fetchLangVideo);
  const [contentLanguage, setContentLanguage] = useState(defaultLang ? defaultLang : "english")
  const [instructionData, setInstructionData] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [isStart, setIsStart] = useState(false);
  const [closeCheckIn, setCloseCheckIn] = useState(false)
  const [btnNext, setBtnNext] = useState(false)
  const [checkDeferral, setCheckDeferral] = useState()
  let cancelButtonRef = useRef();

  const donorCheckDeferral = async (isType) => {
    if (isLogin) {
      await donorAPI.checkDeferralDonor({ donorId: donorId }).then((res) => { // Check If donor is deferral and eligible date
        setCheckDeferral(res)
        if(!isType) {
          if (checkDefeTime(res.nextEligibleData)) {
            setCurrentProcess(3);
          } else {
            setCurrentProcess(0);
          }
        }
      })
    } else {
      customMessage.showMessage("Unauthorized: Please login","error");
      navigate('/');
    }
  }
  useEffect(() => {
    donorCheckDeferral();
  }, [])

  useEffect(() => {
    if (appCode && appCode != "null") {
      preRegistrationRecordsApi
        .get({
          appUniqueCode: appCode,
        })
        .then((result) => {
          if (!result || !result.data) {
            console.log("no need to auto fill");
          } else {
            let checkAutofill = result.data["healthsurvey"] ? true : false;
            setisautofill(result.data["healthsurvey"] ? true : false);
          }
        }).catch((err) => {
          console.log(err)
        });
    }
  }, [appCode]);

  useEffect(() => {
    let questionCheck = false;
    if (VersionRecord && VersionRecord.length > 0) {
      if (
        VersionRecord[VersionRecord.length - 1].ids &&
        VersionRecord[VersionRecord.length - 1].ids.questionId &&
        VersionRecord[VersionRecord.length - 1].ids.questionId.deferralIds &&
        VersionRecord[VersionRecord.length - 1].ids.questionId.deferralIds
          .length > 0
      ) {
        console.log("deferral");
      } else {
        questionCheck = true;
      }
      if (questionCheck) {
        setCurrentProcess(3);
      }
    }

    if (questions && !questionCheck) {
      if (isautofill) {
        let questionCopy = questions;
        questionCopy.questions = answerUtils.autoFillFunc(
          questionCopy.questions
        );
        setQuestions(questionCopy);
        setcurrentIndex(questionCopy.questions.length - 1);
        setCurrentProcess(2);
      }
    }
  }, [VersionRecord, isautofill, questions]);

  const checkAllDeferral = () => {
    let deferralList = [];
    questions.questions.map((item) => {
      (item.questionType.toLowerCase() === "multi-choice" ||
        item.questionType.toLowerCase() === "single-choice") &&
        item.answers.map((answer) => {
          if (
            answer.isanswer &&
            answer.result.toLowerCase() === "fail" &&
            answer.actions &&
            answer.actions[0].deferralId
          ) {
            deferralList.push(answer.actions[0].deferralId);
          }
        });
    });

    if (deferralList.length > 0) {
      return deferralList;
    }
    return [];
  };
  const openVerify = async(e) => {
    let errorCodeDeferral = await donorAPI.getDonorDeferral({ donorId: donorId });
    if (errorCodeDeferral && errorCodeDeferral.errorCode === 2007006) {
      messageModalRef.current.showWarningConfirmCloseCancel(errorCodeDeferral.message, () => {
        cancelButtonRef && cancelButtonRef.current.open()
      }, "", null, true, null, `Visit Cancel`,  ``, true);
      return false
    }

    verifyRef.current.open();
  };

  const getVerificationType = (verifyType) => {
    switch (verifyType) {
      case 0:
        return "signature";
      case 1:
        return "fingerScan";
      case 2:
        return "faceRecognition";
      default:
        return "";
    }
  };
  
  const handleConfirm = async () => {
    setConfirmLoading(true);
    try {
      const verifyType = verifyRef.current.verifyType;
      const signatureImg = verifyRef?.current?.signatureImg || null;
      let deferrals = checkAllDeferral();
      // Post health survey records and get linkedId
      const recordResponse = await questionAPI.postRecord({
        donorId: donorId,
        record: questions.questions,
        position: "qualification",
        deferralIds: deferrals,
      });
      let errorCodeDeferral = await donorAPI.getDonorDeferral({ donorId: donorId });
      if (errorCodeDeferral && errorCodeDeferral.errorCode === 2007006) {
        messageModalRef.current.showWarningConfirmCloseCancel(errorCodeDeferral.message, () => {
          cancelButtonRef.current.open()
        }, "", null, true, null, `Visit Cancel`, ``, true);
        return false
      }
      if (!recordResponse) {
        throw new Error("Submission failure!");
      }
      const linkedId = recordResponse._id;
      // Post verification data
      const verifyData = {
        donorId: donorId,
        verificationType: getVerificationType(verifyType),
        position: "qualification",
        fingerscan: "",
        linkedId: linkedId,
        signature: signatureImg,
      };
      const verifyResponse = await questionAPI.postVerify(verifyData);
      if (verifyResponse) {
        setCurrentProcess(3);
        setConfirmLoading(false)
      } else {
        throw new Error("Verification submission failure!");
      }

    } catch (error) {
      console.error('Error:', error);
      message.error("Submission failure!");
      setConfirmLoading(false);
    } finally {
      setConfirmLoading(false);
      donorCheckDeferral(true);
    }
  };

  useEffect(
    errorDeal(async () => {
      setisloading(true);
      let gender = "";
      if (donorId) {
        let res = await donorAPI.get({ donorId: donorId });
        if (res) {
          let questionList = await getDonor("/donors/process", {
            donorId: donorId,
            process: "qualification",
          });
          setVersionRecord(questionList);
          gender = res.gender;
          setDonorType(res.donorType)
          setDonor_Id(res._id)
        }
      }
      let defaultLang = null
      let fetchData = await questionAPI.get({
        status: 1,
        position: "qualification",
      });
      let questions = fetchData.questionnaires
      setInstructionData(questions)
      if (questions?.length > 0) {
        defaultLang = fetchData.sysLangs[0]
        setFetchLangVideo(questions.map((item) => item.language))
        setContentLanguage(defaultLang)
        let questionCopy = questions.find(it => it.language === defaultLang);
        questionCopy.questions = questionCopy.questions.filter(
          (e) =>
            e.gender.toLowerCase() == gender.toLowerCase() ||
            e.gender.toLowerCase() == "all"
        );
        setQuestions(questionCopy);
      }
      setisloading(false);
    }),
    [donorId]
  );

  const handlerOnClickNext = () => {
    isChangeValue && isChangeValue(false);
    dispatch(setIsLogin(null));
    dispatch(SetUserInfo({}));

    let dataNew = {
      visitor_user: null,
      visitor_token: null,
    }

    dispatch({
      type: LOGOUT,
      payload: dataNew
    })

    navigate(`${closeCheckIn ? "/" : `/`}`);
  };

  const handleChangeLanguage = (event) => {
    let gender = "";
    setContentLanguage(event.target.value)
    let resData = instructionData.filter((item) => item.language === event.target.value)
    if (instructionData?.length > 0) {
      setFetchLangVideo(instructionData.map((item) => item.language))
      let questionCopy = resData[0];

      for (var itemQ in questionCopy.templates) {
        questionCopy.templates[itemQ].questions = questionCopy.templates[
          itemQ
        ].questions.filter(
          (e) =>
            e.gender.toLowerCase() == gender.toLowerCase() ||
            e.gender.toLowerCase() == "all"
        );
      }
      questionCopy.templates = questionCopy.templates.filter(
        (i) => i.questions.length > 0
      );
      setQuestions(questionCopy);
    }
  }

  const questionFooter = (
    <div
      className="w-100 h-100 d-flex flex-row justify-content-between align-items-center div-padding-checklist-footer- pt-2"
    >
      {/* <CancelButton donorId={donorId} position={"qualification"} visitId={data && data.lastVisitId && data.lastVisitId }/> */}
      <CancelButton  ref={cancelButtonRef} donorId={donorId} position={"qualification"} visitId={data && data.lastVisitId && data.lastVisitId }/>
      <Space size={"middle"}>
        <button
          className="btn_white"
          onClick={() => {
            if (currentIndex > 0) {
              setcurrentIndex((i) => i - 1);
            } else if (currentIndex === 0) {
              setCurrentProcess(0);
            }
          }}
        >
          <Space size={"small"}>
            <Vector />
            <span>Previous</span>
          </Space>
        </button>

        {currentIndex != questions?.questions?.length - 1 ? (
          <button
            className="btn_next"
            style={!isNext ? { opacity: 0.4 } : {}}
            disabled={!isNext}
            onClick={() => setcurrentIndex((i) => i + 1)}
          >
            Next
          </button>
        ) : (
          <button
            className="btn_next"
            style={!isNext ? { opacity: 0.4 } : {}}
            disabled={!isNext}
            onClick={() => setCurrentProcess(2)}
          >
            Preview
          </button>
        )}
      </Space>
    </div>
  );

  const instructionFooter = (
    <div
      className="w-100 h-100 d-flex flex-row justify-content-between align-items-center div-padding-checklist-footer- pt-2"
    >
      {/* <CancelButton donorId={donorId} position={"qualification"} visitId={data && data.lastVisitId && data.lastVisitId }/> */}
      <CancelButton  ref={cancelButtonRef} donorId={donorId} position={"qualification"} visitId={data && data.lastVisitId && data.lastVisitId }/>
      <Space size={"middle"}>
        <button
          className="btn_white"
          onClick={() => {
            if (isChange) {
              messageModalRef.current?.showWarningConfirm(
                <p style={{ textAlign: 'center' }} >Are you sure you want to leave without saving?</p>,
                () => {
                  dispatch({ type: IS_CHANGE, isChange: false })
                  navigate(
                    `/visitor/donor/create/video?id=${donorId}&app_unique_code=${appCode}`
                  );
                },
                ""
              );
            } else {
              navigate(
                `/visitor/donor/create/video?id=${donorId}&app_unique_code=${appCode}`
              );
            }
          }}
        >
          <Space size={"small"}>
            <Vector />
            <span>Previous</span>
          </Space>
        </button>
        <button
          className="btn_next"
          style={{
            opacity: !(questions?.questions?.length > 0) ? 0.4 : isStart ? 1 : 0.4
          }}
          disabled={questions?.questions?.length > 0 ? isStart ? false : true : true}
          onClick={() => setCurrentProcess(1)}
        >
          Start
        </button>
      </Space>
    </div>
  );

  const previewFooter = (
    <div
      className="w-100 h-100 d-flex flex-row justify-content-between align-items-center div-padding-checklist-footer- pt-2"
    >
      {/* <CancelButton donorId={donorId} position={"qualification"} visitId={data && data.lastVisitId && data.lastVisitId }/> */}
      <CancelButton  ref={cancelButtonRef} donorId={donorId} position={"qualification"} visitId={data && data.lastVisitId && data.lastVisitId }/>
      <Space size={"middle"}>
        <button
          className="btn_white"
          onClick={() => {
            setCurrentProcess(1);
          }}
          style={confirmLoading ? { opacity: 0.4 } : {}}
          disabled={confirmLoading}
        >
          <Space size={"small"}>
            <Vector />
            <span>Previous</span>
          </Space>
        </button>
        <button
          className="btn_next"
          onClick={openVerify}
          style={confirmLoading ? { opacity: 0.4 } : {}}
          disabled={confirmLoading}
        >
          Next
        </button>
      </Space>
    </div>
  );

  const [isCheckIn, setIsCheckIn] = useState(false)

  const handleCheckInProcess = () => {
    checkInRef.current.handleCheckinNext();
  }
  const reportFooter = (
    <div
      className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
      style={{ padding: "2rem", paddingTop: 0, paddingBottom: "0.4rem" }}
    >
      {
        checkDeferral && checkDeferral.deferrals?.length > 0 ?
          <button
            className="report_close_btn"
            onClick={() => { handlerOnClickNext() }}
          >
            Close
          </button> :
          <>
            {
              isCheckin === 'next' ? (
                <button
                  className="report_close_btn"
                  onClick={() => { 
                    btnNext ? handleCheckInProcess() : handlerOnClickNext()
                   }}
                >
                  {btnNext ? "Checkin" : "Close"}
                </button>) : ""
            }
          </>
      }
    </div>
  );


  return (
    <CDonorLayout
      data={data} setData={setData}
      donorId={donorId}
      current={2}
      showPass={currentProcess === 3 && isCheckIn}
      noOverflowY={currentProcess === 2 ? true : false}
      footer={
        <>
          {currentProcess === 0 && instructionFooter}
          {currentProcess === 1 && questions && questionFooter}
          {currentProcess === 2 && previewFooter}
          {currentProcess === 3 && reportFooter}
        </>
      }
    >
      <div className="h-100 w-100">
        <DonorVerify
          donorId={donorId}
          ref={verifyRef}
          onConfirm={handleConfirm}
        />
        {questions?.questions?.length > 0 ? (
          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
            {currentProcess === 0 && (
              <div className="h-100 w-100">
                <SurveyInstruction
                  text={questions.instruction}
                  contentLanguage={contentLanguage}
                  isStart={isStart} setIsStart={setIsStart}
                  setContentLanguage={setContentLanguage}
                  handleChangeLanguage={handleChangeLanguage}
                  fetchLangVideo={fetchLangVideo}
                  questions={questions}
                />
              </div>
            )}
            {currentProcess === 1 && (
              <div className="h-100 w-100 div-padding-checklist" 
              >
                <QuestionBoxProgress
                  className="question-survey"
                  total={questions?.questions?.length}
                  current={currentIndex + 1}
                  value={questions?.questions[currentIndex]}
                  onIsNext={(e) => {
                    setIsNext(e);
                  }}
                  onChange={(value) => {
                    isChangeValue && isChangeValue(true);
                    setQuestions((i) => {
                      i.questions[currentIndex] = value;
                      return i;
                    });
                  }}
                />
              </div>
            )}
            {currentProcess === 2 && (
              confirmLoading ? (
                <Spin size="large" Tips="Loading..." />
              ) : (
                <div className="w-100 h-100" style={{ padding: "2rem" }}>
                  <div className="checkin_header">Preview</div>
                  <div className="div-padding-checklist-preview"
                    style={{
                      overflowY: "auto",
                      height: "100%",
                    }}
                  >
                    {questions.questions.map((item, index) => (
                      <QuestionBox
                        noInfo={true}
                        className={
                          index === questions.questions.length - 1
                            ? "question-preview no-border"
                            : "question-preview"
                        }
                        current={index + 1}
                        value={item}
                        onChange={(value) => {
                          isChangeValue && isChangeValue(true);
                          setQuestions((i) => {
                            i.questions[index] = value;
                            return i;
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
              )
            )}

            {currentProcess === 3 ? (
              <div className="h-100 w-100 pd-8">
                <Report
                  isChangeValue = {isChangeValue}
                  ref={checkInRef} dataID={{ donorId: donorId }}
                  donor_Id={donor_Id} setCloseCheckIn={setCloseCheckIn}
                  setBtnNext={setBtnNext} setisloading={setisloading}
                  donorCheckDeferral={donorCheckDeferral}
                  setIsCheck={setIsCheck} 
                  isCheckIn={isCheckIn} setIsCheckIn={setIsCheckIn}
                  handlerOnClickNext={handlerOnClickNext}
                  />
              </div>
            ) : null}
          </div>
        ) : isloading ? (
          <Spin
            size="large"
            className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
          ></Spin>
        ) : (
          <Empty
            imageStyle={{
              height: 60,
            }}
            description={<span>No Content</span>}
          ></Empty>
        )}
      </div>
      <MessageModal
        ref={modalRef}
        handlerOnClickNext={handlerOnClickNext}
        errData=""
        setCurrentProcess={setCurrentProcess}
      />
      {/* <MessageConfirmModal textCentered widthBtn={`auto`} noTitle={`No, Keep Stay`} yesTitle={`Yes, Leave without Saving`} ref={messageModalRef} /> */}
      <MessageConfirmModal textCentered widthBtn={`auto`} noTitle={`No, Keep Stay`} yesTitle={`Yes, Leave without Saving`} ref={messageModalRef} />
    </CDonorLayout>
  );
};

const SurveyInstruction = ({ text, contentLanguage, isStart, setIsStart, fetchLangVideo, handleChangeLanguage, questions }) => {
  const videoRef = React.useRef();

  useEffect(() => {
    if (questions && questions.instructionVideo) {
      videoRef.current.load()
    }
  }, [questions])

  useEffect(() => {
    const timer = setInterval(() => {
      if (videoRef.current && questions && questions.instructionVideo) {
        if (
          videoRef.current.video.props.player.currentTime >
          videoRef.current.video.props.player.duration - 1
        ) {
          setIsStart(true);
        }
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  });
  return (
    <div className="w-100 h-100 video_content">
      <div className="video_header">
        <div className="left_section">
          <p className="first_title">
            Health History Questionnaire
          </p>
        </div>
        <div className="right_section">
          <p className="select_title">
            Select the language
          </p>
          <select
            name="text"
            className="checkin_subheader select_lang"
            value={contentLanguage}
            onChange={handleChangeLanguage}
          >
            {
              fetchLangVideo?.length > 0 && fetchLangVideo.sort().map((item, index) => <option value={item} key={index}>{firstToUpper(item)}</option>)
            }
          </select>
        </div>
      </div>
      <div
        className="h-100 div-padding-Literacy"
        // style={{
        //   paddingLeft: "7rem",
        //   paddingRight: "7rem",
        // }}
      >
        <div
          className="literacy_content_header"
          // style={{ marginTop: "3.13rem" }}
        ></div>
        <div className="literacy_content" style={{ marginTop: "0.63rem" }}>
          <div
            className="literacy_consent_header"
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        </div>
        {questions && questions.instructionVideo ? <div className="w-100- h-100- dash_box">
          <Player playsInline={true} aspectRatio="auto" fluid={false} width="100%" height="100%" ref={videoRef}>
            <source src={questions.instructionVideo} type="video/mp4" />
            <BigPlayButton position={"center"} />
            <LoadingSpinner />
            <ControlBar autoHide={false} disableDefaultControls={true}>
              <PlayToggle />
              <CurrentTimeDisplay />
              <TimeDivider />
              <DurationDisplay />
            </ControlBar>
          </Player>
        </div> : ''}
      </div>
    </div>
  );
};


export default ChecklistPage;
