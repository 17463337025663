import cookieUtils from "@/utils/cookieUtils";

export const SETVISITID = "SETVISITID";
export const COOKIE_VISIT_ID = 'visit_id';

export const setVisitId = (visitId) => {
    if (visitId) {
        cookieUtils.set(COOKIE_VISIT_ID, JSON.stringify(visitId));
    } else {
        cookieUtils.clear(COOKIE_VISIT_ID);
    }
    return ({
        type: SETVISITID,
        visitId,
    })
}