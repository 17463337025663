const config = {
  cookie: {
    path: '/'
  }
};
let SUFFIX = "_____timeout";

//设置cookie
const _set = function (cname, cvalue, expries, path) {
    let ck = cname + "=" + cvalue;
    if (expries != undefined) {
        var d = new Date();
        d.setTime(d.getTime() + expries);
        var expires = "expires=" + d.toUTCString();
        ck = ck + ";" + expires;
    }
    path = path ? path : config.cookie.path;
    if (path) {
        ck = ck + ";path=" + path;
    }
    document.cookie = ck;
}

//设置cookie
const set = function (cname, cvalue, expries, path) {
    _set(cname, cvalue, undefined, path);
    if (expries && expries >= 0) {
        if (expries == 0) {
            _clear(cname, path);
        } else {
            _set(cname + SUFFIX, '' + (new Date().getTime() + expries), undefined, path);
        }
    }
}

//获取cookie
const _get = function (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ')
            c = c.substring(1);
        if (c.indexOf(name) != -1) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const get = function (cname) {
    let value = _get(cname);
    if (!value) {
        return value;
    }
    let exp = _get(cname + SUFFIX);
    if (exp) {
        if (new Date().getTime() < +exp) {
            return value;
        }
        else {
            _clear(cname, '');
            return null;
        }
    }
    else {
        return value;
    }
}

//清除cookie
const clear = function (cname, path) {
    _set(cname + SUFFIX, "", -1, path);
    _set(cname, "", -1, path);
}

//清除cookie
const _clear = function (cname, path) {
    _set(cname, "", -1, path);
}

export default {
    _set,
    set,
    _get,
    get,
    clear,
    _clear
};
