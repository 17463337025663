import * as React from "react";

function TickIcon(props) {
    return (
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 6.09091L6.94118 11L16 2" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

export default TickIcon;
