import React, { useState, useRef, useEffect } from "react";
import { Tag, Space } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import ChoiceGroup from "../choice/ChoiceGroup";
import "./questionBox.css";
import TipsModal from "./TipsModal";

//Question Box (题目，第几题，答案)
/*
  value: 可选 object
  onChange： 可选 按照ant design标准

  className: 可选 string
  style: 可选 object

  current: 可选 number 当前题目位置


*/

const QuestionBox = React.forwardRef((props, ref) => {
  React.useImperativeHandle(ref, () => ({
    value: value,
  }));
  const QUESTIONTYPES = {
    "single-choice": "radio",
    "multi-choice": "checkbox",
    text: "textarea",
  };

  const type = props.value?.questionType
    ? Object.keys(QUESTIONTYPES).findIndex(
        (i) => i === props.value.questionType.toLowerCase()
      ) > -1
      ? QUESTIONTYPES[props.value.questionType.toLowerCase()]
      : ""
    : "";
  const [value, setValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (type === "radio" && props.value?.answers?.length > 0) {
      let valueChoose = props.value.answers.find((i) => i.isanswer);
      if (valueChoose) {
        setValue(valueChoose.answer);
      } else {
        setValue("");
      }
    } else if (type === "textarea" && props.value?.answers?.length > 0) {
      setValue(props.value.answers);
    } else if (type === "checkbox" && props.value?.answers?.length > 0) {
      let valueC = [];
      let valueChooseC = props.value.answers.filter((i) => i.isanswer);
      if (valueChooseC?.length > 0) {
        valueChooseC.forEach((i) => {
          valueC.push(i.answer);
        });
      }
      setValue(valueC);
    }else {
      if (type === "checkbox") {
        setValue([]);
      } else {
        setValue("");
      }
    }
  }, [props]);

  const choiceRef = useRef(null);
  const className = props.className ? props.className : "";
  const title = props.value?.title ? props.value.title : " ";

  let options = [];
  if (type != "textarea" && props.value?.answers?.length > 0) {
    props.value.answers.map((i) => {
      options.push(i.answer);
    });
  }

  const current = props.current ? props.current : 0;
  const onChange = (e) => {
    setValue(e);
    let valueC = props.value;
    if (valueC) {
      if (type === "textarea") {
        valueC.answers = [e];
      } else if (type === "radio") {
        valueC.answers.map((n, index) => {
          if (n.answer === e) {
            valueC.answers[index].isanswer = true;
          } else {
            valueC.answers[index].isanswer = false;
          }
        });
      } else if (type === "checkbox") {
        valueC.answers.map((n, index) => {
          if (e?.length > 0 && e.findIndex((i) => i === n.answer) > -1) {
            valueC.answers[index].isanswer = true;
          } else {
            valueC.answers[index].isanswer = false;
          }
        });
      }
    }
    props.onChange && props.onChange(valueC);
  };
  return (
    <div
      className={"question-box " + className}
      style={props.style ? props.style : {}}
    >
      {isOpen ? <TipsModal
        question={props.value}
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
      /> : ''}
      <div className="w-100 d-flex flex-row align-items-center justify-content-between">
        <Space size="large">
          <Tag className="tag-box"> Q{current}</Tag>
          <div className="question-title">{title}</div>
        </Space>
        {props.noInfo ? (
          <div></div>
        ) : (
          props.value &&
          props.value.information?.title && (
            <QuestionCircleOutlined
              onClick={() => setIsOpen(true)}
              style={{
                color: "#0075ff",
                fontSize: "1.3rem",
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          )
        )}
      </div>

      <div className="w-100 mt-2 mt-md-4 mt-lg-5">
        <ChoiceGroup
          disabled={props.disabled}
          ref={choiceRef}
          type={type}
          onChange={onChange}
          value={value}
          options={options}
        />
      </div>
    </div>
  );
});

export default QuestionBox;
