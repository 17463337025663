module.exports = {
    SCREENING: "screening",
    PHYSICAL: "physical",

    DEFERRAL: "deferral",
    SUCCESS: "success",
    SUCCESSFULLY: "Successfully",

    PROCESSING: "processing",
    OPEN: "open",
    CLOSED: "closed",

    PROGRAM_ID: "programId",
    STANDARD_DONATION: "Standard Donation",
}