import * as React from "react";

function Vector(props) {
  return (
    <svg
      width="0.75rem"
      height="1.1875rem"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M10.9996 4.99997H3.40643L6.7078 1.71268C6.89618 1.52453 7.00202 1.26935 7.00202 1.00327C7.00202 0.737183 6.89618 0.481998 6.7078 0.29385C6.51942 0.105701 6.26392 0 5.99751 0C5.73109 0 5.47559 0.105701 5.28721 0.29385L0.285132 5.28973C0.194054 5.38476 0.122659 5.49681 0.0750447 5.61946C-0.0250149 5.86272 -0.0250149 6.13557 0.0750447 6.37884C0.122659 6.50149 0.194054 6.61354 0.285132 6.70856L5.28721 11.7044C5.38021 11.7981 5.49086 11.8724 5.61277 11.9232C5.73468 11.9739 5.86544 12 5.99751 12C6.12957 12 6.26033 11.9739 6.38224 11.9232C6.50415 11.8724 6.6148 11.7981 6.7078 11.7044C6.80157 11.6116 6.87599 11.5011 6.92678 11.3793C6.97757 11.2575 7.00372 11.1269 7.00372 10.995C7.00372 10.8631 6.97757 10.7325 6.92678 10.6108C6.87599 10.489 6.80157 10.3785 6.7078 10.2856L3.40643 6.99832H10.9996C11.2649 6.99832 11.5194 6.89305 11.707 6.70567C11.8946 6.51829 12 6.26415 12 5.99915C12 5.73415 11.8946 5.48001 11.707 5.29262C11.5194 5.10524 11.2649 4.99997 10.9996 4.99997Z"
        fill="#152C5B"
      />
    </svg>
  );
}

export default Vector;
