export const checkDefeTime = (nextEligibleData) => {
    if (nextEligibleData === 'N / A') {
      return true
    }
    const nextDate = new Date(nextEligibleData);
    const currenDate = new Date()
    if (nextDate > currenDate) {
      return true
    }
    return false
}