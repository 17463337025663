import * as React from "react";

function WarningSVG(props) {
  return (
    <svg
      width="5.3125rem"
      height="5.375rem"
      viewBox="0 0 85 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.9138 10.9277C39.5455 6.36956 46.1246 6.36955 48.7562 10.9277L73.4299 53.6637C76.0615 58.2218 72.7719 63.9195 67.5087 63.9195H18.1614C12.8981 63.9195 9.60853 58.2218 12.2402 53.6637L36.9138 10.9277Z"
        fill="#FDD6D7"
      />
      <path
        d="M43.0605 26.6523L43.0605 41.6942"
        stroke="#E44E51"
        strokeWidth="5.46977"
        strokeLinecap="round"
      />
      <circle cx="43.0611" cy="52.6099" r="2.73489" fill="#E44E51" />
    </svg>
  );
}

export default WarningSVG;
