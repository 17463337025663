import { SOCKET_CONNECT, SOCKET_DISCONNECT, MSA_JOIN_ROOM, MSA_EXIT_ROOM, ROOM_INFO_UPDATED, CALL } from "../actions/webSocket";
import { Socket } from '../utils/webSocketService'
import { incomingMessage } from '../utils/webSocketMessage'

export const socketMiddleware = (socket) => (params) => (next) => (action) => {
    const { dispatch, getState } = params
    const { type, userInfo } = action

    const socket = new Socket()
    if(userInfo) {
        socket.connect(process.env.REACT_APP_CALLING + `/call?id=${userInfo.siteId}`)
    }

    const reconnect = () => {
        setTimeout(function () {
            dispatch({ type: SOCKET_CONNECT });
        }, 1000);
    };

    switch (type) {
        case SOCKET_CONNECT:
            socket.on('open', () => {
            })

            socket.on('message', (event) => {
                let incomingVariable = JSON.parse(event.data)
                let data = incomingMessage(incomingVariable)
                let typeText = data.text
                switch (typeText) {
                    case MSA_JOIN_ROOM:
                        dispatch({ type: MSA_JOIN_ROOM, payload: data })
                        break

                    case MSA_EXIT_ROOM:
                        dispatch({ type: MSA_EXIT_ROOM, payload: data })
                        break

                    case ROOM_INFO_UPDATED:
                        dispatch({ type: ROOM_INFO_UPDATED, payload: data })
                        break
                    case CALL:
                        dispatch({ type: CALL, payload: data })

                    default:
                        break
                }
            })

            socket.on('close', () => {
                reconnect()
            })
            break

        case SOCKET_DISCONNECT:
            break

        default:
            break
    }
    return next(action)
}