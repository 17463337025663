import * as React from 'react';

function useSVG(props) {
    return (
        <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.15993 3.19143C8.15993 4.5926 6.98532 5.75781 5.5 5.75781C4.01494 5.75781 2.84007 4.59082 2.84007 3.19143C2.84007 1.79204 4.01494 0.625046 5.5 0.625046C6.98506 0.625046 8.15993 1.79204 8.15993 3.19143ZM5.5 8.00772C6.7396 8.00772 7.8578 8.29918 8.69853 8.91688C9.52353 9.52302 10.1404 10.4857 10.3211 11.9598C10.3301 12.033 10.3073 12.0988 10.2532 12.1548C10.1959 12.2142 10.106 12.2575 10 12.2575H1C0.894015 12.2575 0.804064 12.2142 0.746773 12.1548C0.692739 12.0988 0.669897 12.033 0.678861 11.9598C0.859585 10.4856 1.47647 9.523 2.30146 8.91687C3.14219 8.29918 4.26039 8.00772 5.5 8.00772Z" stroke="#0075FF" strokeWidth="1.25" />
        </svg>
    );
}

export default useSVG;
