import React, { useEffect, useState } from "react";

const Countdown = ({ time, onCount, current, color }) => {
  const [countTime, setTimerCount] = useState(time);
  useEffect(() => {
    setTimerCount(time);
    if (time == 0) {
      onCount && onCount();
    }
  }, [time, current]);
  useEffect(() => {
    const timer = setInterval(() => {
      if (countTime > 0) {
        setTimerCount((i) => i - 1);
      }
    }, 1000);
    if (countTime === 0) {
      onCount && onCount();
    }
    return () => {
      clearInterval(timer);
    };
  }, [countTime]);

  return <div className={`${color} count_font`}>{countTime}s</div>;
};

export default Countdown;
