import { SETISRELOAD, setIsReload } from '../../actions/checkin'


export const ReloadDonorDataReducer = ( prevState = {
    isReload: false
}, action) => {
    switch (action.type) {
        case SETISRELOAD:
            let newState = { ...prevState }
            newState.isReload = !newState.isReload
            return newState
        default:
            return prevState
    }
}