import cookieUtils from '@/utils/cookieUtils';
import { SETDONORINFO } from '../../actions/donorList';


export const DonorStateReducer = (prevState = {
    donorInfo: []
}, action) => {
    let newState = {...prevState}
    switch (action.type) {
        case SETDONORINFO:
            // newState.donorInfo.push(action.donorInfo)
            // console.log(newState)
            return newState
        default:
            return prevState
    }
}
