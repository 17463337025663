export const incomingMessage = (item) => {
    let today = new Date(Date.now());
    if (item) {
        let response = {
            type: "message",
            text: item.p,
            data: item.c,
            date: today.toLocaleString(),
        }
        return response
    }
}