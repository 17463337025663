import cookieUtils from '@/utils/cookieUtils';
import { COOKIE_ROOM_RECORD, SETROOMRECORD } from '../../actions/roomRecord';

const initState = {
    roomData: {},
}

export const roomRecordStateReducer = (state = initState, action) => {
    switch (action.type) {
        case SETROOMRECORD:
            return {
                ...state,
                roomData: action.roomData,
            }
        default:
            let data = cookieUtils.get(COOKIE_ROOM_RECORD);
            if (data) {
                let dataUpdate = JSON.parse(data);
                return { roomData: dataUpdate };
            } else {
                return state
            }
    }
}