import * as React from 'react';

function Signature(props) {
    return (
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M38.6898 23.1841L42.3378 19.5621L38.6338 15.8841L34.9858 19.5041L38.6898 23.1841ZM36.3918 25.4641L32.6878 21.7861L16.3778 37.9761C15.6978 38.6501 14.8498 40.2181 14.6558 41.1601L14.0818 43.9341L16.8778 43.3641C17.8358 43.1681 19.3958 42.3341 20.0838 41.6521L36.3918 25.4641ZM36.6958 13.8601C37.2078 13.3461 37.9058 13.0581 38.6338 13.0581C39.3618 13.0581 40.0598 13.3461 40.5718 13.8601L44.3798 17.6381C44.6341 17.8901 44.836 18.1899 44.9737 18.5203C45.1114 18.8507 45.1824 19.2051 45.1824 19.5631C45.1824 19.9211 45.1114 20.2755 44.9737 20.6059C44.836 20.9363 44.6341 21.2361 44.3798 21.4881L22.0738 43.6301C21.0038 44.6921 18.9498 45.7961 17.4438 46.1021L13.2578 46.9541C12.6398 47.0801 11.9998 46.8901 11.5538 46.4461C11.3345 46.2289 11.1729 45.9604 11.0835 45.665C10.9941 45.3695 10.9798 45.0565 11.0418 44.7541L11.8998 40.6001C12.2038 39.1241 13.3178 37.0641 14.3898 36.0021L36.6958 13.8601ZM42.7558 40.3941C42.5418 41.6901 41.7418 42.8601 40.4798 43.7801C43.0698 43.9461 45.0338 43.0921 46.5058 41.2241C46.9758 40.6301 47.8398 40.5241 48.4438 40.9841C49.0458 41.4461 49.1558 42.3001 48.6878 42.8941C46.1598 46.1001 42.4698 47.1781 37.8738 46.1361C37.2965 46.0081 36.7355 45.8149 36.2018 45.5601C32.3658 46.5141 28.8558 46.9901 25.6758 46.9901C25.3098 46.9921 24.9598 46.8481 24.7018 46.5941C24.5733 46.4682 24.4712 46.3179 24.4015 46.152C24.3318 45.9861 24.2959 45.808 24.2958 45.6281C24.2998 44.8741 24.9178 44.2641 25.6778 44.2661C28.1318 44.2661 30.8278 43.9541 33.7638 43.3281C33.2198 42.3201 33.0718 41.1401 33.3538 39.9081C33.9498 37.3121 36.3138 35.3501 38.9178 35.7221C41.5118 36.0981 43.1438 38.0741 42.7558 40.3941ZM36.0438 40.5141C35.8558 41.3401 36.0378 42.0741 36.6478 42.6281C40.6238 41.4401 40.9918 38.7761 38.5158 38.4201C37.4698 38.2721 36.3458 39.2061 36.0438 40.5141Z"
            fill="#152C5B"
          />
        </svg>
      );

}

export default Signature;
