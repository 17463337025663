import AlertSVG from '@/pad/assets/svgicons/donor/AlertSVG';
import CloseBtn from '@/pad/assets/svgicons/donor/CloseBtn';
import Success from '@/pad/assets/svgicons/donor/Success';
import Warning from "@/pad/assets/svgicons/donor/Warning";
import fieldUtils from '@/utils/fieldUtils';
import stringUtils from '@/utils/stringUtils';
import { Button, Col, Image, Row, } from "antd";
import moment from 'moment/moment';
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal } from 'react-bootstrap';
const MessageModal = forwardRef(
    (props, ref) => {
        useImperativeHandle(ref, () => {
            return {
                showSuccess: (message) => {
                    setShown(true)
                    setMessageObject({
                        type: "success",
                        content: "Successfully",
                        message: message ? message : ""
                    })
                },
                showWarning: (message, isError, content) => {
                    setShown(true)
                    setMessageObject({
                        type: "warning",
                        content: content,
                        message: message ? message : "",
                        isError: isError
                    })
                },
                showError: (data, message) => {
                    setShown(true)
                    setMessageObject({
                        type: "error",
                        content: "Failed",
                        message: message
                    })
                    if (data) {
                        setModalData(data);
                    }
                }
            };
        }, []);

        const { donorId, handlerOnClickNext, errData, setCurrentProcess } = props

        const [shown, setShown] = useState(false)
        const [messageObject, setMessageObject] = useState({})
        const [modalData, setModalData] = useState({});

        let icon = messageObject.type === "success" ? (<Success />) : <AlertSVG />

        const handleOnClickSkipChar = () => {
            window.open(`/to/chart?donor_id=${errData.donorId}`)
        };

        const handleNext = () => {
            setCurrentProcess(3);
            setShown(false)
        }

        return (
            <Modal
                show={shown}
                onHide={() => setShown(false)}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ borderRadius: "0.625rem" }}
            >
                <Modal.Body>
                    <div
                        onClick={() => setShown(false)}
                        style={{ textAlign: "right" }}
                    >
                        <CloseBtn />
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <div style={{ textAlign: "center", marginBottom: "1.63rem" }}>
                            {icon}
                        </div>
                        <div className="modal_header">{messageObject.content}</div>

                        {
                            messageObject.type === "success" ? (
                                <>
                                    <div
                                        className="d-flex flex-row align-items-center justify-content-center"
                                        style={{
                                            textAlign: "center",
                                            paddingTop: "1rem",
                                            paddingBottom: "3.38rem",
                                        }}
                                    >
                                        <div className="modal_subheader_left">
                                            Donor ID :&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div className="modal_subheader_right"> {donorId}</div>
                                    </div>
                                    <div
                                        className="w-100 d-flex flex-row align-items-center justify-content-center"
                                        style={{ marginTop: "2rem" }}
                                    >
                                        {
                                            handlerOnClickNext ? (
                                                <div
                                                    className="notes_btn align-items-center"
                                                    onClick={handlerOnClickNext}
                                                    style={{
                                                        fontSize: "1.2rem",
                                                        width: "30%",
                                                        marginTop: "0",
                                                    }}
                                                >
                                                    Next
                                                </div>
                                            ) : " "
                                        }
                                    </div>
                                </>
                            ) : messageObject.type === "warning" ? (
                                <div
                                    className='err_message d-flex flex-column align-items-center justify-content-center'
                                    style={{ marginBottom: "1.63rem", marginTop: "1.63rem" }}
                                >
                                    {errData.message ? errData.message : messageObject.message ? messageObject.message : "Please fill out the remaining required fields and submit."}
                                    {
                                        handlerOnClickNext ? (
                                            messageObject.isError ? <div
                                                className="notes_btn align-items-center notes_btn ant-btn ant-btn-default "
                                                onClick={() => setShown(false)}
                                                style={{
                                                    fontSize: "1.2rem",
                                                    width: "30%",
                                                    marginTop: "2rem",
                                                    background: 'rgb(209 214 219 / 0%)',
                                                    color: 'rgba(21, 44, 91, 1)'
                                                }}
                                            >
                                                Modify
                                            </div> : <div
                                                className="notes_btn align-items-center"
                                                onClick={() => handleNext()}
                                                style={{
                                                    fontSize: "1.2rem",
                                                    width: "30%",
                                                    marginTop: "2rem",
                                                }}
                                            >
                                                Next
                                            </div>
                                        ) : (
                                            <div
                                                className="notes_btn align-items-center"
                                                onClick={() => handleNext()}
                                                style={{
                                                    fontSize: "1.2rem",
                                                    width: "30%",
                                                    marginTop: "2rem",
                                                }}
                                            >
                                                Next
                                            </div>
                                        )
                                    }
                                </div>
                            ) : (
                                <div>
                                    <div
                                        className="d-flex flex-row align-items-center justify-content-center"
                                        style={{
                                            textAlign: "center",
                                            paddingTop: "1rem",
                                            fontWeight: 1000,
                                            fontSize: "1.1rem"
                                        }}
                                    >
                                        {modalData.message ? modalData.message : `Identification value has exist ${modalData.idType}!`}
                                    </div>

                                    {modalData && <RepetitionIdValue data={modalData} />}

                                    <Button
                                        style={{ marginTop: "2rem", background: "rgb(0,117,255)" }}
                                        type="primary"
                                        htmlType="submit"
                                        className="form-btn-submit"
                                        onClick={handleOnClickSkipChar}
                                    >
                                        View information
                                    </Button>
                                </div>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
)

const RepetitionIdValue = (data) => {
    return (
        <Col sm={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }}>
            <div className="pacs-form-account-shadow-boxes-css">
                <Row justify="center">
                    <Col span={6}>
                        <div style={{
                            width: "10rem",
                            height: "7rem",
                            borderRadius: "1rem"
                        }}>
                            <Image height={"6.5rem"} width={"6.5rem"}
                                style={{ width: "100%", height: "100%", borderRadius: "50%" }} placeholder
                                src={fieldUtils.getFieldValue(data, "data.portrait", "")} />
                        </div>
                    </Col>

                    <Col span={5}>
                        <div className="pacs-form-account-shadow-boxes-key-css">
                            <div>Donor ID</div>
                            <div>Name</div>
                            <div>Gender</div>
                            <div>DOB</div>
                        </div>
                    </Col>

                    <Col span={6}>
                        <div className="pacs-form-account-shadow-boxes-value-css">
                            <div>{fieldUtils.getFieldValue(data, "data.donorId", "")}</div>
                            <div>{stringUtils.stringCapitalize(fieldUtils.getFieldValue(data, "data.firstName", "")) + " " + stringUtils.stringCapitalize(fieldUtils.getFieldValue(data, "data.lastName", ""))}</div>
                            <div>{stringUtils.stringCapitalize(fieldUtils.getFieldValue(data, "data.gender", ""))}</div>
                            <div>{moment(fieldUtils.getFieldValue(data, "data.birthdate", "")).format('MM/DD/YYYY')}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
    );
}

export default MessageModal