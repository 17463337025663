import i18next from 'i18next'
import * as Actions from './constants'
import { getString } from '../../utils/storage'
import { storageKeys } from '../../../constants/storage'

const initState = {
  visitor_user: localStorage.getItem(storageKeys.USER) ? getString(storageKeys.USER) : null,
  visitor_token: localStorage.getItem(storageKeys.TOKEN) ? localStorage.getItem(storageKeys.TOKEN) : null,
}

export default function authReducer(state = initState, action = {}) {
  switch (action.type) {
    case Actions.LOGIN_SUCCESS:
      return {
        visitor_user: action.payload.visitor_user,
        visitor_token: action.payload.visitor_token,
        isLogin : true,
      }
    case Actions.LOGIN_TOKEN:
      return {
        ...state,
        visitor_token: action.payload,
      }
    case Actions.RESTORE_USER:
      return {
        ...state,
        visitor_user: action.payload,
      }
    case Actions.LOGOUT:
      return {
        visitor_user: null,
        visitor_token: null,
        isLogin : false,
      }
    default:
      return state
  }
}
