import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import FingerNormal from '../svgicons/FingerNormal';
import FingerRefComponent from '@/components/fingerprint/VerifyDonor';
import { message } from 'antd';
import VectorSVG from '../svgicons/VectorSVG';

const DonorVerifyFinger = forwardRef(

    ({ onConfirm, onCancel }, ref) => {
        useImperativeHandle(ref, () => {
            return { open: open, close: close }
        })
        const [show, setShow] = useState(false);
        const fingerRef = useRef()

        const open = () => {
            setShow(true);
        };

        const close = () => {
            setShow(false);
        };

        const cancelClick = () => {
            setShow(false);
            onCancel();
        };

        return (
            <>
                {
                    show ? (
                        <div>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <div style={{ width: "80px", height: "80px", margin: "61px 0" }}>
                                    <FingerNormal />
                                </div>
                                <div
                                    className="preview_index_content"
                                    style={{ marginBottom: "60px" }}
                                >
                                    Please scan your finger to confirm
                                </div>
                                <div className='w-100'>
                                    <div>
                                        <button
                                            className="btn_next"
                                            style={{
                                                background: "#fff",
                                                marginRight: "0.5rem",
                                                border: "1px solid #888",
                                                color: "#152C5B",
                                                fontSize: '16px',
                                            }}
                                            onClick={() => cancelClick()}
                                        >
                                            <VectorSVG /> <text style={{ paddingLeft: '0.25rem' }}>Back</text>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ""
                }
            </>
        )
    }
)

export default DonorVerifyFinger