import { MSA_JOIN_ROOM, MSA_EXIT_ROOM, ROOM_INFO_UPDATED, CALL } from "../../actions/webSocket";

export const webSocketStateReducer = (state = {}, action) => {
    const { type, payload } = action;

    switch (type) {
        case MSA_JOIN_ROOM:
            return { ...state, payload }
        case MSA_EXIT_ROOM:
            return { ...state, payload }
        case ROOM_INFO_UPDATED:
            return { ...state, payload }
        case CALL:
            return { ...state, payload }
        default:
            break
    }
    return action
}