import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import {
  getFingerprint,
  postFingerprint,
} from "../../donor/components/service/print/print";
import { useMountedRef } from "../../hooks/CustomizationHooks";

const FingerRefComponent = React.forwardRef((props, ref) => {
  React.useImperativeHandle(ref, () => ({
    changeStatesFun: changeStatesFun,
    printFunction: printFunction,
    verifyFunction: verifyFunction,
  }));
  const [printData, setprintData] = useState({});
  const [verifyData, setverifyData] = useState({});
  const [tempList, settempList] = useState([]);
  const mountedRef = useMountedRef();

  const [changeStates, setchangeStates] = useState(false);
  const changeStatesFun = (e) => {
    setchangeStates(e);
    setchangeStates((i) => {
      return i;
    });
  };

  const printFunction = async () => {
    let url = "http://localhost:9001/pacs-local-server/fingerprint/gain";
    let res = await getFingerprint(url);
    setprintData(JSON.parse(JSON.stringify(res)));
    let changeCopy = null;
    setchangeStates((i) => {
      changeCopy = i;
      return i;
    });

    if (!res.data.gather && !changeStates && mountedRef.current) {
      let resFinal = await printFun(res);
      return resFinal;
    } else {
      return res;
    }
  };

  const printFun = async (resCopy) => {
    return new Promise((r, j) => {
      if (resCopy.data && resCopy.data.gather) {
        
      } else {
        setTimeout(() => {
          printFunction().then((res) => {
            r(res);
          });
        }, 1000);
      }
    });
  };
  // verify(params, (res)=>{ } success, (res)=>{ } error设备连接断开，指纹不对)=>{stop(){}}
  // obj中断循环
  const verifyFunction = async (params, obj) => {
    let url = "http://localhost:9001/pacs-local-server/fingerprint/verify";
    let data = JSON.parse(JSON.stringify(params));
    const result = await postFingerprint(url, JSON.stringify(data));
    setverifyData(JSON.parse(JSON.stringify(result)));
    if (!result.data.gather && !changeStates && mountedRef.current) {
      let res = await verifyFun(obj, data);
      return res;
    } else {
      return result;
    }
  };

  const verifyFun = (obj, data) => {
    return new Promise((r, j) => {
      if (verifyData.data && verifyData.data.verify) {
      } else {
        setTimeout(() => {
          verifyFunction(data, obj).then((res) => {
            r(res);
          });
        }, 500);
      }
    });
  };
  return <></>;
});
export default FingerRefComponent;
