import Tipbox from "@/components/Tipbox/Tipbox";
import Recommend from "@/pad/assets/svgicons/donor/Recommend";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { firstToUpper, phonenumberUS } from "@/components/util/Util";
import HideIcon from "@/pad/assets/svgicons/donor/HideIcon";
import usersAPI from "@/api/app/user/user"
import md5 from "js-md5";
import { Button, message } from "antd";

const AskStaffModal = (props) => {
    const { shown, setShown, staffInf, value, setValue, handleCheckin, verifyProgram } = props

    const [showTipbox, setshowTipbox] = useState(false);
    const [isHide, setIsHide] = useState(true)
    const [password, setPassword] = useState("")
    let tipBoxValue = "Password input error";

    const handleConfirm = async () => {
        if (password) {
            let pwd = md5(password)
            let verifyStaff = await usersAPI.verifyStaffPassword({ pwd: pwd })
            if (verifyStaff) {
                handleCheckin()
            } else {
                message.warning("Password is invalid")
            }
        }
    }

    return (
        <Modal
            show={shown}
            onHide={() => setShown(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ borderRadius: "0.625rem" }}
        >
            <Modal.Body>
                <Tipbox
                    show={showTipbox}
                    setShow={setshowTipbox}
                    value={tipBoxValue}
                    state={"failed"}
                />
                <div className="recommend_layout row gy-5">
                    <Recommend width="6rem" height="6rem" />
                    <div className="col-12">
                        <p className="recommend_description">{verifyProgram.inform}</p>
                    </div>
                    <div className="col-12 recommend_form">
                        <div className="w-100 row g-4">
                            {
                                verifyProgram.data && verifyProgram.data.length > 0 && (
                                    <>
                                        <div className="col-4 d-flex align-items-center">
                                            <p className="title_text">Donor Program:</p>
                                        </div>
                                        <div className="col-8">
                                            <select
                                                id="matchDonorProgram"
                                                style={{
                                                    height: "3rem",
                                                    width: "100%",
                                                    borderRadius: "0.375rem",
                                                    border: "0.0938rem solid rgba(21,44,91,0.1)",
                                                    padding: "0.625rem"
                                                }}
                                                className="card_select"
                                                onChange={(event) => setValue(event.target.value)}
                                            >
                                                <option selected disabled>Select Donor Program</option>
                                                {verifyProgram.data &&
                                                    verifyProgram.data.map((item) => (
                                                        <option key={item._id} value={item._id}>
                                                            {item.title}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </>
                                )
                            }
                            {
                                staffInf ? (
                                    <>
                                        <div className="col-4">
                                            <p className="title_text">Staff Name:</p>
                                        </div>
                                        <div className="col-8">
                                            <p className="input_text">
                                                {firstToUpper(staffInf.firstName)}{" "}
                                                {firstToUpper(staffInf.middleName)}{" "}
                                                {firstToUpper(staffInf.lastName)}
                                            </p>
                                        </div>
                                        <div className="col-4">
                                            <p className="title_text">Staff Phone Number:</p>
                                        </div>
                                        <div className="col-8">
                                            <p className="input_text">{phonenumberUS(staffInf.phone)}</p>
                                        </div>
                                    </>
                                ) : null
                            }
                            <div className="col-4 d-flex align-items-center">
                                <p className="title_text">Employee Password:</p>
                            </div>
                            <div className="col-8 input_content">
                                <input
                                    type={`${isHide ? "password" : "text"}`}
                                    id="password"
                                    className="form-control input_field recommend_input"
                                    disabled={value ? false : true}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <button onClick={() => setIsHide(!isHide)} className="hide_password">
                                    <HideIcon width="1rem" height="1rem" />
                                </button>
                            </div>
                            <div className="col-12 d-flex flex-row align-items-center justify-content-center">
                                <Button disabled={password ? false : true} onClick={handleConfirm} className="submit_create_button" size='large' type="primary">Confirm</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AskStaffModal