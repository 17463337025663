import * as React from 'react';

function SvgContentViewOpenIssues() {
    return (
        <svg width="4rem" height="4rem" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.2731 43.1667H8.04459C6.98356 43.1665 5.94127 42.8929 5.02244 42.3733C4.1036 41.8538 3.34059 41.1066 2.81005 40.2069C2.27951 39.3072 2.00014 38.2866 2 37.2477C1.99986 36.2087 2.27896 35.1881 2.80925 34.2882L18.9235 6.9595C19.4541 6.05969 20.2173 5.31249 21.1363 4.79299C22.0553 4.27349 23.0977 4 24.1589 4C25.22 4 26.2625 4.27349 27.1814 4.79299C28.1004 5.31249 28.8636 6.05969 29.3942 6.9595L45.5085 34.2882C46.0388 35.1881 46.3178 36.2087 46.3177 37.2477C46.3176 38.2866 46.0382 39.3072 45.5077 40.2069C44.9771 41.1066 44.2141 41.8538 43.2953 42.3733C42.3764 42.8929 41.3341 43.1665 40.2731 43.1667Z" fill="#FDD6D7" />
            <path d="M24 35.2502C25.1046 35.2502 26 34.3734 26 33.2918C26 32.2103 25.1046 31.3335 24 31.3335C22.8954 31.3335 22 32.2103 22 33.2918C22 34.3734 22.8954 35.2502 24 35.2502Z" fill="#E44E51" />
            <path d="M24 27.4165C23.4696 27.4165 22.9609 27.2102 22.5858 26.8429C22.2107 26.4757 22 25.9776 22 25.4582V17.6248C22 17.1055 22.2107 16.6073 22.5858 16.2401C22.9609 15.8728 23.4696 15.6665 24 15.6665C24.5304 15.6665 25.0391 15.8728 25.4142 16.2401C25.7893 16.6073 26 17.1055 26 17.6248V25.4582C26 25.9776 25.7893 26.4757 25.4142 26.8429C25.0391 27.2102 24.5304 27.4165 24 27.4165Z" fill="#E44E51" />
        </svg>
    );
}

export default SvgContentViewOpenIssues;
