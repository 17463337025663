import React, { useEffect, useRef } from "react";

const useMountedRef = () => {
  const mountedRef = useRef(false);

  useEffect(() => {
    //页面加载完调用
    mountedRef.current = true;
    //页面卸载调用
    return () => {
      mountedRef.current = false;
    };
  });
  return mountedRef;
};

export default useMountedRef;
