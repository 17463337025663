import * as React from "react";

function Deferral(props) {
  return (
    <svg
      width="4.625rem"
      height="4.625rem"
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="37" cy="37" r="37" fill="#E44E51" />
      <path
        d="M49.0889 22.2844C49.0889 21.4523 49.0781 20.631 49.0889 19.8098C49.1105 18.6211 50.0839 17.8322 51.1979 18.07C51.9549 18.2321 52.5389 18.8912 52.5389 19.6801C52.5498 22.0251 52.5606 24.37 52.5389 26.715C52.5281 27.6119 51.8143 28.3467 50.9167 28.3575C48.5589 28.3791 46.1904 28.3791 43.8327 28.3575C42.9026 28.3467 42.1888 27.5687 42.1888 26.6501C42.1888 25.7316 42.9026 24.9752 43.8327 24.9427C44.3086 24.9211 44.7736 24.9319 45.2495 24.9319C45.7037 24.9319 46.158 24.9319 46.6122 24.9319C46.6338 24.8995 46.6663 24.8563 46.6879 24.8239C46.6014 24.7698 46.5041 24.7374 46.4284 24.6726C37.614 17.8214 24.3654 22.5221 21.8563 33.3932C19.8879 41.9194 25.0684 50.2078 33.6124 52.1421C34.7155 52.3907 35.8836 52.4339 37.0192 52.5311C37.8627 52.596 38.5765 53.1687 38.6955 53.9684C38.8253 54.8221 38.371 55.6217 37.5707 55.8919C37.3544 55.9675 37.1165 55.9999 36.9002 55.9999C28.0317 56.0216 20.1475 49.5702 18.3846 40.8496C16.546 31.7075 21.4345 22.6194 30.1623 19.3451C36.792 16.8489 43.0108 17.8646 48.6995 22.0791C48.7969 22.1547 48.8942 22.2196 48.9916 22.2952C48.9916 22.2844 49.0132 22.2844 49.0889 22.2844Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M35.2775 31.8148C35.2775 30.0642 35.2667 28.3136 35.2775 26.563C35.2883 25.785 35.8507 25.1366 36.5861 24.9637C37.3432 24.7908 38.1759 25.1366 38.5004 25.839C38.641 26.1524 38.7058 26.5414 38.7058 26.8872C38.7167 29.8805 38.7167 32.8738 38.7058 35.8672C38.7058 36.1589 38.7924 36.3643 38.9979 36.5696C40.4579 38.0068 41.9071 39.4548 43.3455 40.9137C44.308 41.8862 43.9403 43.4207 42.675 43.8098C42.0477 44.0043 41.4529 43.8854 40.9878 43.4207C39.225 41.6809 37.4729 39.9195 35.7209 38.1581C35.364 37.8015 35.2559 37.3584 35.2559 36.8613C35.2775 35.1864 35.2775 33.5006 35.2775 31.8148Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M50.9246 49.2031C50.9138 50.1433 50.1459 50.9105 49.1942 50.9105C48.2533 50.9105 47.4746 50.1325 47.4746 49.1923C47.4746 48.2414 48.2857 47.4417 49.2266 47.4633C50.1783 47.4741 50.9354 48.2522 50.9246 49.2031Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M43.6294 51.2335C44.5812 51.2443 45.349 52.0224 45.3382 52.9625C45.3382 53.9027 44.5487 54.6807 43.6078 54.6807C42.6561 54.6807 41.8558 53.8703 41.8774 52.9193C41.899 51.9791 42.6777 51.2227 43.6294 51.2335Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M54.2577 38.7206C53.306 38.7098 52.5381 37.9425 52.5489 36.9916C52.5598 36.0407 53.3276 35.2842 54.2902 35.295C55.2419 35.295 56.0206 36.0731 55.999 37.024C55.9882 37.9749 55.2095 38.7314 54.2577 38.7206Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M52.9707 41.9089C53.9333 41.9089 54.7011 42.6762 54.6903 43.6271C54.6903 44.5781 53.9224 45.3453 52.9599 45.3453C51.9973 45.3453 51.2402 44.5889 51.2402 43.6271C51.251 42.6654 52.0081 41.9089 52.9707 41.9089Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  );
}

export default Deferral;
