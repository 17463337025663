import React from 'react'
import './formLabel.css'

const FormLabel = (props) => {
    const { title, style } = props
    return (
        <div className='form_title' style={style}>
            <p className='title_label'>{title}</p>
            <div className='navigate_line_v2'></div>
        </div>
    )
}

export default FormLabel