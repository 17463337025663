import React, { useEffect, useState } from "react";
import * as faceapi from "face-api.js";
import LampSVG from "@/components/auth/svgicons/lampSVG";
import UseSVG from "@/components/auth/svgicons/useSVG";
import VectorSVG from "@/components/auth/svgicons/VectorSVG";
import userImage from "@/components/auth/svgicons/user.png";
import { Modal, message, Image } from "antd";
/**
 * 人脸认证信息
 * prop
 * donorId：用户id
 * onConfirm: ()=>void，通过后回调
 * onCancel: ()=>void, 认证不通过回调
 *
 */
const DonorVerifyFace = React.forwardRef(
  ({ donorId, onConfirm, onCancel }, ref) => {
    React.useImperativeHandle(ref, () => {
      return {
        open: open,
        close: close,
        closeWebcam: closeWebcam,
        start: startVideo,
      };
    });

    const [show, setShow] = useState(false);
    const [captureVideo, setCaptureVideo] = React.useState(false);
    const [modelsLoaded, setModelsLoaded] = React.useState(false);
    const videoRef = React.useRef();
    const videoHeight = 250;
    const videoWidth = 250;
    const canvasRef = React.useRef();
    let options; // The Tiny Face Detector 配置项
    let reference; // 参考图像
    const scoreThreshold = 0.45; // 人脸最低可信度阈值
    const inputSize = 160; // 处理图像的尺寸, 越小越快, 但是必须要被32整除
    const [time, setTime] = useState(null); //倒计时时间
    const [step, setStep] = useState(0); //倒计时时间
    const timeRef = React.useRef(); //设置延时器
    React.useEffect(() => {
      const loadModels = async () => {
        const MODEL_URL = process.env.PUBLIC_URL + "/models";

        Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
          faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
          faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
          faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
        ]).then(setModelsLoaded(true));
      };
      loadModels();
    }, []);
    //倒计时
    useEffect(() => {
      //如果设置倒计时且倒计时不为0
      if (time && time !== 0)
        timeRef.current = setTimeout(() => {
          console.log("time", time);
          if (time == 1) {
            console.log(12341234123);
            closeWebcam();
          }
          setTime((time) => time - 1);
        }, 1000);
      //清楚延时器
      return () => {
        clearTimeout(timeRef.current);
      };
    }, [time]);
    const open = () => {
      // 拉起认证窗口
      setShow(true);
      console.log("videoRef", videoRef);
    };
    const close = () => {
      // 关闭认证窗口
      setShow(false);
    };
    const cancelClick = () => {
      setShow(false);
      onCancel();
      // setStep(1);
      closeWebcam();
    };
    const verificationClick = async () => {
      startVideo();
      setStep(0);
    };
    const startVideo = () => {
      setCaptureVideo(true);
      navigator.mediaDevices
        .getUserMedia({ video: { width: 250, height: 250 } })
        .then((stream) => {
          let video = videoRef.current;
          video.srcObject = stream;
          video.play();
          setTime(30);
        })
        .catch((err) => {
          console.error("error:", err);
        });
    };

    let count  = 0
    const handleVideoOnPlay = () => {
      setInterval(async () => {
        if (canvasRef && canvasRef.current) {
          canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
            videoRef.current
          );
          const displaySize = {
            width: videoWidth,
            height: videoHeight,
          };

          faceapi.matchDimensions(canvasRef.current, displaySize);

          options = new faceapi.TinyFaceDetectorOptions({
            inputSize,
            scoreThreshold,
          });

          const result = await faceapi
            .detectSingleFace(videoRef.current, options)
            .withFaceLandmarks()
            .withFaceDescriptor();
          if (result) {
            let imgEle = document.createElement("img");
            imgEle.crossOrigin = "anonymous";
            imgEle.src = donorId.faceCollectionUrl;
            // imgEle.src =
            //   "https://pic4.dreams-travel.com/Public/images/1111.png";
            reference = await faceapi
              .detectSingleFace(imgEle, options)
              .withFaceLandmarks()
              .withFaceDescriptor();
            if (reference) {
              const faceMatcher = new faceapi.FaceMatcher(result);
              const bestMatch = faceMatcher.findBestMatch(reference.descriptor);
              //返回输出结果,
              console.log("bestMatch", bestMatch.distance);
              if (bestMatch.distance < 0.4) {
                // message.success("Face Success!")
                videoRef.current.srcObject.getTracks()[0].stop();
                videoRef.current.pause();
                // handleClose();
                if(count === 0) {
                  onConfirm();
                }
                count = count + 1
              }
            }
          }
        }
      }, 250);
    };

    const closeWebcam = () => {
      if(captureVideo){
        videoRef.current.srcObject.getTracks()[0].stop();
        videoRef.current.pause();
        setCaptureVideo(false);
      }
    };
    return (
      <div>
        {show ? (
          <div>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div
                style={captureVideo?{
                  paddingTop: "2rem",
                  paddingBottom: "2rem",
                  width: "80%",
                }:{
                  paddingTop: "2rem",
                  paddingBottom: "2rem",
                  width: "100%",
                }}
              >
                {step == 1 ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "40px",
                        color: "#B7BECD",
                        borderRadius: "50%",
                      }}
                    >
                      <Image
                        width={150}
                        style={{ borderRadius: "50%" }}
                        src={userImage}
                        preview={false}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="w-100 h-100  justify-content-center ">
                    {captureVideo ? (
                      <div
                        style={{
                          padding: "2rem",
                          border: "1px dashed #E1E4EA",
                          background: "#FCFCFD",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <video
                            ref={videoRef}
                            // height={videoHeight}
                            // width={videoWidth}
                            onPlay={handleVideoOnPlay}
                            style={{
                              width: "250px",
                              height: "250px",
                              borderRadius: "50%",
                              WebkitTransform: 'scaleX(-1)',
                              transform: 'scaleX(-1)'
                            }}
                          />
                          <canvas
                            ref={canvasRef}
                            style={{
                              position: "absolute",
                              width: "250px",
                              height: "250px",
                            }}
                          />
                        </div>
                        <div
                          className="justify-content-center"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <text
                            style={{
                              marginTop: "1rem",
                              textAlign: "center",
                              color: "#A1ABBD",
                              fontWeight:'bold'
                            }}
                          >
                            Verifying ...({time}s)
                          </text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          padding: "5rem",
                          border: "1px dashed #E1E4EA",
                          background: "#FCFCFD",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div
                            style={{
                              width: "46px",
                              textAlign: "center",
                              height: "46px",
                              border: "3px solid #E42D2D",
                              color: "#E42D2D",
                              fontSize: "26px",
                              fontWeight: "bold",
                              borderRadius: "50%",
                            }}
                          >
                            !{/* <FoFaceSVG/> */}
                          </div>
                        </div>
                        <div style={{ marginTop: "1rem", textAlign: "center" ,  color: "#E42D2D",}}>
                          No face recognition
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {!captureVideo ? (
                  ""
                ) : (
                  <div
                    className="preview_index_content"
                    //onClick={handleNext}
                    style={{
                      fontSize: "14px",
                      color: "#A1ABBD",
                    }}
                  >
                    <div>
                      <LampSVG />{" "}
                      <text style={{ marginLeft: "0.25rem" }}>
                        Avoid verification under too light or too dark
                        environment
                      </text>
                    </div>
                    <div>
                      <UseSVG />{" "}
                      <text style={{ marginLeft: "0.25rem" }}>
                        Don’t block your face featuers
                      </text>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {show ? (
          <div style={{}}>
            {captureVideo ? (
              <div style={{ display: "flex", justifyContent: "space-between",padding:'0rem 3rem 1rem 1rem' }}>
                <button
                  className="btn_next"
                  style={{
                    background: "#fff",
                    marginRight: "5.5rem",
                    border: "1px solid #888",
                    color: "#152C5B",
                    fontSize: '16px',
                  }}
                  onClick={() => cancelClick()}
                >
                  <VectorSVG /> <text style={{paddingLeft:'0.25rem'}}>Back</text> 
                </button>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" ,padding:'0rem 3rem 0rem 0rem'}}>
                <button
                  className="btn_next"
                  style={{
                    background: "#fff",
                    marginRight: "5.5rem",
                    border: "1px solid #888",
                    color: "#152C5B",
                    fontSize: '16px',
                  }}
                  onClick={() => cancelClick()}
                >
                  <VectorSVG /> <text style={{paddingLeft:'0.25rem'}}>Other verification methods</text> 
                </button>
                <button className="btn_next" onClick={() => startVideo()}>
                  Restart
                </button>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
);

export default DonorVerifyFace;
