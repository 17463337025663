import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/reducers'

import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';


const storageConfig = {
    key: 'root',
    storage: storageSession,
    blacklist: [],
}
//
const myPersistReducer = persistReducer(storageConfig, rootReducer);

export const store = createStore(myPersistReducer, applyMiddleware(thunk))

//
export const persistor = persistStore(store);


