import { combineReducers } from 'redux';
import { loginStateReducer, userInfStateReducer } from './login/login';
import { ReloadDonorDataReducer } from './checkin/checkin';
import { DonorStateReducer } from './donorList/donorList'
import { isChangeStateReducer } from './isChange/isChange';
import { roomRecordStateReducer } from './roomRecord/roomRecord'
import { webSocketStateReducer } from './webSocket/webSocket'
import { visitStateReducer } from './visit/visit'
const rootReducer = combineReducers({
    loginStateReducer,
    userInfStateReducer,
    ReloadDonorDataReducer,
    DonorStateReducer,
    isChange: isChangeStateReducer,
    roomRecordStateReducer,
    webSocketStateReducer,
    visitStateReducer,
})

export default rootReducer