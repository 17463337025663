import * as React from "react";

function DisagreeSVG(props) {

  const { width, height } = props

  return (
    <svg width={`${width ? width : '4.625rem'}`} height={`${height ? height : '4.625rem'}`} viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="37" cy="37" r="37" fill="#FFAF37"/>
    <path d="M36.9999 28.0302C36.5242 28.0302 36.0679 28.2192 35.7314 28.5557C35.395 28.8921 35.206 29.3484 35.206 29.8242V36.9999C35.206 37.4757 35.395 37.932 35.7314 38.2684C36.0679 38.6049 36.5242 38.7939 36.9999 38.7939C37.4757 38.7939 37.932 38.6049 38.2684 38.2684C38.6049 37.932 38.7939 37.4757 38.7939 36.9999V29.8242C38.7939 29.3484 38.6049 28.8921 38.2684 28.5557C37.932 28.2192 37.4757 28.0302 36.9999 28.0302ZM36.9999 42.3818C36.6451 42.3818 36.2983 42.487 36.0033 42.6841C35.7083 42.8812 35.4783 43.1614 35.3426 43.4892C35.2068 43.817 35.1713 44.1777 35.2405 44.5257C35.3097 44.8737 35.4805 45.1933 35.7314 45.4442C35.9823 45.6951 36.302 45.8659 36.65 45.9352C36.9979 46.0044 37.3587 45.9689 37.6865 45.8331C38.0143 45.6973 38.2944 45.4674 38.4915 45.1724C38.6887 44.8773 38.7939 44.5305 38.7939 44.1757C38.7939 43.6999 38.6049 43.2436 38.2684 42.9072C37.932 42.5708 37.4757 42.3818 36.9999 42.3818ZM54.4191 29.0348L44.965 19.5808C44.6213 19.253 44.1663 19.0672 43.6913 19.0605H30.3085C29.8336 19.0672 29.3786 19.253 29.0348 19.5808L19.5808 29.0348C19.253 29.3786 19.0672 29.8336 19.0605 30.3085V43.6913C19.0672 44.1663 19.253 44.6213 19.5808 44.965L29.0348 54.4191C29.3786 54.7469 29.8336 54.9327 30.3085 54.9393H43.6913C44.1663 54.9327 44.6213 54.7469 44.965 54.4191L54.4191 44.965C54.7469 44.6213 54.9327 44.1663 54.9393 43.6913V30.3085C54.9327 29.8336 54.7469 29.3786 54.4191 29.0348ZM51.3515 42.9379L42.9379 51.3515H31.062L22.6484 42.9379V31.062L31.062 22.6484H42.9379L51.3515 31.062V42.9379Z" fill="white"/>
    </svg>    
  );
}

export default DisagreeSVG;
