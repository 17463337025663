import DisagreeSVG from "@/pad/assets/svgicons/donor/DisagreeSVG";
import moment from "moment";
import SuccessIcon from '@/pad/assets/svgicons/donor/SuccessIcon'


const WatchVideosDetails = ({ isAgree, donorProcess }) => {

  let videos = donorProcess.ids && donorProcess.ids.consentId ? donorProcess.ids && donorProcess.ids.consentId : {}
  return (
    <div className="row">
      <div className="col-12">
        <p className="report_title">Report</p>
      </div>
      <div className="col-12 p-sm-0 p-md-4  p-lg-4 pt-lg-2">
        <div className="d-flex flex-column align-items-center justify-content-center w-100 mb-4">
          <div className="icon-cus">{isAgree === 'agree' ? <SuccessIcon width="4rem" height="5rem" /> : <DisagreeSVG width="4rem" height="5rem" />}</div>
          <p className="report_title mt-1">
            {isAgree === 'agree' ? "Agree" : "Disagree"}
          </p>
        </div>

        <div className="w-100 d-flex justify-content-between">
          <div 
          className="div-content-videos"
          >
            <div className="row pt-sm-2 pt-md-2 pb-lg-2">
              <div className='col-5'>
                <p className='label-text'>Completion Time :</p>
              </div>
              <div className='col-7'>
                <p className='value-text'>{donorProcess.createdAt ? <>{moment(donorProcess.createdAt).format('MM / DD / YYYY　 hh : mm ')}{moment(donorProcess.createdAt).format('a')}</> : 'N/A'}</p>
              </div>
            </div>
            <div className="row pt-sm-2 pt-md-2 pb-lg-2">
              <div className='col-5'>
                <p className='label-text'>Channel :</p>
              </div>
              <div className='col-7'>
                <p className='value-text'>web</p>
              </div>
            </div>
            <div className="row pt-sm-2 pt-md-2 pb-lg-2">
              <div className='col-5'>
                <p className='label-text'>Video Version :</p>
              </div>
              <div className='col-7'>
                {
                  videos && videos.videos ? <>
                  {
                    videos.videos.map((it, i) => <p className='value-text'><span>{i+1}. </span><span><span style={{textTransform: 'capitalize'}}>{it.videoType}</span> {it.name}</span></p>)
                  }
                  </> : ''
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default WatchVideosDetails;