import * as React from 'react';

function CloseBtn(props) {
	return (
		<svg width="1.125rem" height="1.125rem" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M14.5857 17.4142C15.3668 18.1953 16.6331 18.1953 17.4142 17.4142C18.1952 16.6332 18.1952 15.3668 17.4142 14.5858L14.5857 17.4142ZM3.41422 0.585789C2.63317 -0.195261 1.36684 -0.195263 0.585789 0.585784C-0.195261 1.36683 -0.195263 2.63316 0.585784 3.41421L3.41422 0.585789ZM0.585837 14.5858C-0.19521 15.3668 -0.195208 16.6332 0.585842 17.4142C1.36689 18.1953 2.63322 18.1953 3.41427 17.4142L0.585837 14.5858ZM17.4142 3.41421C18.1953 2.63316 18.1953 1.36683 17.4142 0.585787C16.6332 -0.19526 15.3668 -0.195258 14.5858 0.585792L17.4142 3.41421ZM17.4142 14.5858L3.41422 0.585789L0.585784 3.41421L14.5857 17.4142L17.4142 14.5858ZM3.41427 17.4142L17.4142 3.41421L14.5858 0.585792L0.585837 14.5858L3.41427 17.4142Z" fill="#152C5B" fillOpacity="0.2" />
		</svg>


	);
}

export default CloseBtn;
