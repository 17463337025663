import * as React from 'react';

function SuccessIcon(props) {

    const { width, height } = props

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={`${width ? width : '74'}`} height={`${height ? height : '74'}`} viewBox="0 0 74 74" fill="none">
            <circle cx="37" cy="37" r="37" fill="#21C273" />
            <path d="M23.5449 37.3056L33.0422 47.0908L50.454 29.1514" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

export default SuccessIcon;