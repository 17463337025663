import React, { useState } from 'react'
import './stepCreateProfile.css'
import TickIcon from '@/pad/assets/svgicons/donor/TickIcon'

const StepCreateProfile = (props) => {

    const { current, items, stepPanel } = props

    return (
        <div className='create_header d-flex flex-row justify-content-between'>
            {
                items.map((item, index) => (
                    <>
                        <div className="number_contain">
                            <div className={`number_box ${index < current ? "success" : index === current ? "active" : ""}`}>
                                {
                                    index < current ? <TickIcon /> : index + 1
                                }
                            </div>
                            <p className={`text ${index < current ? "success" : index === current ? "active" : ""}`}>
                                {item.title}
                            </p>
                        </div>
                        {
                            index < current ? (<>
                                {
                                    items.length !== index + 1 && <div className='success_line'></div>
                                }
                            </>) : (<>
                                {
                                    items.length !== index + 1 && <div className='line_dashed'></div>
                                }
                            </>)
                        }

                    </>
                ))
            }
        </div>
    )
}

export default StepCreateProfile