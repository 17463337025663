import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "@/pad/styles/pages/chart/chart.css";
import questionAPI from "@/api/app/question/question";
import { Modal } from "react-bootstrap";
import { Space, Empty, Spin, Row, Col } from "antd";
import {
  Player,
  BigPlayButton,
  LoadingSpinner,
  ControlBar,
  PlayToggle,
  CurrentTimeDisplay,
  TimeDivider,
  DurationDisplay,
} from "video-react";
import "video-react/dist/video-react.css";
import urlUtils from "@/utils/urlUtils";
import CDonorLayout from "@/pad/components/layout/CDonorLayout";
import Vector from "@/pad/assets/svgicons/donor/Vector";
import CancelButton from "@/components/button/cancel/cancelButton";
import CloseBtn from "@/pad/assets/svgicons/donor/CloseBtn";
import preRegistrationRecordsApi from "@/api/app/preRegistrationRecords/preRegistrationRecords";
import donorApi from "@/api/app/donor/donor";
import { fetchLang, defaultLangg } from "@/utils/defaultLanguage";
import "./VideoPage.css"
import AlertErrorSVG from "@/pad/assets/svgicons/donor/AlertErrorSVG";
import WatchVideosDetails from "@/pad/components/donor/process/createProfile/detailsPage/WatchVideosDetails";
import donorAPI from "@/api/app/donor/donor";
import { getDonor,postProcess } from "@/pad/components/service/donor/donor";
import moment from "moment";
import { getEducationApp, getWatchVideoApp } from "@/components/service/visitor/visitor";
import { useSelector } from "react-redux";
import DownTimeMillisecond from "@/pad/components/donor/process/createProfile/detailsPage/DownTimeMillisecond";

const VideoPage = () => {
  const [data, setData] = useState(null)
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = React.useRef();
  const [isNext, setIsNext] = useState(false);
  const [isENext, setIsENext] = useState(false);
  const [show, setShow] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [isloadingVideo, setisloadingVideo] = useState(false);
  const [videoURL, setVideoURL] = useState(null);
  const [educationURL, setEducationURL] = useState(null);
  const [currentProcess, setcurrentProcess] = useState(0);
  const educationRef = React.useRef();
  let query = urlUtils.url2query(location.search);
  const [fetchLanguage, setFetchLanguage] = useState()
  const [fetchLangVideo, setFetchLangVideo] = useState([])
  let defaultLang = defaultLangg(fetchLangVideo);
  const [contentLanguage, setContentLanguage] = useState('')
  const [educationVideo, setEducationVideo] = useState([])
  const [educationVideoCount, setEducationVideoCount] = useState(0)
  const [activeVideo, setActiveVideo] = useState(0)
  const [viewVideo, setviewVideo] = useState(0)
  const [isAgree, setIsAgree] = useState('');
  const [stopAtConsentVideo,setStopAtConsentVideo] = useState(localStorage.getItem("reset_content_step") || false);
  const [videosConsent, setVideosConsent] = useState({
    consent: [],
    education: []
  });
  const [donorProcess, setDonorProcess] = useState(null)
  const [watchEducationData, setWatchEducationData] = useState([]);
  const [watchConsentData, setWatchConsentData] = useState([]);
  const [loading, setLoading] = useState(false)


  const user = useSelector((state) => state.authReducer)
  const {visitor_user} = user;
  let donorId = visitor_user ? visitor_user.donorId : "";
  let visitorProcess = localStorage.getItem("process") ?? '';
  let appCode = query.app_unique_code ? query.app_unique_code : "";
  

  useEffect(async () => {
    if (appCode) {
      preRegistrationRecordsApi
        .get({
          appUniqueCode: appCode,
        })
        .then((result) => {
          if (!result || !result.data) {
            console.log("no need to auto fill");
          } else {
            if (result.data.consentvideo) {
              setIsNext(true);
              setIsENext(true);
            }
          }
        }).catch((err) => {
          console.log(err)
        });
    }
  }, [appCode]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (videoRef.current && videoURL) {
        if (
          videoRef.current.video.props.player.currentTime >
          videoRef.current.video.props.player.duration - 1
        ) {
          setIsNext(true);
        }
      }
      if (educationRef.current && educationURL) {
        if (
          educationRef.current.video.props.player.currentTime >
          educationRef.current.video.props.player.duration - 1
        ) {
          setIsENext(true);
        }
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  });

  const getEducationCurrent = async () => {
      let res;
      await getEducationApp({ status: 1 })
      .then((result) => {
        if(result.status === 200 && result.data) {
          res = result.data
        }
      }).catch((error) => {})
      if(res) {

        res.sysLangs && setFetchLangVideo(res.sysLangs)
        res.sysLangs && !contentLanguage && setContentLanguage(res.sysLangs[0])

        if(res.questionnaires && res.questionnaires.length > 0) {
          let videos = res.questionnaires[0].educationVideos
          setEducationVideo(videos)
          setWatchEducationData(videos.map(it => ({
            ...it, videoType: 'education'
          })))
          setEducationVideoCount(videos.length)
          setVideosConsent({
            ...videosConsent,
            education: videos
          })

          if(videos[activeVideo].video) {
            let defultLang = contentLanguage ? contentLanguage : res.sysLangs[0]
            let findVidoeByLang = videos[activeVideo].video.find(it => it.language === defultLang && it.url !== '')
            let filterVidoeByLang = videos[activeVideo].video.find(it => it.language !== defultLang && it.url !== '')
            findVidoeByLang ? setEducationURL(findVidoeByLang.url) : filterVidoeByLang && setEducationURL(filterVidoeByLang.url)

            if (educationRef.current && findVidoeByLang) {
              educationRef.current.load()
            }
          }

        }

      }
  }

  const getConsentCurrent = async () => {
      let res = null
      await getWatchVideoApp({ status: 1 })
      .then((result) => {
        if(result.status === 200 && result.data) {
          res = result.data
        }
      }).catch((error) => {});
      if(res) {

        res.sysLangs && setFetchLangVideo(res.sysLangs)
        res.sysLangs && !contentLanguage && setContentLanguage(res.sysLangs[0])

        if(res.questionnaires && res.questionnaires.length > 0) {
          let videos = res.questionnaires[0].consentVideos
          setEducationVideo(videos)
          setWatchConsentData((videos.map(it => ({
            ...it, videoType: 'consent'
          }))))
          setVideosConsent({
            ...videosConsent,
            consent: videos
          })

          if(videos[activeVideo].video) {
            let defultLang = contentLanguage ? contentLanguage : res.sysLangs[0]
            let findVidoeByLang = videos[activeVideo].video.find(it => it.language === defultLang && it.url !== '')
            let filterVidoeByLang = videos[activeVideo].video.find(it => it.language !== defultLang && it.url !== '')
            findVidoeByLang ? setVideoURL(findVidoeByLang.url) : filterVidoeByLang && setVideoURL(filterVidoeByLang.url)

            if (videoRef.current && findVidoeByLang) {
              videoRef.current.load()
            }
          }

        }

      }
  }


  const requestData = async () => {
    try {
      setisloadingVideo(true)
      setisloading(true)
      let dataLanguageSetting = await donorApi.getLanguageSettings()
      if (dataLanguageSetting) {
        setFetchLanguage(dataLanguageSetting)
      }
      if (currentProcess === 0) {
        await getEducationCurrent();
      }
      if (currentProcess === 1) {
        await getConsentCurrent();
      }
    } finally {
      setTimeout(() => {
        setisloading(false)
        setisloadingVideo(false)
      }, 3000);
    }
  }
  useEffect(async () => {
    requestData();
  }, [currentProcess, activeVideo, contentLanguage, defaultLang])


  const [newCurrentProcess, setNewCurrentProcess] = useState('')
  const [newProcessTotal, setNewProcessTotal] = useState(1)
  const handleNextEducation = async () => {
    setisloading(true)
    setVideoURL(null)
    setEducationURL(null)
    setisloadingVideo(true)
    let videoLength = educationVideo.length
    let currentVideo = activeVideo + 1
    if (activeVideo === viewVideo) {
      setviewVideo(viewVideo + 1)
    }
    if (currentVideo !== videoLength) {
      setActiveVideo(
        currentVideo !== videoLength ? currentVideo : activeVideo
      )
      setIsENext(false)
      educationRef.current.load()
    } else {
      setActiveVideo(0)
      setcurrentProcess(1)
    }

    // record watched education

    if (visitorProcess == "literacy") {
      let res = await postProcess("/donors/process", {
        donorId: donorId,
        process: "education"
      });
      console.log(res);
      localStorage.setItem("process", "education");
    }

  }

  const requestDonorProcess = async (resData, status) => {
    setisloading(true);
    let res = await getDonor("/donors/process", {
      donorId: donorId,
      process: "consent",
    });
    if(res) {
      setNewProcessTotal(res.length)
      let vdNew = res.pop()
      setDonorProcess(vdNew)
      if(vdNew && vdNew.ids && vdNew.ids.consentId && moment(vdNew.createdAt).isSame(new Date(), 'day') || resData && resData.isCompleted || data && data.isCompleted ) {
        if(vdNew.ids.consentId.action === 'agree') {
          setcurrentProcess(1)
          setIsAgree(vdNew.ids.consentId.action)
        } else {
          if(vdNew.ids.consentId.action === 'disagree' && status === 'disagree') {
            setcurrentProcess(1)
            setIsAgree(vdNew.ids.consentId.action)
          } else {
            setcurrentProcess(0)
            setIsAgree('')
          }
        }
      }
    }
    setisloading(false);
  }

  const getDonors = async (status) => {
    setisloading(true);
    if (donorId) {
      let res = await donorAPI.get({ donorId: donorId });
      if (res) {
        if(res.watchVideo) {
          setcurrentProcess(1);
          setNewCurrentProcess(1);
        } else {
          setcurrentProcess(0);
          setNewCurrentProcess(0);
        }
        await requestDonorProcess(res, status)
        setisloading(false)
        setData && setData(res)
      }
      else {
          navigate("/visitor/donor/create/account")
      }
    }
  }
  useEffect(() => {
    getDonors()
  }, [donorId,stopAtConsentVideo]);

  const handlePrevious = () => {
    if (activeVideo === 0) {
      navigate(
        `/visitor/donor/create/check?id=${donorId}&app_unique_code=${appCode}`
      );
    } else {
      setActiveVideo(activeVideo - 1)
      setNewCurrentProcess(0)
    }
  }

  const questionEFooter = (
    <div
      className="w-100 h-100 d-flex flex-row justify-content-between align-items-center div-padding-checklist-footer- pt-2"
      // style={{ padding: "2rem", paddingTop: 0 }}
    >
      <CancelButton donorId={donorId} position={"consent"} visitId={data && data.lastVisitId && data.lastVisitId }/>
      <Space size={"middle"}>
        <button
          className="btn_white"
          onClick={() => { handlePrevious(); }}
        >
          <Vector />
          <span style={{ marginLeft: "0.3rem" }}>Previous</span>
        </button>

        <button
          className="btn_next"
          style={!isENext ? { opacity: 0.4 } : {}}
          disabled={!isENext}
          onClick={handleNextEducation}
        >
          Next
        </button>
      </Space>
    </div>
  );

  const submitWactVideos = async (status) => {
    let videosProcess = donorProcess && donorProcess.ids && donorProcess.ids.consentId ? donorProcess.ids.consentId.videos : []
    let videos = []
    setStopAtConsentVideo(false);
    if(newCurrentProcess === 0) {
      videos = watchEducationData.concat(watchConsentData).map(it => ({
        name: it.name,
        video: it.video,
        watchAt: moment(new Date()).format("MM/DD/YYYY hh:mm"),
        videoId: it._id,
        videoType: it.videoType
      }))
    }
    if(newCurrentProcess === 1) {
      let videosEducationArr = videosProcess.filter(it => it.videoType !== 'consent')
      videos = videosEducationArr.concat(watchConsentData).map(it => ({
        name: it.name,
        video: it.video,
        watchAt: it.videoType === 'education' ? it.watchAt : moment(new Date()).format("MM/DD/YYYY hh:mm"),
        videoId: it._id,
        videoType: it.videoType
      }))
    }
    let params = {
      donorId: donorId,
      action: status,
      consentVersion: `Consent video v_${newProcessTotal}.0`,
      videos: videos
    }
    setLoading(true)
    questionAPI
        .postConsent(params)
        .then((res) => {
          setLoading(false)
          if (res) {
            getDonors(status)
            status === 'disagree' && setShow(false);
          }
        })
        .catch((err) => { setLoading(false)});
  }
  
  const questionFooter = (
    <div
      className="w-100 h-100 d-flex flex-row justify-content-between align-items-center div-padding-checklist-footer- pt-2"
      // style={{ padding: "2rem", paddingTop: 0 }}
    >
      <CancelButton donorId={donorId} position={"consent"} visitId={data && data.lastVisitId && data.lastVisitId }/>
      <Space size={"middle"}>
        <button
          className="btn_white"
          onClick={() => {
            setActiveVideo(educationVideoCount - 1)
            setcurrentProcess(0);
            setNewCurrentProcess(0)
            setEducationURL(null);
            setIsENext(false)
            setIsNext(false);
          }}
        >
          <Vector />
          <span style={{ marginLeft: "0.3rem" }}>Previous</span>
        </button>
        <button className="btn_white" onClick={() => setShow(true)}>
          Disagree
        </button>
        <button
          className="btn_next"
          style={!isNext ? { opacity: 0.4 } : {}}
          disabled={!isNext}
          onClick={() => { submitWactVideos(`agree`) }}
        >
          Agree
        </button>
      </Space>
    </div>
  );

  const questionAgreeFooter = (
    <div
      className="w-100 h-100 d-flex flex-row justify-content-between align-items-center div-padding-checklist-footer- pt-2"
      // style={{ padding: "2rem", paddingTop: 0 }}
    >
      <div></div>
      <Space size={"middle"}>
        {isAgree === 'disagree' && <button
          className="btn_white"
          onClick={() => {
            navigate(`/`);
          }}
        >
          <span style={{ marginLeft: "0.3rem" }}>Close <span style={{color:"rgba(0, 117, 255, 1)"}}><DownTimeMillisecond timeTotal={180} />s</span></span>
        </button>}

        {isAgree === 'agree' && <>
          <button
            className="btn_white"
            onClick={() => {
              navigate(
                `/visitor/donor/create/check?id=${donorId}&app_unique_code=${appCode}`
              );
            }}
          >
            <Vector />
            <span style={{ marginLeft: "0.3rem" }}>Previous</span>
          </button>

          <button
            className="btn_next"
            onClick={() => {
              navigate(
                `/visitor/donor/create/checklist?id=${donorId}&app_unique_code=${appCode}`
              );
            }}
          >
            Next
          </button>
        </>}
      </Space>
    </div>
  );

  const handleChangeLanguage = (event) => {
    setContentLanguage(event.target.value)
  }

  return (
    <CDonorLayout
      donorId={donorId}
      data={data} setData={setData}
      current={1}
      footer={
        data && data.isCompleted ? <>
          {questionAgreeFooter}
        </> : <>
          {currentProcess === 1 && isAgree && questionAgreeFooter}
          {currentProcess === 1 && !isAgree && questionFooter}
          {currentProcess === 0 && questionEFooter}
        </>
      }
    >
      <DisagreeModal submitWactVideos={submitWactVideos} show={show} setShow={setShow} isAgree={isAgree} loading={loading} setIsAgree={setIsAgree} donorId={donorId} requestDonorProcess={requestDonorProcess} getDonors={getDonors} />

      {data && donorProcess && data.isCompleted ? <div className="video_content">
        <WatchVideosDetails
          isAgree={isAgree}
          donorProcess={donorProcess} setDonorProcess={setDonorProcess}
          setIsAgree={setIsAgree}
          videosConsent={videosConsent} setVideosConsent={setVideosConsent}
          data={data} setData={setData} />
      </div> : <>
        {isAgree === 'agree' || isAgree === 'disagree' ? <div className="video_content">
          <WatchVideosDetails
            isAgree={isAgree}
            donorProcess={donorProcess} setDonorProcess={setDonorProcess}
            setIsAgree={setIsAgree}
            videosConsent={videosConsent} setVideosConsent={setVideosConsent}
            data={data} setData={setData} />
        </div> : ''}
        {!isAgree && <div className="video_content">
          <div className="video_header">
            <div className="left_section">
              <p className="first_title">
                {/* Education Video */}
                {currentProcess === 0 ? "Education Video" : "Consent Video"}
                {
                  currentProcess === 0 ? (
                    educationVideo && educationVideo.length > 0 ? (
                      <span className="count_title">
                        ({activeVideo + 1}/{educationVideo ? educationVideo.length : 0})
                      </span>
                    ) : null
                  ) : null
                }
              </p>
              <p className="second_title">Please watch the {`${currentProcess === 0 ? "Education Video" : "Consent Video"}`}</p>
            </div>
            <div className="right_section">
              <p className="select_title">
                Select the language
              </p>
              <select
                name="text"
                className="checkin_subheader select_lang"
                value={contentLanguage}
                onChange={handleChangeLanguage}
              >
                {
                  fetchLangVideo && fetchLangVideo.map((item, index) => <option value={item} key={index}>{item}</option>)
                }
              </select>
            </div>
          </div>
          <div className="h-100 w-100 div-videos" 
          // style={{ padding: "4rem 12rem", paddingTop: "1rem", paddingBottom: "1rem" }}
          >
            {!isloadingVideo ? <div className="w-100- h-100- dash_box">
              {
                !isloadingVideo && educationURL && currentProcess === 0 ? (
                  <Player playsInline={true} aspectRatio="auto" fluid={false} width="100%" height="100%" ref={educationRef}>
                    <source src={educationURL} type="video/mp4" />
                    <BigPlayButton position={"center"} />
                    <LoadingSpinner />
                    <ControlBar autoHide={false} disableDefaultControls={true}>
                      <PlayToggle />
                      <CurrentTimeDisplay />
                      <TimeDivider />
                      <DurationDisplay />
                    </ControlBar>
                  </Player>

                ) : isloading ? (
                  <Spin
                    size="large"
                    className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                  ></Spin>
                ) : (
                  <Empty
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>No Content</span>}
                  ></Empty>
                ) && !isloadingVideo && videoURL && currentProcess === 1 ? (
                  <Player playsInline={true} aspectRatio="auto" fluid={false} width="100%" height="100%" ref={videoRef}>
                    <source src={videoURL} type="video/mp4" />
                    <BigPlayButton position={"center"} />
                    <LoadingSpinner />
                    <ControlBar autoHide={false} disableDefaultControls={true}>
                      <PlayToggle />
                      <CurrentTimeDisplay />
                      <TimeDivider />
                      <DurationDisplay />
                    </ControlBar>
                  </Player>
                ) : isloading ? (
                  <Spin
                    size="large"
                    className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                  ></Spin>
                ) : (
                  <Empty
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>No Content</span>}
                  ></Empty>
                )}
            </div> : <Spin
                size="large"
                className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
              ></Spin>}
          </div>
        </div>}
      </>}
    </CDonorLayout>
  );
};

const DisagreeModal = ({ show, setShow, donorId, getDonors, requestDonorProcess, isAgree, setIsAgree, submitWactVideos, loading }) => {
  const navigate = useNavigate();

  return (
    <Modal
      show={show}
      className="modal-50w"
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ borderRadius: "0.625rem" }}
    >
      <Spin tip="Loading..." spinning={loading}>
      <Modal.Body>
        <div
          onClick={() => setShow(false)}
          style={{ textAlign: "right", cursor: "pointer" }}
        >
          <CloseBtn />
        </div>

        <div
          className="w-100 d-flex flex-column align-items-center"
          style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
        >
          <div className="notes_title">
            <Row>
              <Col >
                <div style={{
                  width: "10rem",
                  height: "5rem",
                  borderRadius: "1rem"
                }}>
                  <span><AlertErrorSVG /></span>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="notes_box_left_value"
            style={{ margin: "2rem 0", opacity: 0.9 }}
          >
            Please double confirm if you disagree to the consent video.
            If confirmed, you are not able to continue for the process.
          </div>
          <div
            className="w-100 d-flex align-items-center justify-content-around"
            style={{ marginTop: "3rem", paddingBottom: "1rem" }}
          >
            <button
              className="btn_white"
              onClick={() => setShow(false)}
              style={{ width: "35%", paddingLeft: '0px', paddingRight: '0px' }}
            >
              <Vector />
              <span style={{ marginLeft: "0.3rem" }}>Back to Page</span>
            </button>
            <button
              style={{ width: "35%" }}
              className="btn_next"
              onClick={() => { submitWactVideos(`disagree`)}}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal.Body>
      </Spin>
    </Modal>
  );
};

export default VideoPage;
