import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { donorAPI, donorAPIGet, donorAPIPut } from "../../service/donor/donor";
import CloseBtn from "../../../assets/svgicons/process/CloseBtn";
import customMessage from "@/utils/showMessage";

const CancelModal = ({ abortshow, setabort, position, donorId, visitId }) => {
  const navigate = useNavigate();

  const [cancelOptions, setCancelOptions] = useState([]);
  useEffect( async() => {
    await donorAPIGet("/aborts/reasons", { category: "Cencel" }).then(res => {
        let arr = [];
        res && res.length && res.map(item => {
           item.status === true && !item.isDeleted ?  arr.push({
            label: item.title,
            value: item._id
        }) : null
        })
        setCancelOptions(arr)
    })
}, [])

  const handleAbort = async () => {
    if (!document.getElementById("abort_reason").value) {
      customMessage.showMessage("The reason for abort action cannot be empty !!",'error');
      return false;
    }
    
    let resultA = await donorAPIPut("/visits/cancel", 
      {
        "visitId": visitId, // null or "",get from key: lastVisitId if don't have
        "donorId": donorId,
        "position": position,
        "reasonId": document.getElementById("selectEthnicity").value, // abort reason id
        "description": document.getElementById("abort_reason").value,
      });
    if(resultA && resultA.status === 200) {
      setabort(false);
      navigate("/");
    }
  };
  return (
    <Modal
      show={abortshow}
      onHide={() => setabort(false)}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ borderRadius: "0.625rem" }}
    >
      <Modal.Body>
        <div onClick={() => setabort(false)} style={{ textAlign: "right" }}>
          <CloseBtn />
        </div>

        <div
          className="d-flex flex-column align-items-center"
          style={{ paddingLeft: "3.3rem", paddingRight: "3.3rem" }}
        >
          <div className="notes_title">Visit Cancel</div>
          <Col
            className="w-100"
            style={{ marginBottom: "2.5rem", marginTop: "2.5rem" }}
          >
            <Row
              className="align-items-center"
              style={{ marginBottom: "1.38rem" }}
            >
              <Col
                sm={4}
                xs={4}
                md={4}
                className="notes_left align-items-center"
              >
                Visit Cancel Reason
              </Col>
              <Col>
                <select
                  id="selectEthnicity"
                  style={{
                    height: "2.25rem",
                    width: "100%",
                    borderRadius: "0.375rem",
                    border: "0.0938rem solid rgba(21,44,91,0.1)",
                  }}
                >
                  <option value={''}>Please select Visit Cancel Reason</option>
                  {cancelOptions.length &&
                    cancelOptions.map((item) => {
                      return <option value={item.value}>{item.label}</option>;
                    })}
                </select>
              </Col>
            </Row>

            <Row
              className="align-items-center"
              style={{ marginBottom: "1.38rem" }}
            >
              <Col
                sm={4}
                xs={4}
                md={4}
                className="notes_left align-items-center"
              >
                More Description
              </Col>
              <Col>
                <textarea
                  id="abort_reason"
                  placeholder=""
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    height: "8.25rem",
                    borderRadius: "0.375rem",
                    background: "rgba(246,246,249,0.4)",
                    border: "0.0938rem solid rgba(21,44,91,0.1)",
                  }}
                />
              </Col>
            </Row>

            {/*<Row
              className="align-items-center"
              style={{ marginBottom: "1.38rem" }}
            >
              <Col
                sm={4}
                xs={4}
                md={4}
                className="notes_left align-items-center"
              >
                Employee Password
              </Col>
              <Col>
                <input
                  type="password"
                  id="password"
                  placeholder=""
                  style={{
                    height: "2.25rem",
                    borderRadius: "0.375rem",
                    background: "rgba(246,246,249,0.4)",
                    border: "0.0938rem solid rgba(21,44,91,0.1)",
                  }}
                />
              </Col>
            </Row>*/}
          </Col>
          <div className="notes_btn" onClick={handleAbort}>
            Confirm
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CancelModal;
