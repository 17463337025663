import { DONORSTEP, setDonorStep } from '../../actions/index';

const initState = {
    donorStep: 0,
}
//登录状态
export const stepStateReducer = (state = initState, action) => {
    switch (action.type) {
        case DONORSTEP:
            return {
                ...state,
                donorStep: action.donorStep
            }
        default:
            return state
    }
}