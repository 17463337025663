import ApiService from '../../request/request'

export const sigAPI = async (url, param) => {
    const result = await ApiService.post(url, param);
    return result.data
}

export const getsig = async (url, param) => {
    const result = await ApiService.get(url, param);
    return result.data
}

export const getdeferal = async (url, param) => {
    const result = await ApiService.get(url, param);
    return result.data
}

export const Adddeferral = async (url, param) => {
    const result = await ApiService.post(url, param);
    return result.data
}


