import { message, Button, Modal, Spin } from "antd";
import { CloseOutlined } from '@ant-design/icons';

const showMessage = (content,type = 'info',autoRefresh = false) => {

    switch (type) {
        case 'error':
            return message.error({
                content : (
                    <div className="message_">
                      {content}
                        <CloseOutlined 
                          style={{ color: '#000' ,fontSize : '12px' }} 
                          onClick={() => { 
                            message.destroy();
                            if (autoRefresh) {
                              window.location.reload();
                            }
                          }}
                          />
                    </div>
                  ),
                duration: 30,
                maxCount: 1,
              });
        case 'success':
                return message.success({
                    content : (
                        <div className="message_">
                          {content}
                          <CloseOutlined 
                          style={{ color: '#000' ,fontSize : '12px' }} 
                          onClick={() => { 
                            message.destroy();
                            if (autoRefresh) {
                              window.location.reload();
                            }
                          }}
                          />
                        </div>
                      ),
                    duration: 30,
                    maxCount: 1,
                  });
        default:
            return message.info({
                content : (
                    <div className="message_">
                      {content}
                      <CloseOutlined 
                          style={{ color: '#000' ,fontSize : '12px' }} 
                          onClick={() => { 
                            message.destroy();
                            if (autoRefresh) {
                              window.location.reload();
                            }
                          }}
                          />
                    </div>
                  ),
                duration: 30,
                maxCount: 1,
              });
    }
    
}

export default {
    showMessage,
};
