import request from "@/utils/request";

export default {
  getProess: async (params) =>
    await request.get("/visitor/donors/process", { params, returnAll: true }),

  //   { donorId: 'aa' }
  get: async (params) =>
    await request.get("/visitor/questionnaires/search", { params }),

  getConsent: async (params) =>
    await request.get("/visitor/questionnaireConsent/list", { params }),

  postRecord: async (data) => await request.post("/visitor/donors/record", data),
  postDeferral: async (data) =>
    await request.post("/visitor/donors/deferral", data),
  postConsent: async (data) => await request.post("/visitor/donors/consent", data),

  postVerify: async (data) => await request.post("/visitor/verify", data),
};
