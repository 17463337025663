import React from "react";

const FormCard = ({ children, title, star }) => {
  return (
    <div>
      <div
        className="d-flex flex-row align-items-center justify-content-center"
        style={{ paddingBottom: "1.25rem" }}
      >
        <div className="form_card_text">{title}</div>
        {star && (
          <div
            className="form_card_star"
            style={{ paddingLeft: "0.5rem", marginTop: "0.375rem" }}
          >
            {star}
          </div>
        )}

        <div className="form_card_line" />
      </div>

      {children}
    </div>
  );
};

export default FormCard;
