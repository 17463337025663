import WarningSVG from '@/components/timeOut/WarningSVG';
import React, { forwardRef, useImperativeHandle, useState } from 'react'

const DonorVerifyFailed = forwardRef(
    ({ onConfirm, onCancel }, ref) => {
        useImperativeHandle(ref, () => {
        })
        const cancelClick = () => {
            onCancel();
        };

        return (
            <div className="d-flex flex-column align-items-center justify-content-center pb-3" style={{gap: "2rem"}}>
                <WarningSVG />
                <text style={{ color: "#152C5B", fontWeight: "bold" }}>
                    Not found.Try again.
                </text>
                <button
                    className="btn_next"
                    onClick={() => cancelClick()}
                >
                    Modify
                </button>
            </div>
        )
    }
)

export default DonorVerifyFailed