import React from "react";
import { Modal, message, Image } from "antd";
import CloseBtn from "@/components/verification/svgicons/CloseBtn";
const TipsModal = ({ question, setIsModalOpen, isModalOpen, className }) => {
  const closeClick = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      open={isModalOpen}
      className="pacs-modal-css"
      closable={false}
      width={"45%"}
      keyboard={false}
      backdrop="static"
      centered
      footer={null}
      style={{ borderRadius: "10px" }}
    >
      <div onClick={() => closeClick()} style={{ textAlign: "right" }}>
        <CloseBtn />
      </div>
      <div className={`d-flex flex-column align-items-center justify-content-center`}>
        <div className={`cus-modal-scroll`}>
        <div style={{ width: "60%", margin: "1.5rem auto" }}>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.2rem",
              opacity: "0.8",
              marginBottom: "2.5rem"
            }}
          >
            <span>
              {question && question.information
                ? question.information.title
                : null}
            </span>
          </div>
          {
            question && question.information && question?.information?.files?.length && question.information?.files[0] !== '' ? (
              <div
                style={{
                  width: "100%",
                  marginBottom: "1.5rem",
                }}
                className="d-flex flex-row align-items-center justify-content-center"
              >
                <Image
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  src={question.information.files[0]}
                />
              </div>
            ) : ""
          }
          {question && question.information && question?.information?.text ? <ul
            style={{
              fontSize: "0.80rem",
              fontWeight: "400",
            }}
          >
            <li>
              {question && question.information
                ? <div
                    style={{opacity: "1"}}
                    dangerouslySetInnerHTML={{
                      __html: question.information.text,
                    }}
                  ></div>
                : null}
            </li>
          </ul> : ''}
        </div>
        </div>
      </div>
    </Modal>
  );
};

export default TipsModal;
