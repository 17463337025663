
export const DONORSTEP = 'DONORSTEP';



export const setDonorStep = (donorStep) => ({
    type: DONORSTEP,
    donorStep
})

