import React, { useState, useEffect } from "react";
import { Radio, Checkbox, Input } from "antd";
import "./choice.css";

//Select Group 参数
/*
  type: 可选 String [radio, checkbox, textarea]
  value: 可选 string || array
  options: 可选 array 按照ant design里 radio和checkbox的options数据格式
  onChange： 可选 按照ant design标准
  name: 可选 string
  className: 可选 string
  style: 可选 object


*/

const ChoiceGroup = React.forwardRef((props, ref) => {
  React.useImperativeHandle(ref, () => ({
    value: selectValue,
  }));

  const TYPES = ["checkbox", "radio", "textarea"];
  const type =
    TYPES.findIndex((i) => i === props.type) != -1 ? props.type : "textarea";
  const onChange = (e) => {
    if (type === "checkbox") {
      setSelectValue(e);
      props.onChange instanceof Function && props.onChange(e);
    } else {
      setSelectValue(e.target.value);
      props.onChange instanceof Function && props.onChange(e.target.value);
    }
  };
  const options = props.options?.length > 0 ? props.options : [""];
  const name = props.name ? props.name : null;

  useEffect(() => {
    let value = props.value ? props.value : type === "checkbox" ? [] : "";
    setSelectValue(value);
  }, [props]);

  const [selectValue, setSelectValue] = useState(null);

  return (
    <div
      className={props.className ? props.className : "choice-main"}
      style={props.style ? props.style : {}}
    >
      {type === "radio" && (
        <Radio.Group
          disabled={props.disabled ? true : false}
          className="choice-main-answer"
          options={options}
          name={name}
          onChange={onChange}
          value={selectValue}
        />
      )}
      {type === "checkbox" && (
        <Checkbox.Group
          disabled={props.disabled ? true : false}
          className="choice-main-answer"
          options={options}
          value={selectValue}
          onChange={onChange}
        />
      )}
      {type === "textarea" && (
        <Input.TextArea
          className="choice-main-answer"
          style={{ height: "4.25rem" }}
          disabled={props.disabled ? true : false}
          value={selectValue}
          onChange={onChange}
        />
      )}
    </div>
  );
});

export default ChoiceGroup;
