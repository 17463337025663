import { IS_CHANGE } from "../../actions/isChange"

const initState = {
  isChange: false,
}

export const isChangeStateReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case IS_CHANGE:
      return { ...state, ...action }
    default:
      return state
  }
}
