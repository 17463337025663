import request from "@/utils/request";
import requestAPI from "@/utils/requestAPI";
import requestAPIData from "@/utils/requestAPIData";

export default {
  getList: async (params) =>
    await request.get("/visitor/donors", { params, returnAll: true }),

  //   { donorId: 'aa' }
  get: async (params) => await request.get("/visitor/donors/detail", { params }),

  add: async (data) => await requestAPI.post("/visitor/donors", data),

  put: async (id, data) => await requestAPI.put(`/visitor/donors/${id}`, data),

  upload: async (data) => await request.post("/visitor/donors/upload", data),

  postConsent: async (data) => await request.post("/visitor/donors/consent", data),

  getConsent: async (params) => await request.get("/visitor/donors/consent", { params }),

  accountUpdate: async (id, data) =>
    await request.put(`/visitor/donors/${id}`, data),
  getVisit: async (id, params) =>
    await request.get(`/visitor/visits/${id}`, { params }),

  getProcessKpi: async (params) =>
    await request.get(`/visitor/siteProcessKpis/detail`, { params }),

  getListKeyword: async (params) =>
    await request.get("/visitor/donors/keyword", { params, returnAll: true }),

  getIdentify: async (params) =>
    await request.get("/visitor/screening/identify", { params }),

  getCode: async (params) =>
    await request.get("/visitor/setting/getNode", { params }),

  getPrograms: async (params) =>
    await request.get("/visitor/donorPrograms", { params }),

  queuePut: async (id, data) => await request.put(`/visitor/queue?` + id, data),

  queueQuery: async (params) =>
    await request.get("/visitor/donorCheckRecords/recent", { params }),

  queueQuerys: async (params) =>
    await requestAPI.get("/visitor/donorCheckRecords/recent", { params }),

  identifyPut: async (id, data) =>
    await request.put(`/visitor/screening/identify?donorId=${id}`, data),

  roomNextCall: async (data) =>
    await request.put(`/visitor/queue/roomNextCall`, data),

  donorCheckRecordsGet: async (id) =>
    await request.get(`/visitor/donorCheckRecords/${id}`),

  donorCheckRecordsPut: async (id, data) => {
    await request.put(`/visitor/donorCheckRecords/${id}/updateItem`, data)
  },

  donorCheckRecordsPost: async (data) =>
    await request.post(`/visitor/donorCheckRecords`, data),

  donorCheckRecordsProcess: async (id, params) =>
    await request.put(`/visitor/donorCheckRecords/${id}/Process`, params),

  getMedicaltion: async (params) =>
    await request.get("/visitor/medicalMedications", { params }),

  getConditions: async (params) =>
    await request.get("/visitor/medicalConditions", { params }),

  getMedicalConditionSeverities: async (params) =>
    await request.get("/visitor/medicalConditionSeverities", { params }),

  getmedicalProcedures: async (params) =>
    await request.get("/visitor/medicalProcedures", { params }),

  addItem: async (id, data) =>
    await request.put(`/visitor/donorCheckRecords/${id}/addItem`, data),

  getVisitType: async (params) =>
    await request.get(`/visitor/visitTypes`, { params }),

  getCollectionType: async (params) =>
    await request.get(`/visitor/collectionTypes`, { params }),

  getQuestionnaire: async (params) =>
    await request.get(`/visitor/questionnaire/search`, { params }),

  setRecord: async (data) => await request.post(`/visitor/donors/record`, data),

  postScreening: async (data) => await request.post(`/visitor/screening`, data),

  donorCheckRecordsRemove: async (id, data) =>
    await request.delete(`/visitor/donorCheckRecords/${id}/item`, data),

  getDonorEthnicitySetting: async (params) =>
    await request.get(`/visitor/donorEthnicitySetting/?keyword`, { params }),

  getIdentificationType: async (category, keyword) =>
    await request.get(`/visitor/donorIdentificationType/?category=${category ? category : ""}&keyword=${keyword ? keyword : ""}`),

  getDonorAddressProof: async (keyword) =>
    await request.get(`/visitor/donorAddressProof/?keyword=${keyword ? keyword : ""}`),

  getLanguageSettings: async (keyword) =>
    await request.get(`/visitor/languageSetting/?keyword=${keyword ? keyword : ""}`),

  matchDonorProgram: async (donorId) =>
    await request.get(`/visitor/donorPrograms/matching?donorId=${donorId ? donorId : ""}`),
  defaultDonorProgram: async (data) =>
    await request.put(`/visitor/donorPrograms/upDefault`, data),
  getDonorCheckIn: async (params) =>
    await request.get("/visitor/donors/check-in", { params }),

  approveDonorReviewer: (id, params) => request.put(`/visitor/donorCheckRecords/${id}/approvalItem`, params, { returnAll: true }),

  saveProofOfDoc: async (data) =>
    await request.post(`/visitor/donors/save/upload`, data),

  autoCheckIn: async (data) => await requestAPI.post(`/visitor/donors/auto-checkin`, data),
  autoCheckInNew: async (data) => await requestAPIData.post(`/visitor/donors/auto-checkin`, data),

  getDonorCanCheckIn: async (params) => await requestAPI.get(`/visitor/donors/canCheckin`, { params }),

  checkDeferralDonor: async (params) => await requestAPI.get(`/visitor/donors/checkExpired`, { params }),

  getVisitId: async (donorID) => await requestAPI.get(`/visitor/visits/getvisitid?donorID=${donorID ? donorID : ""}`),

  getDonorDeferral: async (param) => {
    return await requestAPIData.get(`/visitor/donors/deferral/state`, param);
  }
};
