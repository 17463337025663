import * as React from "react";

function faceUserSVG(props) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.3009 22.5282C38.3009 27.1105 34.584 30.8208 30.0053 30.8208C25.4266 30.8208 21.7097 27.1052 21.7097 22.5282C21.7097 17.9511 25.4266 14.2356 30.0053 14.2356C34.584 14.2356 38.3009 17.9511 38.3009 22.5282ZM43.8947 42.8184C43.403 36.7192 37.3703 31.899 30.0053 31.899C22.6402 31.899 16.6075 36.7192 16.1158 42.8184M25.2362 49.758C25.2362 49.0762 24.6811 48.5212 23.999 48.5212H15.6664C12.7955 48.5212 10.4744 46.1957 10.4744 43.331V34.8587C10.4744 34.1769 9.91925 33.622 9.2372 33.622C8.55516 33.622 8 34.1769 8 34.8587V43.331C8 47.5646 11.4314 50.9947 15.6664 50.9947H23.999C24.6811 50.9894 25.2362 50.4398 25.2362 49.758ZM52 43.3258V34.8587C52 34.1769 51.4449 33.622 50.7628 33.622C50.0808 33.622 49.5256 34.1769 49.5256 34.8587V43.331C49.5256 46.201 47.1992 48.5265 44.3283 48.5265H35.9111C35.229 48.5265 34.6739 49.0814 34.6739 49.7632C34.6739 50.445 35.229 51 35.9111 51H44.3389C48.5686 50.9894 52 47.5593 52 43.3258ZM52 22.951V14.6689C52 10.4354 48.5686 7.00529 44.3336 7.00529H35.9111C35.229 7.00529 34.6739 7.56024 34.6739 8.24204C34.6739 8.92384 35.229 9.4788 35.9111 9.4788H44.3389C47.2098 9.4788 49.5309 11.8043 49.5309 14.6689V22.951C49.5309 23.6328 50.086 24.1877 50.7681 24.1877C51.4501 24.1877 52 23.6328 52 22.951ZM10.4744 22.951V14.6689C10.4744 11.799 12.8008 9.47351 15.6717 9.47351H24.0043C24.6864 9.47351 25.2415 8.91856 25.2415 8.23676C25.2415 7.55496 24.6864 7 24.0043 7H15.6664C11.4314 7 8 10.4302 8 14.6637V22.9457C8 23.6275 8.55516 24.1825 9.2372 24.1825C9.91925 24.1877 10.4744 23.6328 10.4744 22.951Z"
        fill="#B7BECD"
      />
    </svg>
  );
}

export default faceUserSVG;
