export const SOCKET_CONNECT = "SOCKET_CONNECT";
export const SOCKET_DISCONNECT = "SOCKET_DISCONNECT";

export const SOCKET_OPEN = "SOCKET_OPEN";
export const SOCKET_CLOSE = "SOCKET_CLOSE";
export const SOCKET_MESSAGE = "SOCKET_MESSAGE";

export const MSA_JOIN_ROOM = "MSA_JOIN_ROOM";
export const MSA_EXIT_ROOM = "MSA_EXIT_ROOM";
export const ROOM_INFO_UPDATED = "ROOM_INFO_UPDATED";
export const CALL = "call";
export const QUEUE_NEXT_CALLING = "QUEUE_NEXT_CALLING";