import * as React from 'react';

const ContentAbort = () => {
	return (
		<svg width="1.3125rem" height="1.3125rem" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.71649 3.78143L13.2825 3.78108L17.2185 7.71659L17.2188 13.2826L13.2833 17.2186L7.71733 17.2189L3.78133 13.2834L3.78099 7.71742L7.71649 3.78143Z" stroke="#E44E51" strokeWidth="1.41213" />
			<circle cx="10.5" cy="14.2093" r="0.618197" fill="#E44E51" />
			<path d="M10.5 8.02722V11.4604" stroke="#E44E51" strokeWidth="2.1182" strokeLinecap="round" />
		</svg>


	);
}

export default ContentAbort;
