import * as React from 'react';

function AlertSVG(props) {
	return (
		<svg width="4.375rem" height="4.375rem" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path opacity="0.6" d="M59.8617 66H11.1383C9.5342 65.9997 7.95846 65.5735 6.56935 64.7644C5.18025 63.9552 4.02671 62.7916 3.22464 61.3903C2.42257 59.989 2.00021 58.3995 2 56.7814C1.99979 55.1634 2.42173 53.5737 3.22343 52.1723L27.5851 9.60927C28.3873 8.20786 29.5411 7.04413 30.9304 6.23504C32.3198 5.42595 33.8957 5 35.5 5C37.1043 5 38.6802 5.42595 40.0696 6.23504C41.4589 7.04413 42.6127 8.20786 43.4149 9.60927L67.7766 52.1723C68.5783 53.5737 69.0002 55.1634 69 56.7814C68.9998 58.3995 68.5774 59.989 67.7754 61.3903C66.9733 62.7916 65.8198 63.9552 64.4306 64.7644C63.0415 65.5735 61.4658 65.9997 59.8617 66Z" fill="#FDD6D7" />
			<path d="M35.0001 53.5C36.703 53.5 38.0834 52.1196 38.0834 50.4167C38.0834 48.7138 36.703 47.3334 35.0001 47.3334C33.2972 47.3334 31.9167 48.7138 31.9167 50.4167C31.9167 52.1196 33.2972 53.5 35.0001 53.5Z" fill="#E44E51" />
			<path d="M35.0001 41.1666C34.1823 41.1666 33.3981 40.8418 32.8198 40.2635C32.2416 39.6853 31.9167 38.901 31.9167 38.0833V25.75C31.9167 24.9322 32.2416 24.148 32.8198 23.5697C33.3981 22.9915 34.1823 22.6666 35.0001 22.6666C35.8178 22.6666 36.6021 22.9915 37.1803 23.5697C37.7586 24.148 38.0834 24.9322 38.0834 25.75V38.0833C38.0834 38.901 37.7586 39.6853 37.1803 40.2635C36.6021 40.8418 35.8178 41.1666 35.0001 41.1666Z" fill="#E44E51" />
		</svg>

	);
}

export default AlertSVG;
