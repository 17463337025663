import { combineReducers } from 'redux';

import { stepStateReducer } from './donorStep/donorStep';
import authReducer from '../auth/reducer';
import { isChangeStateReducer } from '@/employee/components/state/reducers/isChange/isChange';
const rootReducer = combineReducers({
    isChange: isChangeStateReducer,
    stepStateReducer,
    authReducer: authReducer,
})

export default rootReducer