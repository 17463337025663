import React, { useEffect, useRef, useState } from "react";
import urlUtils from "@/utils/urlUtils";
import questionAPI from "@/api/app/question/question";
import donorAPI from "@/api/app/donor/donor";
import errorDeal from "@/utils/errorDeal";
import { useLocation, useNavigate } from "react-router-dom";
import CDonorLayout from "@/pad/components/layout/CDonorLayout";
import QuestionBoxProgress from "@/components/questionBox/QuestionBoxProgress";
import QuestionBox from "@/components/questionBox/QuestionBox";
import Vector from "@/pad/assets/svgicons/donor/Vector";
import CancelButton from "@/components/button/cancel/cancelButton";
import "../preview.css";
import answerUtils from "@/utils/answerUtils";
import { Space, Empty, Spin, message, Button } from "antd";
import { getQuestions, getDonor } from "@/pad/components/service/donor/donor";
import { firstToUpper } from "@/components/util/Util";
import "@/pad/styles/pages/donor/donor.css";
import '@/pad/components/donor/process/createProfile/detailsPage/detailsPage.css'
import "@/pad/styles/pages/chart/chart.css";
import '@/pad/components/donor/process/qualification/ReportCheckIn.js'
import customMessage from "@/utils/showMessage";

import preRegistrationRecordsApi from "@/api/app/preRegistrationRecords/preRegistrationRecords";
import DonorVerify from "@/components/auth/DonorVerify";

import {
  Player,
  BigPlayButton,
  LoadingSpinner,
  ControlBar,
  PlayToggle,
  CurrentTimeDisplay,
  TimeDivider,
  DurationDisplay,
} from "video-react";
import Countdown from "@/components/questionBox/Countdown";
import { defaultLangg } from "@/utils/defaultLanguage";
import { useDispatch, useSelector } from "react-redux";
import { IS_CHANGE } from "@/employee/components/state/actions/isChange";
import MessageConfirmModal from "@/components/messageModal/MessageConfirmModal";
import ReportLiteracy from "@/pad/components/donor/process/literacy/ReportLiteracy";
import { DONOR_RECORD_URL } from "@/api/app/api/URLs";
import { donorRetake } from "@/pad/components/service/queue/queue";

const CheckPage = () => {
  const dispatch = useDispatch()
  const isChange = useSelector((state) => state.isChange.isChange)
  const isChangeValue = (status) => {
      dispatch({ type: IS_CHANGE, isChange: status })
  }
  const messageModalRef = useRef("rememberMe");
  const location = useLocation();
  const navigate = useNavigate();
  const verifyRef = React.useRef();
  const [isloading, setisloading] = useState(false);

  const [questions, setQuestions] = useState(null);
  const [isNext, setIsNext] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [isRetake, setIsRetake] = useState(false);
  const [currentIndex, setcurrentIndex] = useState(-1);
  const [templateIndex, setTemplateIndex] = useState(0);
  const [currentProcess, setCurrentProcess] = useState(0);
  const [isautofill, setisautofill] = useState(false);
  const [VersionRecord, setVersionRecord] = React.useState([]);
  const [donorInfo, setDonorInfo] = useState(false);
  let query = urlUtils.url2query(location.search);

  const user = useSelector((state) => state.authReducer)
  const {visitor_user} = user;
  let donorId = visitor_user ? visitor_user.donorId : "";
  let appCode = query.app_unique_code ? query.app_unique_code : "";

  const [literacyData, setLiteracyData] = useState(null)
  const [fetchLangVideo, setFetchLangVideo] = useState([])
  let defaultLang = defaultLangg(fetchLangVideo);
  const [contentLanguage, setContentLanguage] = useState(defaultLang ? defaultLang : "english")
  const [instructionData, setInstructionData] = useState()
  const [position, setPosition] = useState('literacy')
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);


  const handleGetRetake = async () => {
    setIsRetake(true);
    await getDataLiteracy();
    setIsNext(false)
    setIsStart(false)
    setisPass(true)
    setpend(false);
    setcurrentIndex(-1);
    setTemplateIndex(0);
    setCurrentProcess(0);
    isChangeValue && isChangeValue(false)
  }
  const handleRetake = async (questionId) => {
    setLoading(true)
    try {
      let res = await donorRetake(`${DONOR_RECORD_URL}?questionId=${questionId}&donorId=${donorId}&position=${position}`);
      if(res.status === 200) {
        await handleGetRetake();
      } else {
        message.error("Fail")
      }
      setTimeout(() => {
          setLoading(false)
        }, 9000);
    } catch (error) {
      setTimeout(() => {
          setLoading(false)
        }, 9000);
    }
  };

  useEffect(() => {
    if (appCode && appCode != "null") {
      preRegistrationRecordsApi
        .get({
          appUniqueCode: appCode,
        })
        .then((result) => {
          if (!result || !result.data) {
            console.log("no need to auto fill");
          } else {
            setisautofill(result.data["literacycheck"] ? true : false);
          }
        })
        .catch((err) => {
          console.log(err)
        });
    }
  }, [appCode]);

  useEffect(() => {
    let questionCheck = false;
    if (VersionRecord && VersionRecord.length > 0 && !isRetake) {
      let newQuestionData = VersionRecord[VersionRecord.length - 1]
      if(newQuestionData && newQuestionData?.ids && newQuestionData?.ids?.questionId) {
        questionCheck = true;
        setCurrentProcess(3);
      }
    }

    if (questions && !questionCheck && !isRetake) {
      if (isautofill) {
        let questionCopy = questions;
        for (let i in questionCopy.templates) {
          questionCopy.templates[i].questions = answerUtils.autoFillFunc(
            questionCopy.templates[i].questions
          );
        }
        setQuestions(questionCopy);
        setTemplateIndex(questionCopy.templates.length - 1);
        setcurrentIndex(
          questionCopy.templates[questionCopy.templates.length - 1].questions
            .length - 1
        );
        setCurrentProcess(2);
      }
    }
  }, [VersionRecord, isautofill, questions]);

  const handleConfirm = async () => {
    let list = [];
    let wrongAnswer = 0;
    let isResultPass = true;
    let isResult = "pass";
    for (var i = 0; i < questions.templates.length; i++) {
      for (var j = 0; j < questions.templates[i].questions.length; j++) {
        questions.templates[i].questions[j].article = j === 0 ? questions.templates[i].article : ''
        list.push(questions.templates[i].questions[j]);
        if (questions.templates[i].questions[j].answers?.length > 0) {
          questions.templates[i].questions[j].answers.forEach((answer) => {
            if (answer.isanswer && answer.result.toLowerCase() === "fail") {
              isResultPass = false;
            }
          });
          if (!isResultPass) {
            wrongAnswer++;
          }
        }
        isResultPass = true;
      }
    }
    if (questions.difficulty) {
      if (wrongAnswer > questions.difficulty) {
        isResult = "fail";
      }
    } else {
      if (wrongAnswer > 0) {
        isResult = "fail";
      }
    }
    await questionAPI
      .postRecord({
        donorId: donorId,
        record: list,
        result: isResult,
        position: position,
      })
      .then((res) => {
        isChangeValue && isChangeValue(false);
        if (res) {
          setCurrentProcess(3);
        } else {
          message.error("Submission failure!");
        }
      });
  };

  const getDataLiteracy = async () => {
    setisloading(true);
      let gender = "";
      if (donorId) {
        let res = await donorAPI.get({ donorId: donorId });
        if (res) {
          let questionList = await getDonor("/donors/process", {
            donorId: donorId,
            process: position,
          });
          setVersionRecord(questionList);
          setDonorInfo(res);
          gender = res.gender;
        }
      }
      let defaultLang = null
      let fetchData = await questionAPI.get({
        status: 1,
        position: position,
      });
      let questions = fetchData.questionnaires
      setInstructionData(questions)
      if (questions?.length > 0) {
        defaultLang = fetchData.sysLangs[0]
        setFetchLangVideo(questions.map((item) => item.language))
        setContentLanguage(defaultLang)
        let questionCopy = questions.find(it => it.language === defaultLang);
        setLiteracyData(questionCopy)

        for (var itemQ in questionCopy.templates) {
          questionCopy.templates[itemQ].questions = questionCopy.templates[
            itemQ
          ].questions.filter(
            (e) =>
              e.gender.toLowerCase() == gender.toLowerCase() ||
              e.gender.toLowerCase() == "all"
          );
        }
        questionCopy.templates = questionCopy.templates.filter(
          (i) => i.questions.length > 0
        );
        setQuestions(questionCopy);
      }
      setTimeout(() => {
        setisloading(false);
      }, 2000);
  }
  useEffect(
    errorDeal(async () => {
      await getDataLiteracy();
    }),
    [donorId]
  );
  const onNext = () => {
    setIsNext(true);
  };

  const handleChangeLanguage = (event) => {
    let gender = "";
    setContentLanguage(event.target.value)
    let resData = instructionData.filter((item) => item.language === event.target.value)
    if (instructionData?.length > 0) {
      setFetchLangVideo(instructionData.map((item) => item.language))
      let questionCopy = resData[0];
      setLiteracyData(questionCopy)

      for (var itemQ in questionCopy.templates) {
        questionCopy.templates[itemQ].questions = questionCopy.templates[
          itemQ
        ].questions.filter(
          (e) =>
            e.gender.toLowerCase() == gender.toLowerCase() ||
            e.gender.toLowerCase() == "all"
        );
      }
      questionCopy.templates = questionCopy.templates.filter(
        (i) => i.questions.length > 0
      );
      setQuestions(questionCopy);
    }
  }

  const questionFooter = questions && (
    <div
      className="w-100 h-100 d-flex flex-row justify-content-between align-items-center div-padding-checklist-footer- pt-2"
    >
      <CancelButton donorId={donorId} position={"literacy"} visitId={data && data.lastVisitId && data.lastVisitId } />
      <Space size={"middle"}>
        <button
          className="btn_white"
          onClick={() => {
            if (currentIndex > -1) {
              setcurrentIndex((i) => i - 1);
            } else if (currentIndex === -1) {
              if (templateIndex === 0) {
                setCurrentProcess(0);
              } else {
                setcurrentIndex(
                  questions.templates[templateIndex - 1].questions.length - 1
                );
                setTemplateIndex((i) => i - 1);
              }
            }
          }}
        >
          <Space size={"small"}>
            <Vector />
            <span>Previous</span>
          </Space>
        </button>

        {currentIndex ===
          questions.templates[templateIndex].questions.length - 1 &&
          templateIndex === questions.templates.length - 1 ? (
          <button
            className="btn_next"
            style={!isNext ? { opacity: 0.4 } : {}}
            disabled={!isNext}
            onClick={() => setCurrentProcess(2)}
          >
            Preview
          </button>
        ) : (
          <>
            {currentIndex === -1 ? (
              <button
                className="btn_next d-flex- align-items-center"
                style={!isNext ? { opacity: 0.4 } : {}}
                disabled={!isNext}
                onClick={() => {
                  if (
                    currentIndex ===
                    questions.templates[templateIndex].questions.length - 1
                  ) {
                    setcurrentIndex(-1);
                    setTemplateIndex((i) => i + 1);
                  } else {
                    setcurrentIndex((i) => i + 1);
                  }
                }}
              >
                Next
                {!isNext &&
                  <span style={{ marginLeft: "0.5rem" }}>
                    <Countdown
                      color="text-white"
                      current={0}
                      time={`${questions.templates[templateIndex].readTime ? questions.templates[templateIndex].readTime : 0}`}
                      onCount={onNext}
                    />
                  </span>}
              </button>
            ) : (
              <button
                className="btn_next"
                style={!isNext ? { opacity: 0.4 } : {}}
                disabled={!isNext}
                onClick={() => {
                  if (
                    currentIndex ===
                    questions.templates[templateIndex].questions.length - 1
                  ) {
                    setcurrentIndex(-1);
                    setTemplateIndex((i) => i + 1);
                  } else {
                    setcurrentIndex((i) => i + 1);
                  }
                }}
              >
                Next
              </button>
            )}
          </>
        )}
      </Space>
    </div>
  );

  const instructionFooter = questions && (
    <div
      className="w-100 h-100 d-flex flex-row justify-content-between align-items-center div-padding-checklist-footer- pt-2"
    >
      <CancelButton donorId={donorId} position={"literacy"} visitId={data && data.lastVisitId && data.lastVisitId } />
      <Space size={"middle"}>
        <button
          className="btn_next"
          style={{
            opacity: !(questions?.questions?.length > 0) ? 0.4 : isStart ? 1 : 0.4
          }}
          disabled={questions?.questions?.length > 0 ? isStart ? false : true : true}
          onClick={() => setCurrentProcess(1)}
        >
          Start
        </button>
      </Space>
    </div>
  );

  const previewFooter = questions && (
    <div
      className="w-100 h-100 d-flex flex-row justify-content-between align-items-center div-padding-checklist-footer- pt-2"
    >
      <CancelButton donorId={donorId} position={"literacy"} isChangeValue={isChangeValue} visitId={data && data.lastVisitId && data.lastVisitId } />
      <Space size={"middle"}>
        <button
          className="btn_white"
          onClick={() => {
            setCurrentProcess(1);
          }}
        >
          <Space size={"small"}>
            <Vector />
            <span>Previous</span>
          </Space>
        </button>
        <button className="btn_next" onClick={handleConfirm}>
          Submit
        </button>
      </Space>
    </div>
  );

  const [isPass, setisPass] = useState(true);
  const [pendshow, setpend] = useState(false);

  const reportFooter = questions && (
    <div
      className="w-100 h-100 d-flex flex-row justify-content-between align-items-center div-padding-checklist-footer- pt-2"
    >
      <CancelButton donorId={donorId} position={"literacy"} visitId={data && data.lastVisitId && data.lastVisitId }/>
      <Space size={"middle"}>
        <button
          className="btn_next ms-2"
          onClick={() => {
            if (isPass) {
              navigate(
                `/visitor/donor/create/video?id=${donorId}&app_unique_code=${appCode}`
              );
            } else {
              setpend(true);
            }
          }}
        >
          Next
        </button>
      </Space>
    </div>
  );

  return (
    <CDonorLayout
      donorId={donorId}
      current={0}
      data={data} setData={setData}
      noOverflowY={currentProcess === 2 ? true : false}
      footer={
        <>
          {currentProcess === 0 && instructionFooter}
          {currentProcess === 1 && questions && questionFooter}
          {currentProcess === 2 && previewFooter}
          {currentProcess === 3 && reportFooter}
        </>
      }
    >
      <Spin tip="Loading..." spinning={isloading}>
      <div className="h-100 w-100">
        <DonorVerify
          donorId={donorId}
          ref={verifyRef}
          onConfirm={handleConfirm}
        />
        {questions?.questions?.length > 0 ? (
          <div className="w-100 h-100">
            {currentProcess === 0 && (
              <div className="h-100 w-100">
                <SurveyInstruction
                  text={questions.instruction}
                  literacyData={literacyData}
                  isStart={isStart} setIsStart={setIsStart}
                  fetchLangVideo={fetchLangVideo}
                  contentLanguage={contentLanguage}
                  setContentLanguage={setContentLanguage}
                  handleChangeLanguage={handleChangeLanguage}
                />
              </div>
            )}
            {currentProcess === 1 && (
              <div className="h-100 w-100 div-padding-Literacy" 
              >
                {currentIndex === -1 ? (
                  <SurveyArticle
                    text={questions?.templates[templateIndex]?.article}
                  />
                ) : (
                  <QuestionBoxProgress
                    className="question-survey"
                    total={
                      questions?.templates[templateIndex]?.questions?.length
                    }
                    current={currentIndex + 1}
                    value={
                      questions?.templates[templateIndex]?.questions[
                      currentIndex
                      ]
                    }
                    onIsNext={(e) => {
                      setIsNext(e);
                    }}
                    onChange={(value) => {
                      isChangeValue && isChangeValue(true);
                      setQuestions((i) => {
                        i.templates[templateIndex].questions[currentIndex] =
                          value;
                        return i;
                      });
                    }}
                  />
                )}
              </div>
            )}
            {currentProcess === 2 && (
              <div className="w-100 h-100" style={{ padding: "2rem" }}>
                <div className="checkin_header">Preview </div>
                <div className="div-padding-check-Preview"
                  style={{
                    overflowY: "scroll",
                    height: "100%",
                  }}
                >
                  {questions.templates.map((item, index) => (
                    <>
                     <div
                        className="w-100 literacy_consent_header"
                        style={{ marginTop: "2rem" }}
                        dangerouslySetInnerHTML={{
                          __html: item.article,
                        }}
                      ></div> 
                      {item.questions.map((itemq, indexq) => (
                        <QuestionBox
                          noInfo={true}
                          className={
                            index === item.questions.length - 1
                              ? "question-preview no-border"
                              : "question-preview"
                          }
                          current={indexq + 1}
                          value={itemq}
                          onChange={(value) => {
                            isChangeValue && isChangeValue(true);
                            setQuestions((i) => {
                              i.templates[index].questions[indexq] = value;
                              return i;
                            });
                          }}
                        />
                      ))}
                    </>
                  ))}
                </div>
              </div>
            )}

            {currentProcess === 3 ? (
              <div className="h-100 w-100" style={{ padding: "2.5rem 2rem" }}>
                <ReportLiteracy
                  dataID={donorInfo}
                  appCode={appCode}
                  onRetake={handleRetake}
                  loading={loading}
                  setLoading={setLoading}
                  setisPass={setisPass}
                  pendshow={pendshow}
                  setpend={setpend}
                  questions={questions}
                />
              </div>
            ) : null}
          </div>
        ) : isloading ? (
          <>
          {/* <Spin
            size="large"
            className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
          ></Spin> */}
          </>
        ) : (
          <Empty
            imageStyle={{
              height: 60,
            }}
            description={<span>No Content</span>}
          ></Empty>
        )}
      </div>
      <MessageConfirmModal textCentered widthBtn={`auto`} noTitle={`No, Keep Stay`} yesTitle={`Yes, Leave without Saving`} ref={messageModalRef} />
      </Spin>
    </CDonorLayout>
  );
};

const SurveyInstruction = ({ text, literacyData, isStart, setIsStart, fetchLangVideo, contentLanguage, setContentLanguage, handleChangeLanguage }) => {
  const videoRef = React.useRef();

  useEffect(() => {
    if (literacyData && literacyData.instructionVideo) {
      videoRef.current.load()
    }
    if (literacyData && !literacyData.instructionVideo) {
      setIsStart(true);
    }
  }, [literacyData])

  useEffect(() => {
    const timer = setInterval(() => {
      if (videoRef.current && literacyData && literacyData.instructionVideo) {
        if (
          videoRef.current.video.props.player.currentTime >
          videoRef.current.video.props.player.duration - 1
        ) {
          setIsStart(true);
        }
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  });
  return (
    <div className="w-100 h-100 video_content">
      <div className="video_header">
        <div className="left_section">
          <p className="first_title">
            Literacy Check
          </p>
          <p className="second_title">Please watch the instruction to understand what you should do in Literacy Check</p>
        </div>
        <div className="right_section">
          <p className="select_title">
            Select the language
          </p>
          <select
            name="text"
            className="checkin_subheader select_lang"
            value={contentLanguage}
            onChange={handleChangeLanguage}
          >
            {
              fetchLangVideo?.length > 0 && fetchLangVideo.sort().map((item, index) => <option value={item} key={index}>{firstToUpper(item)}</option>)
            }
          </select>
        </div>
      </div>
      <div
        className="h-100 div-padding-Literacy"
      >
        <div
          className="literacy_content_header"
          // style={{ marginTop: "3.13rem" }}
        ></div>
        <div className="literacy_content" style={{ marginTop: "0.63rem" }}>
          <div
            className="literacy_consent_header"
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        </div>

        {literacyData && literacyData.instructionVideo ? <div className="w-100- h-100- dash_box">
          <Player playsInline={true} aspectRatio="auto" fluid={false} width="100%" height="100%" ref={videoRef}>
            <source src={literacyData.instructionVideo} type="video/mp4" />
            <BigPlayButton position={"center"} />
            <LoadingSpinner />
            <ControlBar autoHide={false} disableDefaultControls={true}>
              <PlayToggle />
              <CurrentTimeDisplay />
              <TimeDivider />
              <DurationDisplay />
            </ControlBar>
          </Player>
        </div> : ''}

      </div>
    </div>
  );
};

const SurveyArticle = ({ text }) => {
  return (
    <div className="w-100 h-100">
      <div className="checkin_header"></div>
      <div
        className="h-100 div-padding-Literacy"
      >
        <div
          className="literacy_content_header"
          style={{ marginTop: "3.13rem" }}
        ></div>
        <div className="literacy_content" style={{ marginTop: "0.63rem", opacity: 1 }}>
          <div
            className="literacy_consent_header"
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CheckPage;
