import * as React from 'react';

function Success(props) {
	return (
		<svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="37" cy="37" r="37" fill="#FFF1D5"/>
			<path d="M40.5 51.4824C40.5 53.4154 38.933 54.9824 37 54.9824C35.067 54.9824 33.5 53.4154 33.5 51.4824C33.5 49.5494 35.067 47.9824 37 47.9824C38.933 47.9824 40.5 49.5494 40.5 51.4824Z"
						fill="#FFAF37"/>
			<path d="M33.5 23.5C33.5 21.567 35.067 20 37 20C38.933 20 40.5 21.567 40.5 23.5V38.5C40.5 40.433 38.933 42 37 42C35.067 42 33.5 40.433 33.5 38.5V23.5Z"
						fill="#FFAF37"/>
		</svg>
	);
}

export default Success;
