import {useLocation, useNavigate} from "react-router-dom";

/**
 * 将url和参数拼接成完整地址
 * @param {string} url url地址
 * @param {Json} data json对象
 * @returns {string}
 */
const getUrl = (url, data) => {
    //看原始url地址中开头是否带?，然后拼接处理好的参数
    if (data) {
        url = url += (url.indexOf('?') < 0 ? '?' : '') + getParam(data)
    }
    return url;
}

/**
 * 传入对象返回url参数
 * @param {Object} data {a:1}
 * @returns {string}
 */
const getParam = (data) => {
    let url = '';
    for (var k in data) {
        let value = data[k];
        if (value != undefined && value != null) {
            // url += `&${k}=${encodeURIComponent(value)}`
            if (value instanceof Array) {
                for (let j in value) {
                    let val = value[j];
                    url += `&${k}=${encodeURIComponent(val)}`
                }
            } else {
                url += `&${k}=${encodeURIComponent(value)}`
            }
        }
    }
    return url ? url.substring(1) : ''
}

const url2query = (url) => {
    if (!url) {
        return {};
    }
    let urls = url.split('?');
    if (urls.length <= 1) {
        return {};
    }
    let param = urls[1];
    let params = param.split('&');
    let r = {};
    for (let key in params) {
        let p = params[key];
        if (p) {
            let ps = p.split('=');
            let k = ps[0];
            let v = ps.length > 1 ? ps[1] : '';
            v = decodeURIComponent(v);
            let oldVal = r[k];
            if (oldVal) {
                if (oldVal instanceof Array) {
                    oldVal.push(v);
                } else {
                    r[k] = [oldVal, v];
                }
            } else {
                r[k] = v;
            }
        }
    }
    return r;
}

let getLocationUrl = (pathname) => {
    let _url = window.location.href.split('?')[0];
    if (!pathname) {
        return _url;
    }
    return _url.substring(0, _url.length - pathname.length);
}

let getAddUrlLocation = (targetUrl, location) => {
    if (!location) {
        throw new Error("location参数不能为空")
    }
    let _url = "_url=" + encodeURIComponent(location.pathname + location.search)
    if (targetUrl) {
        if (targetUrl.indexOf('?') < 0) {
            return targetUrl + "?" + _url;
        } else {
            return targetUrl + "&" + _url;
        }
    } else {
        return _url;
    }

}

let useAddUrlLocation = (defaultUrl, location) => {
    if (!location) {
        throw new Error("location参数不能为空")
    }
    let query = url2query(location.search);
    let _url = query._url;
    decodeURIComponent(_url)
    if (_url && _url !== "undefined") {
        return _url;
    } else {
        return defaultUrl;
    }

}

export default {getUrl, getParam, url2query, getLocationUrl, getAddUrlLocation, useAddUrlLocation}
