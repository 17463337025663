import React from "react";
const SuccessSVG = () => {
  return (
    <svg
      width="1.6rem"
      height="1.6rem"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        stroke="#21C273"
        strokeWidth="2"
      />
      <path
        d="M7 11.2727L9.82353 14L15 9"
        stroke="#21C273"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default SuccessSVG;
