import axios, { AxiosError } from "axios";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
// import { connect } from 'react-redux';
// import { store } from '../employee/components/state/store';
import urlUtils from "./urlUtils";
import { store } from "@/api/employee/components/state/store";

//基地址
// const BaseUrl = "http://pacsystem-env-1.us-east-1.elasticbeanstalk.com";
// const BaseUrl = 'http://localhost:8080'
const BaseUrl = process.env.REACT_APP_BASE_URL;


// interface NetRequestParam<T = any, D = any>  {
  // url: string;
  // method: string;
  //
//   data: T;
//   params: {key:value};
//   headers: {key:value};
// }

export class NetworkException extends Error {
  errorData = {};
  constructor(res) {
    super('Server field.');
    this.errorData = res;
  }
}

export class BadReqeustException extends Error {
  errorData = {};
  constructor(res) {
    super(res.message ? res.message : 'Param error.');
    this.errorData = res;
  }
}

//请求类
class ApiService {
  constructor() {
  }

  request(cf) {
    let rp = {};
    rp.url = cf.url;
    rp.method = cf.method ? cf.method : 'GET';
    rp.headers = cf.headers ? cf.headers : {};
    rp.data = cf.data ? cf.data : {};
    rp.params = cf.params ? cf.params : {};
    rp.token = (cf.token != null && cf.token != undefined) ? cf.token : true;
    rp.file = (cf.file != null && cf.file != undefined) ? cf.file : false;
    rp.responseAll = (cf.responseAll != null && cf.responseAll != undefined) ? cf.responseAll : false;

    rp.returnAll = (cf.returnAll != null && cf.returnAll != undefined) ? cf.returnAll : false;

    rp.url = urlUtils.getUrl(rp.url, rp.params);
    rp.params = {};

    // 请求对象
    if (store.getState().loginStateReducer.isLogin) {
      let token = store.getState().loginStateReducer.isLogin;
      let start = token.indexOf(".");
      let end = token.indexOf(".", start + 1);
      let employeeID = token.substring(start + 1, end);
      let employeeIDGet = JSON.parse(atob(employeeID));
      rp.data.employeeId = employeeIDGet.id;
    }

    if (rp.file) {
        let data = rp.data;
        let fd = new FormData();
        rp.data = fd;
        if (data) {
            for (let key in data) {
                fd.append(key, data[key]);
            }
        }
        // rp.headers['Content-Type'] = 'multipart/form-data;charset=utf-8';
    }

    if (rp.url) {
      if (!rp.url.startsWith('http://') && !rp.url.startsWith('https://')) {
        rp.url = BaseUrl + rp.url;
      }
    }
    return new Promise((r, j)=>{
      axios.request(rp).then((res)=>{
        if (res.status < 400) {
          let data = res.data;
          if (rp.responseAll){
            r(data);
          }else {
            if (data.status < 400) {
              if (rp.returnAll) {
                r(data);
              } else {
                r(data.data);
              }
            } else {
              j(new BadReqeustException(data));
            }
          }
        } else {
          j(new NetworkException(res));
        }

      }).catch((err)=>{
        j(err);
      });
    });
  }

  get(url, cf) {
    let pa = cf ? cf : {};
    pa.url = url;
    pa.method = 'GET';
    return this.request(pa);
  }

  post(url, data, cf) {
    let pa = cf ? cf : {};
    pa.url = url;
    pa.method = 'POST';
    pa.data = data;
    return this.request(pa);
  }
  put(url, data, cf) {
    let pa = cf ? cf : {};
    pa.url = url;
    pa.method = 'PUT';
    pa.data = data;
    return this.request(pa);
  }
  delete(url,data, cf) {
    let pa = cf ? cf : {};
    pa.url = url;
    pa.data = data;
    pa.method = 'DELETE';
    return this.request(pa);
  }

}


let apiService = new ApiService();

export default apiService;


