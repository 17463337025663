import ApiService from "../../request/request";

export const getData = async (url, param) => {
  const result = await ApiService.get(url, param);
  return result.data;
};

export const createData = async (url, param) => {
  const result = await ApiService.post(url, param);
  return result.data;
};

export const queueData = async (url, param) => {
  const result = await ApiService.get(url, param);
  return result.data;
};

export const upDataNew = async (url, param, data) => {
  const result = await ApiService.put(url, param, data);
  return result;
};
export const upData = async (url, param, data) => {
  const result = await ApiService.put(url, param, data);
  return result.data;
};
export const getCollectionList = async (url, param) => {
  const result = await ApiService.get(url, param);
  return result.data;
};
export const getCollectionListAll = async (url, param) => {
  const result = await ApiService.get(url, param);
  return result;
};
