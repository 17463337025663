// VISITOR
export const VISITOR_LOGIN_URL = '/visitor/login';
export const VISITOR_DONOR_COMPLETE_URL = '/visitor/donors/completed';
export const VISITOR_DONOR_AUTO_CHECKIN_URL = '/visitor/donors/auto-checkin';
export const VISITOR_DONOR_DETAIL_URL = '/visitor/donors/detail';
export const VISITOR_DONOR_PROCESS_URL = '/visitor/donors/process';
export const VISITOR_QUESTIONAIRE_URL = '/visitor/questionnaires/search';
export const VISITOR_CHECK_DONOR_EXPIRE_URL = '/visitor/donors/checkExpired'
export const VISITOR_DONOR_URL = '/visitor/donors'

