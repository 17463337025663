import { data } from "browserslist";
import ApiService from "../../request/request";

export const donorAPI = async (url, param) => {
  const result = await ApiService.post(url, param);
  return result.data;
};
export const getDonor = async (url, param) => {
  const result = await ApiService.get(url, param);
  return result.data;
};

export const getDonorList = async (url, param) => {
  const result = await ApiService.get(url, param);
  return result;
};

export const getQuestions = async (url, param) => {
  const result = await ApiService.get(url, param);
  return result;
};

export const setQuestions = async (url, param) => {
  const result = await ApiService.post(url, param);
  return result;
};

export const getDonorPhoto = async (url, param) => {
  const result = await ApiService.get(url, param);
  return result.data;
};
export const createDonor = async (url, param) => {
  const result = await ApiService.post(url, param);
  return result;
};
export const putProcess = async (url, param, data) => {
  const result = await ApiService.put(url, param, data);
  return result;
};

export const postProcess = async (url, param) => {
  const result = await ApiService.post(url, param);
  return result.data;
};
