import React, { useEffect, useState } from "react";
import { Form, Toast, ToastContainer } from "react-bootstrap";
import SuccessSVG from "./SuccessSVG";
import Error from "./Erroer";

//Next扫描完毕或回车后要执行的方法
//style为行内样式
const DEFAULT_POSITION = "top-center";
const DEFAULT_TIME = 2000;
const DEFAULT_VALUE = "Data not found!";
const DEFAULT_COLOR = "rgba(21,44,91,0.8)";

const Tipbox = ({ show, setShow, value, state, position, time }) => {
  // console.log(show, value, state);
  //show:控制是否显示：true/false
  //value:显示内容：String
  //state:提示框类型："success"/"failed"
  //position:提示框出现位置：
  //          'top-start',
  //          'top-center',
  //          'top-end',
  //          'middle-start',
  //          'middle-center',
  //          'middle-end',
  //          'bottom-start',
  //          'bottom-center',
  //          'bottom-end'
  //time: 提示框持续的时间：毫秒
  let color =
    state && state === "failed"
      ? "rgba(228,78,81,1)"
      : state === "success"
      ? "rgba(33,194,115,0.8)"
      : DEFAULT_COLOR;
  //const [showBox, setShowBox] = useState(show ? show : false);
  //useEffect(() => {

  //},[])
  return (
    <ToastContainer
      position={position ? position : DEFAULT_POSITION}
      style={{
        marginTop: position ? "" : "10rem",
        zIndex: 9999,
        backgroundColor: "rgba(255,255,255,1)",
      }}
    >
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={time ? time : DEFAULT_TIME}
        autohide
        style={{ borderColor: "rgba(255,255,255,1)" }}
      >
        <Toast.Body>
          <div
            className="w-100 "
            style={{
              width: "100%",
              textAlign: "center",
              padding: "1rem",
              fontFamily: "Montserrat",
              fontWeight: "600",
              color: DEFAULT_COLOR,
              fontSize: "1.3rem",
            }}
          >
            <div style={{ minWidth: "2rem", minHeight: "2rem" }}>
              {state === "failed" ? (
                <Error />
              ) : state === "success" ? (
                <SuccessSVG />
              ) : null}
            </div>

            {value ? value : DEFAULT_VALUE}
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Tipbox;
