import { store } from "@/api/employee/components/state/store";
import Axios, { AxiosError } from "axios";
Axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

// import { store } from "../../../employee/components/state/store";
//基地址
// const BaseUrl = "http://pacsystem-env-1.us-east-1.elasticbeanstalk.com/app";
// const BaseUrl = 'http://localhost:8080/app'
const BaseUrl = process.env.REACT_APP_BASE_URL + "/visitor";

const encodeParams = (params) => {
  const r = "?";
  const p = [];
  for (const key in params) {
    p.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
  }
  return r + p.join("&");
};

//请求类
class ApiService {
  constructor() {
    this.interceptorsOfReq();
    this.interceptorsOfRes();
  }

  get(url, params) {
    if (params) {
      url += encodeParams(params);
    }
    return Axios.get(BaseUrl + url).then((res) => res.data);
  }

  post(url, params) {
    if (!params) {
      params = {};
    }
    if (store.getState().loginStateReducer.isLogin) {
      let token = store.getState().loginStateReducer.isLogin;
      let start = token.indexOf(".");
      let end = token.indexOf(".", start + 1);
      let employeeID = token.substring(start + 1, end);
      let employeeIDGet = JSON.parse(atob(employeeID));
      params.employeeId = employeeIDGet.id;
    }

    return Axios.post(BaseUrl + url, params).then((res) => res.data);
  }

  delete(url, params) {
    if (!params) {
      params = {};
    }

    return Axios.delete(BaseUrl + url, params).then((res) => res.data);
  }

  put(url, params, data) {
    if (params) {
      url += encodeParams(params);
      //url=url+'/'+params
    }
    if (!data) {
      data = {};
    }
    return Axios.put(BaseUrl + url, data).then((res) => res.data);
  }

  interceptorsOfReq() {
    Axios.interceptors.request.use(
      function (config) {
        if (!config.url.includes("localhost:9001")) {
          if (store.getState().loginStateReducer.isLogin) {
            config.headers.Authorization =
              store.getState().loginStateReducer.isLogin;
          }

          config.headers.timeZone = -new Date().getTimezoneOffset() / 60;
        }
        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  //响应拦截器
  interceptorsOfRes() {
    Axios.interceptors.response.use(
      (response) => {
        //if (response.data.status === 200) {
        //  // console.log(response.data.message);
        //}
        // console.log(response.data.message);
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}

export default new ApiService();
