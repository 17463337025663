import ApiService from "../../request/requestPrint";

export const getFingerprint = async (url) => {
  const result = await ApiService.get(url);
  return result;
};

export const postFingerprint = async (url, parmas) => {
  const result = await ApiService.post(url, parmas);
  return result;
};
