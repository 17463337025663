import React, { forwardRef, useImperativeHandle, useState } from "react";
import ContentAbort from "@/pad/assets/svgicons/checkin/ContentAbort";
import CancelModal from "./cancelModal";
const CancelButton = forwardRef(({ position, donorId, isChangeValue, visitId }, ref) => {
  const [abortshow, setabort] = useState(false);

  const open = () => {
    setabort(true)
  }
  
  useImperativeHandle(ref, () => ({
    open
  }));

  return (
    <div>
      <button className="btn_red" onClick={() => setabort(true)}>
        {" "}
        <ContentAbort /> Visit Cancel
      </button>
      <CancelModal abortshow={abortshow} setabort={setabort} position={position} donorId={donorId} isChangeValue={isChangeValue} visitId={visitId}/>
    </div>
  );
});
export default CancelButton;
