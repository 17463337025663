import * as React from 'react';

function PassSVG(props) {
	const { width, height } = props
	return (
		<svg width={`${width ? width : '74'}`} height={`${height ? height : '74'}`} viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="37" cy="37" r="37" fill="#21C273" />
			<path d="M19.6031 30.677C18.2641 29.338 16.0933 29.338 14.7543 30.677C13.4154 32.0159 13.4154 34.1867 14.7543 35.5257L19.6031 30.677ZM30.5462 51.3175C31.8851 52.6564 34.0559 52.6564 35.3949 51.3175C36.7338 49.9786 36.7338 47.8077 35.3949 46.4688L30.5462 51.3175ZM60.07 27.5318C61.409 26.1928 61.409 24.022 60.07 22.6831C58.7311 21.3441 56.5603 21.3441 55.2213 22.6831L60.07 27.5318ZM31.4356 46.4688C30.0967 47.8077 30.0967 49.9786 31.4356 51.3175C32.7745 52.6564 34.9454 52.6564 36.2843 51.3175L31.4356 46.4688ZM14.7543 35.5257L30.5462 51.3175L35.3949 46.4688L19.6031 30.677L14.7543 35.5257ZM55.2213 22.6831L31.4356 46.4688L36.2843 51.3175L60.07 27.5318L55.2213 22.6831Z" fill="white" />
		</svg>

	);
}

export default PassSVG;
