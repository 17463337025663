import { LOGOUT } from "@/components/state/auth/constants";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsLogin,SetUserInfo } from "@/pad/components/state/actions/login";


const DownTimeMillisecond = ({timeTotal, handlerOnClickNexts}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const handlerOnClickNext = () => {
        dispatch(setIsLogin(null));
        dispatch(SetUserInfo({}));

        let dataNew = {
            visitor_user: null,
            visitor_token: null,
        }

        dispatch({
            type: LOGOUT,
            payload: dataNew
        })

        navigate(`/`);
    };

    const [countDown, setCountDown ] = useState(timeTotal ? timeTotal : 0)
    useEffect(() => {
      let timer = setInterval(() => {
        if (countDown - 1 >= 0) {
          let time = countDown
          time = time - 1
          setCountDown(time)
        } else {
          handleFinish();
          clearInterval(timer);
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }, [countDown]);
  
    const handleFinish = () => {
      handlerOnClickNext && handlerOnClickNext()
    };
  
    return countDown
  };
  
  export default DownTimeMillisecond;