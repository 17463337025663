import Axios, { AxiosError } from "axios";
Axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

//基地址
const BaseUrl = "";
// const BaseUrl = 'http://localhost:8080'

const encodeParams = (params) => {
  const r = "?";
  const p = [];
  for (const key in params) {
    p.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
  }
  return r + p.join("&");
};

//请求类
class ApiService {
  constructor() {
    this.interceptorsOfReq();
    this.interceptorsOfRes();
  }

  get(url, params) {
    if (params) {
      url += encodeParams(params);
    }
    return Axios.get(url).then((res) => res);
  }

  post(url, params) {
    if (!params) {
      params = {};
    }
    return Axios.post(url, params).then((res) => res);
  }

  delete(url, params) {
    if (!params) {
      params = {};
    }
    return Axios.delete(url, params).then((res) => res);
  }

  interceptorsOfReq() {
    Axios.interceptors.request.use(
      function (config) {
        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  //响应拦截器
  interceptorsOfRes() {
    Axios.interceptors.response.use(
      (response) => {
        // console.log("执行顺序get", response);
        if (response.code === 200) {
          // console.log(response.data);
        }
        // console.log(response.data.message);
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}

export default new ApiService();
