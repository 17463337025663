import ApiService from '../../request/request'

export const donorAPI = async (url, param) => {
    const result = await ApiService.post(url, param);
    return result.data
}
export const donorAPIPut = async (url, param) => {
    const result = await ApiService.put(url, '', param);
    return result
}
export const donorAPIGet = async (url, param) => {
    const result = await ApiService.get(url, param);
    return result.data
}

export const getQuestions = async (url) => {
    const result = await ApiService.post(url, '');
    return result
}
