export const checkDeferral = (data) => {
    let WquestionsCopy = [];
    data && data.record.map((item) => {
        (item.questionType.toLowerCase() === "multi-choice" ||
            item.questionType.toLowerCase() === "single-choice") &&
            item.answers.map((answer) => {
                if (
                    answer.isanswer &&
                    answer.result.toLowerCase() === "fail" &&
                    answer.actions &&
                    answer.actions[0].deferralId
                ) {
                    WquestionsCopy.push(answer);
                }
            });
    });
    return WquestionsCopy
}

//排序
export const compare = (v1, v2) => {
    if (v1.cycle === -1) {
        return -1;
    } else if (v2.cycle === -1) {
        return 1;
    }

    if (v1.cycle < v2.cycle) {
        return 1;
    } else if (v1.cycle > v2.cycle) {
        return -1;
    } else {
        return 0;
    }
};

export const getDate = (date) => {
    if (!date) {
        return "";
    }
    let newDate = date ? new Date(date) : new Date();
    return `${newDate.getMonth() + 1 < 10
        ? "0" + (newDate.getMonth() + 1)
        : newDate.getMonth() + 1
        } / ${newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate()
        } / ${newDate.getFullYear()} ${newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
        } : ${newDate.getMinutes() < 10
            ? "0" + newDate.getMinutes()
            : newDate.getMinutes()
        } `;
};

export const getOneProgramHistory = (data) => {
    if (data && data.length > 0) {
        let programId = data.map((item) => item._id)
        return programId[0]
    }
}

export const filterDuplicateDeferralType = (data, action) => {
    const getUniqueListBy = (arr, key) => {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    let filterData = getUniqueListBy(data.flat(), action)
    return filterData
}