import axios from "axios";
import uploadFileApi from "@/api/app/upload/uploadFileRecords";

const uploadBase64 = async (dataurl) => {
  try {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      uarr = new Uint8Array(n);
    while (n--) {
      uarr[n] = bstr.charCodeAt(n);
    }
    let theBlob = new Blob([uarr], { type: mime });
    let fileName = `$`;
    theBlob.lastModifiedDate = new Date();
    theBlob.name = `${new Date().getTime()}_.` + mime.split("/")[1];
    return await uploadFile(theBlob);
  } catch (err) {
    return "";
  }
};

const uploadFile = async (binary) => {
  let signedUrl = "";
  let signedData = {};
  let imgUrl = "";

  try {
    let name = binary.name;
    var ext =  name.split('.').pop();
    name = ext === 'MP4' ? binary.name.replace(".MP4", '.mp4') : binary.name
    signedData = await uploadFileApi.get({
      fileName: `${new Date().getTime()}_` + name,
    });

    if (signedData.status != 200) {
      return "";
    }

    signedUrl = signedData.data.signedUrl;
    imgUrl = signedUrl.split("?")[0];

    await axios.put(
      signedUrl,
      binary,

      {
        headers: {
          "Content-Type": binary.type,
        },
        transformRequest: [
          (data, headers) => {
            delete headers.Authorization;
            return data;
          },
        ],
      }
    );

    return imgUrl;
  } catch (err) {
    return "";
  }
};

export default { uploadFile, uploadBase64 };
