import React from 'react';

const LogoP = ({ width, height }) => {
	return (
		<svg width={width ? width : "6.125rem"} height={height ? height : "6.125rem"} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_d_12_3826)">
				<circle cx="40" cy="36" r="33" fill="#0075FF" />
			</g>
			<path d="M42.0601 21.2061H29.7598V50.7923H36.3034V42.3391H42.0601C49.432 42.3391 53.6563 38.3661 53.6563 31.4767C53.6563 24.9255 49.432 21.2061 42.0601 21.2061ZM41.7288 36.7599H36.3034V26.7429H41.7288C45.3733 26.7429 47.444 28.3913 47.444 31.688C47.444 35.0693 45.3733 36.7599 41.7288 36.7599Z" fill="url(#paint0_linear_12_3826)" />
			<defs>
				<filter id="filter0_d_12_3826" x="0" y="0" width="80" height="80" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="3.5" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.458824 0 0 0 0 1 0 0 0 0.3 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12_3826" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12_3826" result="shape" />
				</filter>
				<linearGradient id="paint0_linear_12_3826" x1="41.3619" y1="21.2791" x2="41.3619" y2="53.1347" gradientUnits="userSpaceOnUse">
					<stop stop-color="#EAF3FF" />
					<stop offset="1" stop-color="#C3DFFF" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default LogoP;
