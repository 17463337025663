import React from "react";
import { Modal, message, Image, Progress, Space } from "antd";
import QuestionBox from "@/components/questionBox/QuestionBox";
import Countdown from "./Countdown";
import "./progress.css";

const QuestionBoxProgress = (props) => {
  const [time, setTime] = React.useState(0);
  const [isFinish, setIsFinish] = React.useState(false);
  const onNext = () => {
    setIsFinish(true);
  };
  const current = props.current && props.current >= 0 ? props.current : 0;
  const total = props.total && props.total >= 1 ? props.total : 0;
  React.useEffect(() => {
    if (props.value) {
      if (
        props.value.answers?.findIndex((i) => i.isanswer) > -1 ||
        (props.value?.answers?.length > 0 &&
          typeof props.value?.answers[0] === "string")
      ) {
        props.onIsNext && props.onIsNext(true);
        setIsFinish(true);
        setTime(0);
      } else {
        props.onIsNext && props.onIsNext(false);
        setTime(props.value?.readTime ? props.value.readTime : 0);
        setIsFinish(props.value?.readTime ? false : true);
      }
    } else {
      props.onIsNext && props.onIsNext(false);
    }
  }, [props.current]);
  React.useEffect(() => {
    if (props.value) {
      if (
        isFinish &&
        (props.value.answers?.findIndex((i) => i.isanswer) > -1 ||
          (props.value?.answers?.length > 0 &&
            typeof props.value?.answers[0] === "string"))
      ) {
        props.onIsNext && props.onIsNext(true);
      } else if (isFinish && props.value.isRequired != "1") {
        props.onIsNext && props.onIsNext(true);
      } else {
        props.onIsNext && props.onIsNext(false);
      }
    }
  }, [isFinish, props.current]);
  const className = props.className
    ? "question-progress " + props.className
    : "question-progress";
  let propsCopy = { ...props };
  propsCopy.className = className;
  propsCopy.onChange = (value) => {
    if (
      isFinish &&
      (value.answers?.findIndex((i) => i.isanswer) > -1 ||
        (value?.answers?.length > 0 &&
          typeof value?.answers[0] === "string" &&
          value?.answers[0]?.length > 0))
    ) {
      props.onIsNext && props.onIsNext(true);
    } else if (isFinish && value.isRequired != "1") {
      props.onIsNext && props.onIsNext(true);
    } else {
      props.onIsNext && props.onIsNext(false);
    }
    props.onChange(value);
  };
  return (
    <div className="w-100 h-100">
      <div
        className="w-100 d-flex flex-row align-items-center justify-content-between"
        style={{ paddingBottom: "1rem" }}
      >
        <Space size="large">
          <div className="checkin_header">Question Answering</div>
          <div
            className="checkin_header"
            style={{ fontSize: "1.13rem", opacity: 0.4 }}
          >{`(${current}/${total})`}</div>
        </Space>
        <div>
          {props.value?.readTime ? (
            <Countdown current={current} time={time} onCount={onNext} />
          ) : null}
        </div>
      </div>
      <div className="w-100" style={{ paddingBottom: "2rem" }}>
        <Progress
          percent={total > 0 && current > 0 ? (current / total) * 100 : 0}
          strokeColor={{
            "0%": "rgba(95,148,254,1)",
            "100%": "rgba(95,148,254,0.75)",
          }}
          showInfo={false}
        />
      </div>
      <QuestionBox {...propsCopy} />
    </div>
  );
};

export default QuestionBoxProgress;
