import { storageKeys } from "../../constants/storage"

export const setString = (key, value) => {
  localStorage.setItem(key, btoa(value))
}

export const getString = (key) => {
  return atob(localStorage.getItem(key))
}

export const setJson = (key, value) => {
  localStorage.setItem(key, btoa(JSON.stringify(value)))
}

export const getJson = (key) => {
  return atob(JSON.parse(localStorage.getItem(key)))
}

export const clearLocaleStorage = (key) => {
  localStorage.removeItem(storageKeys.TOKEN)
  localStorage.removeItem(storageKeys.USER)
}

export const sessionSetString = (key, value) => {
  sessionStorage.setItem(key, btoa(value))
}

export const sessionGetString = (key) => {
  return atob(sessionStorage.getItem(key))
}

export const currentToken = (key, value) => {
  localStorage.getItem(storageKeys.USER)
}