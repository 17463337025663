/**
 * 字符串首字母大写转换
 * @param {string} data 需要转换的字符串
 * @returns {string}
 */
const stringCapitalize = (data) => {
  //看原始url地址中开头是否带?，然后拼接处理好的参数
  return data.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());

}


export default {stringCapitalize}
