import { VISITOR_CHECK_DONOR_EXPIRE_URL, VISITOR_DONOR_DETAIL_URL, VISITOR_DONOR_PROCESS_URL, VISITOR_LOGIN_URL, VISITOR_QUESTIONAIRE_URL } from "../../../api/visitor/URLs";
import serviceAPI from "../../request/requestAPI";

export const visitorLogin = async (param) => {
  const result = await serviceAPI.post(VISITOR_LOGIN_URL, param);
  return result;
};

export const checkDonorExpired = async (data) => {
  const result = await serviceAPI.get(VISITOR_CHECK_DONOR_EXPIRE_URL, data);
  return result;
};

export const getDonorDetail = async (data) => {
  const result = await serviceAPI.get(VISITOR_DONOR_DETAIL_URL, data);
  return result;
};

export const visitorProcess = async (data) => {
  const result = await serviceAPI.get(VISITOR_DONOR_PROCESS_URL, data);
  return result;
};

export const visitorquestionaire = async (data) => {
  const result = await serviceAPI.get(VISITOR_QUESTIONAIRE_URL, data);
  return result;
};
export const getEducationApp = async (params) => await serviceAPI.get("/visitor/questionnaires/education", params)

export const getWatchVideoApp = async (params) => await serviceAPI.get("/visitor/questionnaires/consent", params)