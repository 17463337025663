import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// import ProcessPage from "./pages/process/ProcessPage";
import Visitor from "./pages/visitor/visitor";
import DefaultPage from "./pages/default/DefaultPage";
import CheckPage from "@/pad/pages/donor/create/CheckPage";
import VideoPage from "@/pad/pages/donor/create/VideoPage";
import ChecklistPage from "./pad/pages/donor/create/ChecklistPage";

function App() {
  // const location = useLocation();
  // const navigate = useNavigate();
  // React.useEffect(() => {
  //   console.log(isLogin);
  //   if(!isLogin){
  //     if(location.pathname != "/"){
  //       navigate("/");
  //     }
  //   }
  // }, [locaticlearon,isLogin]);
  return (
    <Routes>
      <Route path="/" element={<Visitor />} />
      {/* <Route path="/process" element={<ProcessPage />} /> */}
      <Route path="/visitor/donor/create/check" element={<CheckPage />} />
      <Route path="/visitor/donor/create/video" element={<VideoPage />} />
      <Route path="/visitor/donor/create/checklist" element={<ChecklistPage />} />
      <Route path="*" element={<DefaultPage />} />
    </Routes>
  );
}

export default App;
