//只处理单选 && 多选
const autoFillFunc = function (questions) {
  console.log(questions);
  let questionCopy = questions;
  let answerIndex = -1;
  if (questionCopy.length > 0) {
    questionCopy.forEach((item, index) => {
      answerIndex =
        questionCopy[index].answers?.findIndex(
          (i) =>
            i.result?.toLowerCase() === "pass" ||
            i.result?.toLowerCase() === "n/a"
        ) >= 0
          ? questionCopy[index].answers.findIndex(
              (i) =>
                i.result?.toLowerCase() === "pass" ||
                i.result?.toLowerCase() === "n/a"
            )
          : -1;
      if (answerIndex != -1) {
        questionCopy[index].answers[answerIndex].isanswer = true;
      }
    });
  }

  return questionCopy;
};

export default {
  autoFillFunc,
};
