import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import CloseBtn from "../verification/svgicons/CloseBtn";
import donorAPI from "@/api/app/donor/donor";
import errorDeal from "@/utils/errorDeal";
import DonorVerifyFace from "@/components/auth/donorverify/DonorVerifyFace";
import DonorVerifySignature from "@/components/auth/donorverify/DonorVerifySignature";
import FaceUserSVG from "@/components/auth/svgicons/faceUserSVG";
import FaceUserNoSVG from "@/components/auth/svgicons/FaceUserNoSVG";
import FingerPrint from "@/components/auth/svgicons/Fingerprints";
import FingerPrintNo from "@/components/auth/svgicons/FingerPrintNo";
import Signature from "@/components/auth/svgicons/signature";
import DonorVerifyFinger from "./donorverify/DonorVerifyFinger";
import FingerRefComponent from "../fingerprint/VerifyDonor";
import { message } from "antd";
import { getRequest } from "../service/donor/donor";
import DonorVerifyFailed from "./donorverify/DonorVerifyFailed";
/**
 * 认证信息
 * prop
 * donorId：用户id
 * onConfirm: ()=>void，通过后回调
 * onCancel: ()=>void, 认证不通过回调
 *
 * ref.open: ()=>void, 打开对话框方法调用
 */
const DonorVerify = React.forwardRef(
  ({ donorId, onConfirm, onCancel }, ref) => {
    React.useImperativeHandle(ref, () => {
      return { open: open, signatureImg: signatureImg, verifyType: verifyType, fingerScan: fingerScan };
    });
    const [show, setShow] = useState(false);
    const [donorData, setDonorData] = useState({});
    const [verifyType, setVerifyType] = useState(0);
    const [verifyShow, setVerifyShow] = useState(true);
    const [signatureImg, setSignatureImg] = useState("");
    const [fingerScan, setFingerScan] = useState([])
    const [noFailed, setNoFailed] = useState(true)

    let faceRef = useRef();
    let fingerRef = useRef();
    let signatureRef = useRef();
    let fingerPrintRef = useRef();

    useEffect(
      errorDeal(async () => {
        if (donorId) {
          let res = await donorAPI.getList({ donorId: donorId });
          if (res.data?.length > 0) {
            setDonorData(res.data[0]);
          }
        }
      }),
      []
    );

    const open = () => {
      // 拉起认证窗口
      setShow(true);
    };
    const closeClick = () => {
      // 关闭认证窗口
      setShow(false);
      signatureRef.current.close();
      fingerRef.current.close();
      faceRef.current.close();
      setNoFailed(true)
      if (verifyType == 2) {
        faceRef.current.closeWebcam();
      }
      setVerifyShow(true);
    };

    const verifyConfirmClick = () => {
      // 拉起选择认证方式的窗口
      setVerifyShow(false);
      if (verifyType == 0) {
        signatureRef.current.open();
      } else if (verifyType == 1) {
        fingerRef.current.open();
        nextFun()
      } else if (verifyType == 2) {
        faceRef.current.open();
        faceRef.current.start();
      }
    };

    const handleVerifyConfirm = () => {
      //验证成功回调
      if (verifyType == 0) {
        setSignatureImg(signatureRef.current.signatureImg);
      }
      setShow(false);
      setVerifyShow(true);
      onConfirm();
    };

    const handleVerifyCancel = () => {
      //验证返回回调
      setNoFailed(true);
      setShow(true);
      setVerifyShow(true);
    };

    const verifyFailedClose = () => {
      setNoFailed(true)
      setVerifyShow(true)
      setShow(false);
    }

    const nextFun = async () => {
      let obj = { running: false };
      fingermark(obj);
    };

    const fingermark = async (obj) => {

      let fingerData = []

      if(donorData && donorData.fingerprint.length > 0) {
        fingerData.push(donorData.fingerprint)
      }

      if (fingerPrintRef.current && fingerData.length > 0) {
        fingerPrintRef.current.verifyFunction(fingerData, obj).then((res) => {
          if (res && res.data.code == 200) {
            if (res.data.verify && res.data.gather) {
              message.success("Identification through!");
              setShow(false);
              handleVerifyConfirm()
            } else {
              fingerRef.current.close();
              setNoFailed(false)
            }
          } else {
            nextFun();
          }
        });
      }
    };

    return (
      <div>
        <FingerRefComponent ref={fingerPrintRef} />
        <Modal
          show={show}
          onHide={() => setShow(false)}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ borderRadius: "0.625rem", width: "100%" }}
        >
          <Modal.Body>
            {
              noFailed ? (
            <>
            <div onClick={() => closeClick()} style={{ textAlign: "right" }}>
              <CloseBtn />
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center" style={{width:'100%'}}>
              <div
                style={{
                  opacity: "0.800000011920929",
                  color: "#152C5B",
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  fontSize: "19px",
                  lineHeight: "normal",
                  letterSpacing: "0px",
                  textAlign: "left",
                }}
              >
                Donor Confirmation
              </div>

              {verifyShow ? (
                <div style={{width: '100%'}}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "1rem",
                      marginBottom: "3rem",
                      width:'100%'
                    }}
                  >
                    <div
                      onClick={() => setVerifyType(0)}
                      style={
                        verifyType == 0
                          ? {
                              paddingTop: "6rem",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "1rem",
                              marginRight: "1rem",
                              borderRadius: "10px",
                              background: "#FCFCFD",
                              border: "3px solid #0075FF",
                              width: "30%",
                              height: "260px",
                            }
                          : {
                              paddingTop: "6rem",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "1rem",
                              marginRight: "1rem",
                              borderRadius: "10px",
                              background: "#FCFCFD",
                              border: "1px dashed #E1E4EA",
                              width: "30%",
                              height: "260px",
                            }
                      }
                    >
                      <div>
                        <div style={{ marginLeft: "0.5rem" }}>
                          <Signature />
                        </div>
                        <div
                          style={{
                            marginTop: "0.5rem",
                            // opacity: " 0.2500000059604645",
                            color: "#152C5B",
                            fontFamily: "Montserrat",
                            fontWeight: "SemiBold",
                            fontSize: "15px",
                            lineHeight: "normal",
                            letterSpacing: "0px",
                            textAlign: "left",
                          }}
                        >
                          <text style={{ color: "#152C5B", fontWeight: "bold" }}>
                            Signature
                          </text>
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={() =>
                        donorData.fingerprint &&
                        donorData.fingerprint.length > 0
                          ? setVerifyType(1)
                          : ""
                      }
                      style={
                        verifyType == 1
                          ? {
                              paddingTop: "6rem",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "1rem",
                              marginRight: "1rem",
                              borderRadius: "10px",
                              background: "#FCFCFD",
                              border: "3px solid #0075FF",
                              width: "30%",
                              height: "260px",
                            }
                          : {
                              paddingTop: "6rem",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "1rem",
                              marginRight: "1rem",
                              borderRadius: "10px",
                              background: "#FCFCFD",
                              border: "1px dashed #E1E4EA",
                              width: "30%",
                              height: "260px",
                            }
                      }
                    >
                      <div>
                        <div style={{ marginLeft: "1rem" }}>
                          {donorData.fingerprint &&
                          donorData.fingerprint.length > 0 ? (
                            <FingerPrint />
                          ) : (
                            <FingerPrintNo />
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: "0.5rem",
                            // opacity: " 0.2500000059604645",
                            fontFamily: "Montserrat",
                            fontWeight: "SemiBold",
                            fontSize: "15px",
                            lineHeight: "normal",
                            letterSpacing: "0px",
                            textAlign: "left",
                          }}
                        >
                          {donorData.fingerprint &&
                          donorData.fingerprint.length > 0 ? (
                            <text style={{ color: "#152C5B", fontWeight: "bold" }}>
                              Fingerprint
                            </text>
                          ) : (
                            <text style={{ color: "#D9DDE5" , fontWeight: "bold"}}>
                              Fingerprint
                            </text>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={() =>
                        donorData.faceCollectionUrl ? setVerifyType(2) : ""
                      }
                      style={
                        verifyType == 2
                          ? {
                              paddingTop: "6rem",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "1rem",
                              marginRight: "1rem",
                              borderRadius: "10px",
                              background: "#FCFCFD",
                              border: "3px solid #0075FF",
                              width: "30%",
                              height: "260px",
                            }
                          : {
                              paddingTop: "6rem",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "1rem",
                              marginRight: "1rem",
                              borderRadius: "10px",
                              background: "#FCFCFD",
                              border: "1px dashed #E1E4EA",
                              width: "30%",
                              height: "260px",
                            }
                      }
                    >
                      <div>
                        <div style={{ marginLeft: "2rem" }}>
                          {donorData.faceCollectionUrl ? (
                            <FaceUserSVG />
                          ) : (
                            <FaceUserNoSVG />
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: "0.5rem",
                            // opacity: " 0.2500000059604645",
                            color: "#152C5B",
                            fontFamily: "Montserrat",
                            fontWeight: "SemiBold",
                            fontSize: "15px",
                            lineHeight: "normal",
                            letterSpacing: "0px",
                            textAlign: "left",
                          }}
                        >
                          {donorData.faceCollectionUrl ? (
                            <text
                              style={{
                                color: "#152C5B",
                                fontWeight: "bold",
                              }}
                            >
                              Face Collection
                            </text>
                          ) : (
                            <text style={{ color: "#D9DDE5", fontWeight: "bold", }}>
                              Face Collection
                            </text>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      className="btn_next"
                      onClick={() => verifyConfirmClick()}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              ) : (
                <div> </div>
              )}

              <div className="w-100 h-100">
                <DonorVerifySignature
                  ref={signatureRef}
                  onConfirm={handleVerifyConfirm}
                  onCancel={handleVerifyCancel}
                />
                <DonorVerifyFinger
                  ref={fingerRef}
                  onConfirm={handleVerifyConfirm}
                  onCancel={handleVerifyCancel}
                />
                <DonorVerifyFace
                  ref={faceRef}
                  donorId={donorData?donorData:''}
                  onConfirm={handleVerifyConfirm}
                  onCancel={handleVerifyCancel}
                />
              </div>
            </div>
            </>
              ) : (
                <>
                  <div onClick={() => verifyFailedClose()} style={{ textAlign: "right" }}>
                    <CloseBtn />
                  </div>
                  <DonorVerifyFailed
                    onCancel={handleVerifyCancel}
                  />
                </>
              )
            }
          </Modal.Body>
        </Modal>
      </div>
    );
  }
);

export default DonorVerify;
