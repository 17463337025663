import cookieUtils from "@/utils/cookieUtils";

export const SETROOMRECORD = "SETROOMRECORD";

export const COOKIE_ROOM_RECORD = 'room_data';

export const setRoomRecord = (roomData) => {
    if (roomData) {
        cookieUtils.set(COOKIE_ROOM_RECORD, JSON.stringify(roomData));
    } else {
        cookieUtils.clear(COOKIE_ROOM_RECORD);
    }
    return ({
        type: SETROOMRECORD,
        roomData,
    })
}