import React from "react";
const Error = () => {
  return (
    <svg
      width="2.75rem"
      height="2.75rem"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_24_1541)">
        <path
          d="M21.8002 39.6C31.5204 39.6 39.4002 31.7202 39.4002 22C39.4002 12.2798 31.5204 4.40002 21.8002 4.40002C12.08 4.40002 4.2002 12.2798 4.2002 22C4.2002 31.7202 12.08 39.6 21.8002 39.6Z"
          stroke="#E44E51"
          strokeWidth="4.4"
        />
        <path
          d="M20.4273 24.8152H23.1465L23.8329 15.7864L23.9649 11.8528H19.6089L19.7409 15.7864L20.4273 24.8152ZM21.8001 31.864C23.1201 31.864 24.1233 30.8872 24.1233 29.5144C24.1233 28.1416 23.1201 27.1912 21.8001 27.1912C20.4537 27.1912 19.4505 28.1416 19.4505 29.5144C19.4505 30.8872 20.4273 31.864 21.8001 31.864Z"
          fill="#E44E51"
        />
      </g>
      <defs>
        <clipPath id="clip0_24_1541">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Error;
