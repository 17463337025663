import cookieUtils from '@/utils/cookieUtils';
import { COOKIE_VISIT_ID, SETVISITID } from '../../actions/visit';

const initState = {
    visitId: ""
}

export const visitStateReducer = (state = initState, action) => {
    switch (action.type) {
        case SETVISITID:
            return {
                ...state,
                visitId: action.visitId,
            }
        default:
            let data = cookieUtils.get(COOKIE_VISIT_ID);
            if (data) {
                let dataUpdate = JSON.parse(data);
                return { visitId: dataUpdate };
            } else {
                return state
            }
    }
}
