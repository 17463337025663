import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { firstToUpper } from "@/components/util/Util";
import { getsig } from "../../../service/donorsig/donorsig";
import PassSVG from "../../../../assets/svgicons/donor/PassSVG";
import { getDonor } from "../../../service/donor/donor";
import { upDataNew } from "@/pad/components/service/collection/collection";
import Deferral from "../../../../assets/svgicons/donor/Deferral";
import DeferralTime from "../../../../assets/svgicons/donor/DeferralReasonAlart";
import { Spin } from "antd";
import donorAPI from "@/api/app/donor/donor";
import ReportCheckIn from "./ReportCheckIn";
import { DEFERRAL, SUCCESS } from "@/utils/exports/queue";
import { checkDeferral, compare, filterDuplicateDeferralType, getDate, getOneProgramHistory } from "./utils/questionnaireService";
import { message } from "antd";
import AskStaffModal from "../../modal/AskStaffModal";
import MessageConfirmModal from "@/components/messageModal/MessageConfirmModal";
import { useNavigate } from "react-router-dom";

const Report = React.forwardRef(
  (props, ref) => {
    React.useImperativeHandle(ref, () => {
      return {
        handleCheckinNext: handleCheckinNext
      };
    });

    const { dataID, donor_Id, setCloseCheckIn, setBtnNext, setisloading, setIsCheck,
      donorCheckDeferral, isChangeValue, isCheckIn, setIsCheckIn, handlerOnClickNext
    } = props
    const [sigPic, setsigPic] = useState(null);
    const [QList, setQList] = useState(null);
    const [deferralInf, setdeferralInf] = useState(null);
    const [Wquestions, setWquestions] = useState([]);
    const [resultQuestion, setResultQuestion] = useState("")
    const [donorProgramId, setDonorProgramId] = useState("")
    const [listDonorProgram, setListDonorProgram] = useState([])
    const [queueData, setQueueData] = useState()
    const [staffInfo, setStaffInfo] = useState()
    const [verifyProgram, setVerifyProgram] = useState({
      data: [],
      inform: ""
    })

    const [isDeferral, setIsDeferral] = useState(false)
    const [showLoading, setshowLoading] = useState(true);
    const [donorIsCompleted, setDonorIsCompleted] = useState(false)
    const [openModalProgram, setOpenModalProgram] = useState(false)
    const [canCheckIn, setCanCheckIn] = useState(false)
    const [staffVerify, setStaffVerify] = useState(false)
    const messageModalRef = useRef("rememberMe");
    const messageModalRef1 = useRef("rememberMe");
    const navigate = useNavigate();

    useEffect(() => {
      isChangeValue && isChangeValue(false)
    }, [])

    //Main Process
    const getDataDonorProcess = async () => {
      if (dataID) {
        getSignatureQuestion() // get Confirme Signature Questionnaire

        let donorDetail = await donorAPI.get({ donorId: dataID.donorId }); // get Donor Details
        if (donorDetail) {
          setStaffInfo(donorDetail.employeeId);
          setDonorIsCompleted(donorDetail.isCompleted);
        }

        await donorAPI.getDonorCanCheckIn({ donorId: dataID.donorId }) // Check if Donor Already in Queue
          .then((res) => {
            setisloading(true)
            setIsCheck('next')
            if (res) {
              setQueueData(res)
              setIsCheckIn(true)
              setCanCheckIn(false)
              setBtnNext(false)
            } else {
              setCanCheckIn(true)
              setBtnNext(true)
            }
          }).catch((err) => {
            setisloading(true)
            setIsCheck('next')
            setBtnNext(true)
            setCanCheckIn(true)
          })

        getDonor("/donors/process", { // get Process Qualification Donor
          donorId: dataID.donorId,
          process: "qualification",
        })
          .then(async (res) => {
            checkQuestionnaire(res); // set Result and Deferral Questions
            setshowLoading(false);
          })
          .catch((err) => {
            setshowLoading(false);
          });
      }
    }
    useEffect(async () => {
      await getDataDonorProcess()
    }, []);

    //Check-In Process
    const handleCheckinNext = async () => {
      if (resultQuestion === SUCCESS && canCheckIn) {
        setshowLoading(false);
        if (!donorIsCompleted) {
          preSetDefaultDonorProgram(donor_Id)
          setshowLoading(false);
        }
        if (donorIsCompleted) {
          preSetDefaultDonorProgram(donor_Id)
          setshowLoading(false);
        }
      }
    }

    useEffect(() => {
      setCloseCheckIn(!isDeferral || isCheckIn ? true : false)
    }, [isDeferral, isCheckIn])

    const getSignatureQuestion = () => {
      getsig("/verify", { donorId: dataID.donorId, position: "qualification" })
        .then((res) => {
          setsigPic(res);
        })
        .catch((err) => {
          setsigPic("");
        });
    }

    const checkQuestionnaire = async (data) => {
      if (data && data.length > 0) {
        let dataQuestion = data.pop();
        let allQuestions = dataQuestion.ids && dataQuestion.ids.questionId ? dataQuestion.ids.questionId : {};
        let deferralQuestion = allQuestions ? allQuestions.deferralIds : [];
        deferralQuestion.sort(compare);
        if (allQuestions) {
          setQList(allQuestions)
          let data = checkDeferral(allQuestions);

          if (data) {
            let getDeferral = data.map((item) => item.actions);
            let deferralType = filterDuplicateDeferralType(getDeferral, "actionValue");

            if (deferralType) {
              setWquestions(deferralType)
            }
          }
          if (deferralQuestion.length > 0) {
            setdeferralInf(deferralQuestion[0])
            setIsDeferral(true);
            setResultQuestion(DEFERRAL);
          } else {
            await upDataNew("/donors/completed", {}, { donorId: dataID.donorId })
              .then(async (res) => {
                setIsDeferral(false);
                setResultQuestion(SUCCESS);
              })
              .catch((err) => {
                message.error("Catch Error");
                setshowLoading(false);
              })
          }
        }
      }
    }

    const handleCheckin = () => {
      defaultDonorProgram(donorProgramId)
    }

    const preSetDefaultDonorProgram = async (donorId) => {
      defaultDonorProgram()
    }

    const defaultDonorProgram = async (programId) => {
      toCheckInProcess()
    }


    const toCheckInProcess = async () => {
      await donorAPI.autoCheckInNew({ donorId: dataID.donorId }).then( async(res) => {
        if (res && res.status === 200 && res.data) {
          setQueueData(res.data)
          setIsCheckIn(true)
          await getDataDonorProcess()
        } else {
          if(res?.errorCode === 2030003) {
            messageModalRef1.current.showWarningConfirmsAutoCloseTime(<div>
              <div>{res ? res?.message : "Catch Error"}</div>
              <div className="pt-4" style={{color:"rgba(0, 117, 255, 1)"}}>( <DownTimeMillisecond handlerOnClickNext={handlerOnClickNext} timeTotal={180} />s )</div>
            </div>, () => { 
              handlerOnClickNext && handlerOnClickNext()
            }, "", true,  () => { 
              handlerOnClickNext && handlerOnClickNext()
            }, 180000);
          } else {
            messageModalRef1.current.showWarningConfirmsAutoClose(res ? res?.message : "Catch Error", () => { 
             
            }, "", true,  () => { 
             
            });
          }
        }
      }).catch((err) => {
        console.log(`err: `, err)
        messageModalRef1.current.showWarningConfirmsAutoClose(err ? err?.message : "Catch Error", () => { 
        }, "", true,  () => { 
        });
      })
    }

    return (
      <>
        <MessageConfirmModal textCentered ref={messageModalRef1} />
        <MessageConfirmModal textCentered widthBtn={`auto`} noTitle={`No, Keep Stay`} yesTitle={`Yes, Leave without Saving`} ref={messageModalRef} />
        {
          !isCheckIn ? (
            <div>
              <AskStaffModal
                shown={staffVerify}
                setShown={setStaffVerify}
                staffInf={staffInfo}
                value={donorProgramId}
                setValue={setDonorProgramId}
                handleCheckin={handleCheckin}
                verifyProgram={verifyProgram}
              />
              <div className="h-100 w-100 d-flex align-items-center justify-content-center skhsk3">
                {showLoading ? (
                  <Spin size="large" Tips="Loading..." />
                ) : (
                  <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-start">
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        padding: "0",
                      }}
                    >
                      <Col style={{ height: "100%" }}>
                        <Row className="m-0">
                          <Col className="literacy_header">Report</Col>
                          <div
                            className="w-100 d-flex flex-column align-items-center justify-content-center"
                            style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem" }}
                          >
                            {!isDeferral ? (
                              <div className="icon-cus">
                                <PassSVG />
                              </div>
                            ) : (
                              <div className="icon-cus">
                                <Deferral />
                              </div>
                            )}
                            {!isDeferral ? (
                              <div>
                                <div className="create_donor_report_status">Pass</div>
                              </div>
                            ) : (
                              <div className="create_donor_report_status">Fail</div>
                            )}
                            {!isDeferral ? null : (
                              <div
                                style={{
                                  width: "100%",
                                  maxWidth: "34.375rem",
                                  // minWidth: "34.25rem",
                                  overflowX: "auto",
                                }}
                              >
                                <div
                                  className="w-100 create_donor_report_status"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "0.9375rem",
                                    margin: "1rem 0",
                                  }}
                                >
                                  There has some problem in your health history
                                  questionnaire Any question , you can ask staff member
                                  for assistance.
                                </div>
                                <div
                                  className="w-100 d-flex flex-column align-items-center justify-content-center"
                                  style={{
                                    border: "2px solid rgba(21,44,91,0.1)",
                                  }}
                                >
                                  <div
                                    class="w-100 div-padding"
                                    style={{
                                      backgroundcolor: "rgba(240,243,249,0.4)",
                                      // padding: "1.25rem 6.25rem",
                                    }}
                                  >
                                    <div
                                      style={{ marginTop: "1rem" }}
                                      className="w-100 d-flex align-items-center justify-content-between"
                                    >
                                      <div className="create_donor_report_k">
                                        Deferral Time :
                                      </div>
                                      <div className="create_donor_report_v ps-4" style={{marginLeft: "2rem"}}>
                                        {deferralInf && deferralInf.cycle > 0
                                          ? deferralInf.cycle + " Days"
                                          : deferralInf?.cycle === 0
                                            ? "Indefinite"
                                            : "Permanent"}
                                      </div>
                                    </div>
                                    <div
                                      style={{ marginTop: "1rem" }}
                                      className="w-100 d-flex align-items-center justify-content-between"
                                    >
                                      <div className="create_donor_report_k">
                                        Next Eligible Date :
                                      </div>
                                      <div className="create_donor_report_v ps-4" style={{marginLeft: "2rem"}}>
                                        {deferralInf &&
                                          QList &&
                                          QList.createdAt &&
                                          deferralInf.cycle &&
                                          deferralInf.cycle > 0
                                          ? getDate(
                                            new Date(
                                              new Date(QList.createdAt).getTime() +
                                              deferralInf.cycle * 86400000
                                            )
                                          )
                                          : deferralInf?.cycle === 0
                                            ? getDate(new Date())
                                            : "Permanent"}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="w-100"
                                    style={{
                                      backgroundColor: "rgba(255,255,255,0.8)",
                                      padding: "0 2rem",
                                    }}
                                  >
                                    {Wquestions
                                      ? Wquestions.map((item, index) => (
                                        <div
                                          key={index}
                                          className="w-100 d-flex align-items-center justify-content-center- skhsk1"
                                          style={{
                                            borderTop:
                                              "0.125rem dashed rgb(232, 234, 239)",
                                          }}
                                        >
                                          <Row
                                            className="w-100 d-flex align-items-center justify-content-center skhsk2"
                                            style={{ padding: "1.5rem 0" }}
                                          >
                                            <Col
                                              md={2}
                                              xs={2}
                                              sm={2}
                                              className="create_donor_report_v ps-4"
                                            >
                                              {index < 9
                                                ? "0" + (index + 1)
                                                : index + 1}
                                            </Col>

                                            <Col className="p-0">
                                              <Row className="d-flex align-items-start justify-content-between">
                                                <Col
                                                  md={6}
                                                  xs={6}
                                                  sm={6}
                                                  className="create_donor_report_k"
                                                >
                                                  Deferral Category :
                                                </Col>
                                                <Col className="create_donor_report_v">
                                                  {firstToUpper(
                                                    item.deferralId
                                                      .category
                                                  )}
                                                </Col>
                                              </Row>
                                              <Row
                                                className="d-flex align-items-start justify-content-between"
                                                style={{ marginTop: "1rem" }}
                                              >
                                                <Col
                                                  md={6}
                                                  xs={6}
                                                  sm={6}
                                                  className="create_donor_report_k"
                                                >
                                                  Deferral Type:
                                                </Col>
                                                <Col
                                                  className="create_donor_report_v"
                                                  style={{ wordBreak: "break-all" }}
                                                >
                                                  {firstToUpper(
                                                    item.deferralId
                                                      ?.typeName
                                                  )}
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </div>
                                      ))
                                      : null}
                                  </div>

                                  <div></div>
                                </div>
                              </div>
                            )}
                          </div>
                          <Row className="align-items-center">
                            <Col className="create_donor_report_k">Completion Time:</Col>
                            <Col className="create_donor_report_v">
                              {QList && QList.createdAt ? getDate(QList.createdAt) : ""}
                            </Col>
                            <Col className="create_donor_report_k">Channel :</Col>
                            <Col className="create_donor_report_v">web</Col>
                          </Row>

                          <Row className="align-items-center">
                            <Col className="create_donor_report_k">Donor Confirmed by:</Col>
                              <Col
                                className="create_donor_report_title"

                                style={{ marginLeft: "2.6rem" }}
                              >
                                {sigPic.map((item, index) => (
                                  <>
                                    {item &&
                                      item &&
                                      item.verificationType === "signature" ? (
                                      <div style={{ width: "148px", height: "49px" }}>
                                        <img
                                          style={{ width: `100%`, height: "100%" }}
                                          src={
                                            item && item.signature
                                              ? item.signature
                                              : ""
                                          }
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <Col
                                      className=""
                                      style={{ marginLeft: "3.2rem" }}
                                    >
                                      {item &&
                                        item &&
                                        item.verificationType === "faceRecognition"
                                        ? `Face Recognition`
                                        : ""}
                                    </Col>
                                    <Col
                                      className=""
                                      style={{ marginLeft: "3.2rem" }}
                                    >
                                      {item &&
                                        item &&
                                        item.verificationType === "fingerScan"
                                        ? `Fingerscan`
                                        : ""}
                                    </Col>
                                  </>
                                ))}
                              </Col>
                            <Col className="create_donor_report_k">
                              {/* Donor Confirmed by： */}
                              </Col>
                            <Col className="create_donor_report_v">
                              {/* N/A */}
                              </Col>
                          </Row>
                        </Row>

                        <Row
                          style={{
                            paddingTop: "0.9375rem",
                            borderTop: "0.125rem dashed #E8EAEF",
                          }}
                        >
                          <Row>
                            <Col className="literacy_header col ps-sm-0 ps-md-4 ps-lg-4">Report details</Col>
                          </Row>
                          <Col className="col ps-sm-0 ps-md-4 ps-lg-4 pe-sm-0 pe-md-4 pe-lg-4">
                            {QList &&
                              QList.record &&
                              QList.record.map((item, ind) => (
                                <Row key={ind}>
                                  <Row
                                    className="align-items-center question_number_layout-cus"
                                    style={{
                                      paddingTop: "0.75rem",
                                      // marginLeft: "0.5rem",
                                    }}
                                  >
                                    <Col
                                      className="preview_index_box- question_layout col-12 d-flex flex-row align-items-center"
                                    >
                                      <div class="question_number_layout me-4 ms-3-cus-"> Q{ind + 1}</div>
                                      <h4 class="question_title"> {item.title}</h4>
                                    </Col>
                                  </Row>

                                  <div
                                    className="align-items-center d-flex"
                                    style={{ paddingTop: "0.75rem", 
                                    // paddingLeft: "1.9rem" 
                                  }}
                                  >
                                    <div class="answer_line_layout me-4"><div class="vertical_solid_line"></div></div>
                                    <div
                                      className="card_input_label ms-2"
                                      // style={{ marginLeft: "10%" }}
                                    >
                                      Answer:
                                    </div>
                                    {/* 选择题 */}
                                    {(item.questionType.toLowerCase() ===
                                      "multi-choice" ||
                                      item.questionType.toLowerCase() ===
                                      "single-choice") &&
                                      item.answers &&
                                      item.answers.map((answer) => (
                                        <div>
                                          {answer && answer.isanswer ? (
                                            <div
                                              className="preview_index_box"
                                              style={{
                                                padding: "0.61rem 1.25rem",
                                                margin: "0 1.25rem",
                                                border:
                                                  answer.result &&
                                                    answer.result.toLowerCase() === "fail"
                                                    ? "2px solid rgba(228,78,81,0.8)"
                                                    : "",
                                              }}
                                            >
                                              {answer.answer}
                                              {answer.result &&
                                                answer.result.toLowerCase() ===
                                                "fail" && (
                                                  <span
                                                    //className="align-self-end"
                                                    style={{
                                                      color: " rgba(228,78,81,0.8)",
                                                      // fontSize: "0.85rem",
                                                      fontFamily: "Montserrat",
                                                      marginLeft: "2rem",
                                                    }}
                                                  >
                                                    <DeferralTime />
                                                    <span>
                                                      {" "}
                                                      Deferral Type : {" "}
                                                      {firstToUpper(
                                                        answer.actions[0]?.deferralId
                                                          ?.typeName
                                                      )}
                                                    </span>
                                                  </span>
                                                )}
                                            </div>
                                          ) : null}
                                        </div>
                                      ))}
                                    {/* 简答题 */}
                                    {item.questionType.toLowerCase() === "text" &&
                                      item.answers ? (
                                      <div
                                        className="preview_index_box"
                                        style={{
                                          padding: "0.61rem 1.25rem",
                                          margin: "0 1.25rem",
                                        }}
                                      >
                                        {item.answers && item.answers[0]
                                          ? item.answers[0]
                                          : "N/A"}
                                      </div>
                                    ) : null}
                                  </div>
                                </Row>
                              ))}
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ReportCheckIn dataID={dataID} donor_Id={donor_Id} queueData={queueData} setQueueData={setQueueData} setBtnNext={setBtnNext} handlerOnClickNext={handlerOnClickNext} />
          )
        }
      </>
    );
  });

  const DownTimeMillisecond = ({timeTotal, handlerOnClickNext}) => {
    const [countDown, setCountDown ] = useState(timeTotal ? timeTotal : 0)
    useEffect(() => {
      let timer = setInterval(() => {
        if (countDown - 1 >= 0) {
          let time = countDown
          time = time - 1
          setCountDown(time)
        } else {
          handleFinish();
          clearInterval(timer);
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }, [countDown]);

    const handleFinish = () => {
      handlerOnClickNext && handlerOnClickNext()
    };

    return countDown
};

export default Report;
