import cookieUtils from '@/utils/cookieUtils';
import { COOKIE_NAME_TOKEN, COOKIE_NAME_USER_INFO, SETISLOGIN, setIsLogin, SETUSERINF, SetUserInf } from '../../actions/login';

const initState = {
    isLogin: "",
}
//登录状态
export const loginStateReducer = (state = initState, action) => {
    // debugger
    // if (!action.state) {
    //     let token = cookieUtils.get(COOKIE_NAME_TOKEN);
    // }
    // if (token) 
    // console.log(JSON.stringify(state), JSON.stringify(action));

    switch (action.type) {
        case SETISLOGIN:
            return {
                ...state,
                isLogin: action.state
            }
        default:
            let token = cookieUtils.get(COOKIE_NAME_TOKEN);
            if (token) {
                return {isLogin: token};
            } else {
                return state
            }
    }
}


const initUserInfState = {
    userInfo: {}
}

export const userInfStateReducer = (userInfo = initUserInfState, action) => {
    // debugger
    // let uf = cookieUtils.get(COOKIE_NAME_USER_INFO)
    // console.log(JSON.stringify(userInfo), JSON.stringify(action));

    switch (action.type) {
        case SETUSERINF:
            return {
                ...userInfo,
                userInfo: action.userInfo
            }
        default:
            let uij = cookieUtils.get(COOKIE_NAME_USER_INFO);
            if (uij) {
                let ui = JSON.parse(uij);
                return { userInfo: ui };
            } else {
                return userInfo
            }
    }
    // console.log("userInf:", userInf);
}
