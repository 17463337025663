export default [
  // {
  //   title: "Create Account",
  // },
  // {
  //   title: "Proof of Documents",
  // },
  {
    title: "Literacy Check",
  },
  {
    title: "Watch Video",
  },
  {
    title: "Health History Questionnaire",
  },
];
