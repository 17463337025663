import { message } from "antd";
import { BadReqeustException } from "./request";


const errFun = (err) => {
  console.error('dealDeal: Data Error', err);
  if (!err) {
    message.error('Bad request error.');
    return;
  }
  if (err instanceof BadReqeustException) {
    message.error(err.message);
    return;
  }
  if (err.name == 'AxiosError') {
    if (err.code == 'ERR_NETWORK') {
      message.error('Network error.');
      return;
    }
  }
  if (err instanceof Error) {
    message.error(err.message);
    return;
  }
  message.error('Bad request error.', err);
}

const errorDeal = (run, outFun) => {
  return function(){
    try {
      if (run && typeof run === 'function') {
        let result = run(...arguments);
        if (result && result instanceof Promise) {
          result.then((res)=>{
          }).catch((err)=>{
            errFun(err);
          });
        }
      }
    } catch (err) {
      errFun(err);
    }

    return () => {
      try {
        if (outFun && typeof outFun === 'function') {
          let result = outFun();
          if (result && result instanceof Promise) {
            result.then((res)=>{
            }).catch((err)=>{
              errFun(err);
            });
          }
        }
      } catch (err) {
        errFun(err);
      }
    };
  };
};

export default errorDeal;
