import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import enUsTrans from "./assets/locales/en-us.json";


i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
    resources: {
      en: {
        translation: enUsTrans,
      },

    },
		fallbackLng: 'en',
		debug: false,

		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
	});

export default i18n;
