import ApiService from "../../request/request";

export const queueQuery = async (url, param) => {
  const result = await ApiService.get(url, param);
  return result;
};

export const queueQuerys = async (url, param) => {
  const result = await ApiService.post(url, param);
  return result;
};

export const queuePost = async (url, param) => {
  const result = await ApiService.post(url, param);
  return result.data;
};
export const queuePostFavorite = async (url, param) => {
  const result = await ApiService.post(url, param);
  return result;
};

export const queuePut = async (url, param, data) => {
  const result = await ApiService.put(url, param, data);
  return result;
};


export const medicalDelete = async (url, param) => {
  const result = await ApiService.delete(url, param);
  return result;
};

export const getVisitType = async (url, param) => {
  return await ApiService.get(url, param);
}

export const getCollectionType = async (url, params) => {
  return await ApiService.get(url, params)
}

export const donorRetake = async (url, params) => {
  return await ApiService.delete(`${url}`, params)
}