import cookieUtils from "@/utils/cookieUtils";

export const SETISLOGIN = "SETISLOGIN";
export const SETUSERINF = "SETUSERINF";

export const COOKIE_NAME_TOKEN = 'token';
export const COOKIE_NAME_USER_INFO = 'user_info';

export const setIsLogin = (token) => {
    if (token) {
        cookieUtils.set(COOKIE_NAME_TOKEN, token);
    } else {
        cookieUtils.clear(COOKIE_NAME_TOKEN);
    }
    return ({
        type: SETISLOGIN,
        state: token,
    })
};

export const SetUserInfo = (userInfo) => {
    if (userInfo) {
        cookieUtils.set(COOKIE_NAME_USER_INFO, JSON.stringify(userInfo));
    } else {
        cookieUtils.clear(COOKIE_NAME_USER_INFO);
    }
    return ({
    type: SETUSERINF,
    userInfo,
    })
};
// export const setCookie = (name, value, expireDate) => {
//   let currentDate = new Date();
//   currentDate.setDate(currentDate.getDate() + expireDate);
//   document.cookie = name + "=" + value + "; expires=" + currentDate;
// };
// export const getCookie = (name) => {
//   let arr = document.cookie.split("; ");
//   for (let i = 0; i < arr.length; i++) {
//     let arr2 = arr[i].split("=");
//     if (arr2[0] === name) {
//       return arr2[1];
//     }
//   }
//   return "";
// };

// export const removeCookie = (name) => {
//   setCookie(name, 1, -1);
// };
